import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Style, Stroke, Icon } from "ol/style";
import { Point } from "ol/geom";
import { transform } from "ol/proj";
import Polyline from "ol/format/Polyline";
import Feature from "ol/Feature";
import googlePolyline from "google-polyline";

import finishMarker from "../../../assets/images/yellow_marker.png";
import vehicleMarker from "../../../assets/images/vehicle_marker.png";

Number.prototype.toRadians = function() {
  return (this * Math.PI) / 180;
};
Number.prototype.toDegrees = function() {
  return (this * 180) / Math.PI;
};

const styleFn = f => {
  switch (f.get("type")) {
    case "route":
      return new Style({
        stroke: new Stroke({
          width: 5,
          color: [150, 25, 150, 0.8]
        })
      });
    case "marker":
      return new Style({
        image: new Icon({
          anchor: [0.5, 0.9],
          scale: 0.3,
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          opacity: 0.85,
          src: finishMarker
        })
      });
    case "vehicle":
      return new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          scale: 0.3,
          anchorXUnits: "fraction",
          anchorYUnits: "fraction",
          opacity: 0.85,
          src: vehicleMarker,
          rotation: +f.get("rotation") || 0
        })
      });
    default:
      return null;
  }
};

const vectorLayer = new VectorLayer({
  title: "مسار الرحلة (الفيديو)",
  name: "route",
  source: new VectorSource({
    features: []
  }),
  style: styleFn
});

export const removeRouteLayer = map => {
  map.removeLayer(
    map
      .getLayers()
      .getArray()
      .find(layer => layer.get("name") === "route")
  );
};

export const prepareRouteLayer = ({ map, polyline }) => {
  const routeFeature = createRouteFeature(polyline.encoded);
  vehicleMarkerObj.new([
    polyline.trackPoints[0][1],
    polyline.trackPoints[0][0]
  ]);
  const finishMarker = createMarker({
    type: "marker",
    coords: [
      polyline.trackPoints[polyline.trackPoints.length - 1][1],
      polyline.trackPoints[polyline.trackPoints.length - 1][0]
    ]
  });

  vectorLayer.getSource().clear();
  vectorLayer
    .getSource()
    .addFeatures([routeFeature, finishMarker, vehicleMarkerObj.get()]);
  map.addLayer(vectorLayer);
  map.getView().fit(vectorLayer.getSource().getExtent(), {
    padding: [170, 50, 30, 150],
    duration: 1000
  });
};

export const createMarker = ({ type = "marker", coords }) =>
  new Feature({
    type: type,
    geometry: new Point(transform(coords, "EPSG:4326", "EPSG:28191"))
  });

export const createRouteFeature = encodedPolyline => {
  return new Feature({
    type: "route",
    geometry: new Polyline().readGeometry(encodedPolyline, {
      dataProjection: "EPSG:4326",
      featureProjection: "EPSG:28191"
    })
  });
};

let vehicleFeature = null;
export const vehicleMarkerObj = {
  new: coords => {
    const marker = new Feature({
      type: "vehicle",
      geometry: new Point(transform(coords, "EPSG:4326", "EPSG:28191"))
    });
    marker.setId("vehicle");
    vehicleFeature = marker;
  },
  get: () => vehicleFeature,
  move: ({ locationData, currentSecond }) => {
    if (vectorLayer && locationData && locationData.coords) {
      const v = vectorLayer.getSource().getFeatureById("vehicle");
      v.set("rotation", locationData.bearing.toRadians());
      v.setGeometry(
        new Point(transform(locationData.coords, "EPSG:4326", "EPSG:28191"))
      );
    }
  }
};

const getBearing = (fromCoords, toCoords) => {
  const A =
    Math.sin(toCoords[0].toRadians() - fromCoords[0].toRadians()) *
    Math.cos(toCoords[1].toRadians());
  const B =
    Math.cos(fromCoords[1].toRadians()) * Math.sin(toCoords[1].toRadians()) -
    Math.sin(fromCoords[1].toRadians()) *
      Math.cos(toCoords[1].toRadians()) *
      Math.cos(toCoords[0].toRadians() - fromCoords[0].toRadians());
  let bearing = Math.atan2(A, B).toDegrees();
  return (bearing = bearing > 0 ? bearing : (bearing + 360) % 360);
};

const getDistance = ([lon1, lat1], [lon2, lat2]) => {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = lat1.toRadians();
    var radlat2 = lat2.toRadians();
    var theta = lon1 - lon2;
    var radtheta = theta.toRadians();
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist.toDegrees();
    dist = dist * 60 * 1.1515 * 1.609344;
    return dist;
  }
};

export const parseGpxXml = xml => {
  const trackPoints = [];
  const trackLocations = {};

  const locationSegments = xml.querySelectorAll("trkpt");

  let secondsCounter = -1;
  let prevTime = null;
  let prevDistance = 0;
  let prevCoords = null;

  locationSegments.forEach(segment => {
    trackPoints.push([
      +segment.getAttribute("lat"),
      +segment.getAttribute("lon")
    ]);

    const time = new Date(segment.querySelector("time").textContent);
    let timeDiff = 1;
    if (prevTime) {
      timeDiff =
        time.getSeconds() !== 0 ? time.getSeconds() - prevTime : 60 - prevTime;
      secondsCounter += timeDiff;

      prevTime = time.getSeconds();
    } else {
      prevTime = time.getSeconds();
    }

    const coords = [+segment.getAttribute("lon"), +segment.getAttribute("lat")];
    let distance = 0;
    let bearing = 0;
    if (prevCoords) {
      distance = getDistance(prevCoords, coords);
      bearing = getBearing(prevCoords, coords);
    }

    prevCoords = coords;
    prevDistance += distance;

    trackLocations[secondsCounter] = {
      coords: coords,
      bearing: Math.round(bearing),
      speed: Math.round((distance * 3600) / timeDiff),
      distance: prevDistance
    };
  });

  return {
    encoded: googlePolyline.encode(trackPoints),
    trackPoints: trackPoints,
    trackLocations: trackLocations
  };
};
