import { Tile } from "ol/layer";
import { OSM } from "ol/source";
import globe from "ol-ext/control/Globe";
import searchGPS from "ol-ext/control/SearchGPS";
import { resources } from "../assets/LocalizationResources";

export default function defaultExtendedControls(map) {
    return [
        GlobeControl({
            layers: [new Tile({ source: new OSM() })],
            follow: true,
            panAnimation: "elastic"
        }),
        SearchGPSControl(
            {
                className: "ol-search-control",
                label: resources.coordinates,
                placeholder: resources.coordinates + "..."
            },
            map
        )
    ];
}

export function GlobeControl(options = {}) {
    return new globe(options);
}

export function SearchGPSControl(options = {}, map) {
    const search = new searchGPS(options);
    search.on("select", function(e) {
        map.getView().animate({
            center: e.search.coordinate,
            zoom: Math.max(map.getView().getZoom(), 15)
        });
    });
    return search;
}
