import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTable, useSortBy, useFilters, usePagination, useRowSelect } from "react-table";
import { Button } from "reactstrap";
import "./Table.css";
import { getDefaultFilterColumn } from "./TableHelper";

/**
 * @param {{columns: Array; data:Array; withPagination?: boolean;withRowSelect?: boolean; onRowSelection?:function; denseBorder?: boolean; tablePageSise?: number }} Options
 */
export default function Table({
  columns,
  data,
  withPagination,
  withRowSelect,
  onRowSelection,
  denseBorder,
  tablePageSise
}) {
  function defaultPaginationComponent() {
    return canPreviousPage || canNextPage ? (
      <div className="g-table-pagination">
        {canPreviousPage && (
          <Button className="g-table-btnpage" color="default" onClick={() => gotoPage(0)}>
            {"<<"}
          </Button>
        )}
        {canPreviousPage && (
          <Button className="g-table-btnpage" color="default" onClick={() => previousPage()}>
            {"<"}
          </Button>
        )}
        {canNextPage && (
          <Button className="g-table-btnpage" color="default" onClick={() => nextPage()}>
            {">"}
          </Button>
        )}
        {canNextPage && (
          <Button className="g-table-btnpage" color="default" onClick={() => gotoPage(pageCount - 1)}>
            {">>"}
          </Button>
        )}
        <span>
          <label> الصفحات من</label>
          <label> {pageIndex ? pageIndex : 0 + 1}</label>
          <label> إلى </label>
          <label>{pageOptions.length ? pageOptions.length : 1}</label>
        </span>

        <select
          className="g-table-select"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              عرض {pageSize}
            </option>
          ))}
        </select>
      </div>
    ) : (
      <></>
    );
  }

  useEffect(() => {
    if (withPagination) {
      setPageSize(tablePageSise || 10);
    }
  }, []);

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: getDefaultFilterColumn
    }),
    []
  );

  const usedPlugins = [useFilters, useSortBy];
  if (withPagination) usedPlugins.push(usePagination);
  if (withRowSelect) usedPlugins.push(useRowSelect);

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
    selectedRows
  } = useTable(
    {
      columns,
      data,
      defaultColumn
    },
    ...usedPlugins
  );

  if (withRowSelect && onRowSelection) {
    onRowSelection(selectedRows);
  }

  return (
    <div className="g-table-container">
      <table {...getTableProps()} className="g-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  <span>
                    {column.sorted ? (
                      column.sortedDesc ? (
                        <FontAwesomeIcon className="g-sort-icon" icon="sort-amount-down" />
                      ) : (
                        <FontAwesomeIcon className="g-sort-icon" icon="sort-amount-up-alt" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                  {column.sortable !== false ? (
                    <span {...column.getSortByToggleProps()}>{column.render("Header")}</span>
                  ) : (
                    column.render("Header")
                  )}
                  <div>{column.filterable !== false ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {(withPagination ? page : rows).map(
            (row, i) =>
              prepareRow(row) || (
                <tr {...row.getRowProps()} style={denseBorder ? { border: "1px solid lightgray" } : {}}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                  })}
                </tr>
              )
          )}
        </tbody>
      </table>
      {withPagination ? defaultPaginationComponent() : null}
    </div>
  );
}
