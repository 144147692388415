import Joi from "@hapi/joi";

export const loginSchema = Joi.object({
  Email: Joi.string()
    .max(255)
    .email({ tlds: { allow: false } })
    .required(),
  Password: Joi.string()
    .min(8)
    .max(255)
    .regex(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/)
    .required()
});
