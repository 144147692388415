import Axios from "axios";
import { getTokenHeader } from "./getTokenHeader";
import constants from "../helpers/Constants";

const axiosConfig = {
  baseURL: `${window.location.protocol}//${
    window.location.hostname + constants.port
  }/api/settings`,
};
const axios = Axios.create(axiosConfig);

const LocalCache = {};
const ttl = 1000 * 60 * 5; // x minutes request caching

function getRequestWithCache(url) {
  if (
    LocalCache[url] &&
    isSuccessfulRequest(LocalCache[url].status) &&
    LocalCache[url].time > new Date().getTime()
  ) {
    return LocalCache[url].promise;
  }

  const request = axios.get(url);
  LocalCache[url] = {
    promise: request,
    time: new Date().getTime() + ttl,
  };
  return request;
}

export function isSuccessfulRequest(response) {
  // if the status = 400 or more (Failed request) the subtraction will be positive
  const success = response && response.status - 400 < 0 && response.data;
  return success;
}

/////////// Service end-points ///////////

const settingsApi = {
  users: () => {
    return axios.get("/getAllUsers", getTokenHeader());
  },
  layers: () => {
    return axios.get("/getAllLayers", getTokenHeader());
  },
  addLayerUsers: (model) => {
    return axios.post("/insertlayerUsers", model);
  },
  layerusers: (model) => {
    return axios.post("/getLayerUsers", model);
  },
  permissions: () => {
    return axios.get("/getPermissions");
  },
  addGroupPermissions: (model) => {
    return axios.post("/insertGroup", model);
  },
  editGroupPermissions: (model) => {
    return axios.post("/editGroup", model);
  },
  deleteGroupPermissions: (model) => {
    return axios.post("/deleteGroup", model);
  },
  groups: () => {
    return axios.get("/getAllGroups");
  },
  groupsdll: () => {
    return axios.get("/getGroupsdll");
  },
  getgroupPermissions: (model) => {
    return axios.post("/getgroupPermissions", model);
  },
  getuser: (model) => {
    return axios.post("/getUserData", model);
  },
  getusergroups: (model) => {
    return axios.post("/getUserGroups", model);
  },
  addVillageLayerPermission: (model) => {
    return axios.post("/addVillageLayerPermission", model);
  },
  getVillageLayerPermission: (model) => {
    return axios.post("/getVillageLayerPermission", model);
  },
};

export default settingsApi;
