import React, { useState, useRef, useEffect } from "react";
import DropDownShift from "../../ui-components/DropDownShift/DropDownShift";
import { resources } from "../../../assets/LocalizationResources";
import { GeoServerApi } from "../../../api/geoserverController";
import PropTypes from "prop-types";
import LayersNames from "../../../helpers/LayersNames";

export default function LandUsefilters({ drawFilter, clearFilterFeatures, userVillages }) {
  const [countryID, setCountryID] = useState(null);
  const [governorateID, setGovernorateID] = useState(null);
  const [municipalityID, setMunicipalityID] = useState(null);
  const [villageID, setVillageID] = useState(null);
  const [basinID, setBasinID] = useState(null);
  const [sectorID, setSectorID] = useState(null);
  const [parcelID, setParcelID] = useState(null);
  const governorateDDL = useRef(null);
  const municipalityDDL = useRef(null);
  const villageDDL = useRef(null);
  const basinDDL = useRef(null);
  const sheetDDL = useRef(null);
  const sectorDDL = useRef(null);
  const parcelDDL = useRef(null);
  const [sheetNo, setSheetNo] = useState(null);
  const fillFilter = async (type, value) => {
    if (
      (type === "basin" && !villageID) ||
      (type === "sheet" && !basinID) ||
      (type === "sector" && sheetNo === null) ||
      (type === "parcel" && sectorID === null)
    )
      return Promise.resolve([]);

    let properties;
    let id;
    let label;
    let withCode = false;
    let primaryid;
    switch (type) {
      case "country":
        properties = {
          layer: LayersNames.country,
          max: "10",
          fields: "country_name,id,country_code",
        };
        id = "country_code";
        label = "country_name";
        primaryid = "id";
        break;
      case "governorate":
        properties = {
          layer: LayersNames.governorate,
          max: "10",
          fields: "gov_a,id,gov_code",
          cqlFilter:
            (!+value ? `gov_a Like '%${value}%'` : `gov_code=${value}`) +
            `${LayersNames.country ? ` AND country_code=${countryID}` : ""}`,
        };
        id = "gov_code";
        label = "gov_a";
        primaryid = "id";
        break;
      case "municipality":
        properties = {
          layer: LayersNames.municipality,
          max: "10",
          fields: "munic_name,id,munic_code",
          cqlFilter:
            (!+value ? `munic_name Like '%${value}%'` : `munic_code =  ${value}`) +
            `${LayersNames.governorate ? ` AND gov_code = ${governorateID}` : ""}`,
        };
        id = "munic_code";
        label = "munic_name";
        primaryid = "id";
        break;
      case "village":
        properties = {
          layer: LayersNames.villages,
          max: "10",
          fields: "vill_aname,vill_code, id",
          cqlFilter: !+value
            ? `vill_aname Like '%${value}%'  AND vill_code IN(${userVillages})`
            : `vill_code = ${value} 
            ${
              //TO do handle villages from many municipality
              //`AND vill_code IN(${userVillages})`
              LayersNames.municipality ? ` AND mun_code = ${municipalityID}` : ""
            }`,
        };
        id = "vill_code";
        label = "vill_aname";
        withCode = true;
        primaryid = "id";
        break;
      case "basin":
        properties = {
          layer: LayersNames.basins,
          max: "10",
          fields: "hod_aname,hod_code,id",
          cqlFilter:
            (!+value ? `hod_aname Like '%${value}%'` : `hod_code =  ${value}`) + ` AND vill_code = ${villageID} `,
        };
        id = "hod_code";
        label = "hod_aname";
        withCode = true;
        primaryid = "id";
        break;
      case "sheet":
        properties = {
          layer: LayersNames.sheets,
          max: "10",
          fields: "sheet_no",
          cqlFilter: `sheet_no=${value} AND block_no=${basinID} AND village_no = ${villageID}`,
        };
        id = "sheet_no";
        label = "sheet_no";
        break;
      case "sector":
        properties = {
          layer: LayersNames.sector,
          max: "10",
          fields: "sector_no",
          cqlFilter: `sector_no = ${value} AND block_no = ${basinID} AND village_no = ${villageID} AND sheet_no = ${sheetNo}`,
        };
        id = "sector_no";
        label = "sector_no";
        break;
      case "parcel":
        properties = {
          layer: LayersNames.landUse,
          max: "10",
          fields: "dls_key,plot_no",
          cqlFilter: `dls_key Like '%${value}%'
            AND sector_no = ${sectorID}
            AND block_no = ${basinID}
            AND sheet_no = ${sheetNo}
            AND village_no = ${villageID}`,
        };
        label = "dls_key";
        id = "dls_key";
        break;
      default:
        return Promise.resolve([]);
    }

    const response = await GeoServerApi.getLayer(properties).catch((e) => {
      console.log(type, e);
      // Todo: popup or some error notification
      return Promise.resolve([]);
    });

    if (response && response.data && response.data.features) {
      if (!response.data.features.length) {
        return Promise.resolve([]);
      } else {
        return Promise.resolve(
          response.data.features.map((feature, idx) => {
            return {
              primaryid: feature.properties[primaryid],
              id: feature.properties[id],
              label: !withCode ? feature.properties[label] : `${feature.properties[label]} - ${feature.properties[id]}`,
            };
          })
        );
      }
    } else {
      // Todo: popup or some error notification
      return Promise.resolve([]);
    }
  };

  const onFilterChange = (type, e) => {
    let info;
    switch (type) {
      case "country":
        setCountryID(e.id);
        setGovernorateID(null);
        setMunicipalityID(null);
        setVillageID(null);
        setBasinID(null);
        setSectorID(null);
        setParcelID(null);
        info = {
          type: type,
          id: e.primaryid,
          labelField: "country_name",
          idField: "id",
        };
        break;
      case "governorate":
        setGovernorateID(e.id);
        setMunicipalityID(null);
        setVillageID(null);
        setBasinID(null);
        setSheetNo(null);
        setSectorID(null);
        setParcelID(null);
        info = {
          type: type,
          id: e.primaryid,
          labelField: "gov_a",
          idField: "id",
        };
        break;
      case "municipality":
        setMunicipalityID(e.id);
        setVillageID(null);
        setBasinID(null);
        setSheetNo(null);
        setSectorID(null);
        setParcelID(null);
        info = {
          type: type,
          id: e.primaryid,
          labelField: "munic_name",
          idField: "id",
        };
        break;
      case "village":
        setVillageID(e.id);
        setBasinID(null);
        setSheetNo(null);
        setSectorID(null);
        setParcelID(null);
        info = {
          type: type,
          id: e.primaryid,
          labelField: "vill_aname",
          idField: "id",
        };
        break;
      case "basin":
        setBasinID(e.id);
        setSheetNo(null);
        setSectorID(null);
        setParcelID(null);
        info = {
          type: type,
          id: e.primaryid,
          labelField: "hod_aname",
          idField: "id",
        };
        break;
      case "sheet":
        setSheetNo(e.id);
        setSectorID(null);
        setParcelID(null);
        info = {
          type: type,
          id: e.id,
          labelField: "plot_no",
          idField: "dls_key",
          basinID: basinID,
          villageID: villageID,
        };
        break;
      case "sector":
        setSectorID(e.id);
        setParcelID(null);
        info = {
          type: type,
          id: e.id,
          labelField: "plot_no",
          idField: "dls_key",
          basinID: basinID,
          villageID: villageID,
        };
        break;
      case "parcel":
        setParcelID(e.id);
        info = {
          type: type,
          id: e.id,
          labelField: "plot_no",
          idField: "dls_key",
        };
        break;
      default:
        return;
    }
    if (drawFilter) drawFilter(info);
  };

  const onClearSelection = (type) => {
    switch (type) {
      case "country":
        setCountryID(null);
        governorateDDL.current.clearSelectionOutside();
        break;
      case "governorate":
        setGovernorateID(null);
        municipalityDDL.current.clearSelectionOutside();
        break;
      case "municipality":
        setMunicipalityID(null);
        villageDDL.current.clearSelectionOutside();
        break;
      case "village":
        setVillageID(null);
        basinDDL.current.clearSelectionOutside();
        break;
      case "basin":
        setBasinID(null);
        sheetDDL.current.clearSelectionOutside();
        break;
      case "sheet":
        setSheetNo(null);
        sectorDDL.current.clearSelectionOutside();
        break;
      case "sector":
        setSectorID(null);
        setParcelID(null);
        parcelDDL.current.clearSelectionOutside();
        break;
      case "parcel":
        setParcelID(null);
        break;
      default:
        return;
    }
  };

  return (
    <>
      {LayersNames.country && (
        <DropDownShift
          searchLabel={resources.LandUse.country}
          onSelectedChange={(e) => onFilterChange("country", e)}
          fetchData={(value) => fillFilter("country", value)}
          searchPlaceHolder={resources.LandUse.country}
          textField="label"
          clearSelection={() => onClearSelection("country")}
          onclickClear={() => clearFilterFeatures("country")}
        />
      )}
      {LayersNames.governorate && (
        <DropDownShift
          ref={governorateDDL}
          searchLabel={resources.LandUse.governorates}
          onSelectedChange={(e) => onFilterChange("governorate", e)}
          fetchData={(value) => fillFilter("governorate", value)}
          searchPlaceHolder={resources.LandUse.governorates}
          textField="label"
          clearSelection={() => onClearSelection("governorate")}
          onclickClear={() => clearFilterFeatures("governorate")}
        />
      )}
      {LayersNames.municipality && (
        <DropDownShift
          ref={municipalityDDL}
          searchLabel={resources.LandUse.municipality}
          onSelectedChange={(e) => onFilterChange("municipality", e)}
          fetchData={(value) => fillFilter("municipality", value)}
          searchPlaceHolder={resources.LandUse.municipality}
          textField="label"
          clearSelection={() => onClearSelection("municipality")}
          onclickClear={() => clearFilterFeatures("municipality")}
        />
      )}
      <DropDownShift
        ref={villageDDL}
        searchLabel={resources.LandUse.Village}
        onSelectedChange={(e) => onFilterChange("village", e)}
        fetchData={(value) => fillFilter("village", value)}
        searchPlaceHolder={resources.LandUse.Village}
        textField="label"
        clearSelection={() => onClearSelection("village")}
        onclickClear={() => clearFilterFeatures("village")}
      />
      <DropDownShift
        ref={basinDDL}
        searchLabel={resources.LandUse.Basin}
        onSelectedChange={(e) => onFilterChange("basin", e)}
        fetchData={(value) => fillFilter("basin", value)}
        searchPlaceHolder={resources.LandUse.Basin}
        textField="label"
        clearSelection={() => onClearSelection("basin")}
        onclickClear={() => clearFilterFeatures("basin")}
      />
      {LayersNames.sheets && (
        <DropDownShift
          ref={sheetDDL}
          searchLabel={resources.LandUse.sheet}
          onSelectedChange={(e) => onFilterChange("sheet", e)}
          fetchData={(value) => fillFilter("sheet", value)}
          searchPlaceHolder={resources.LandUse.sheet}
          textField="label"
          clearSelection={() => onClearSelection("sheet")}
          onclickClear={() => clearFilterFeatures("sheet")}
        />
      )}
      <DropDownShift
        ref={sectorDDL}
        searchLabel={resources.LandUse.Sector}
        onSelectedChange={(e) => onFilterChange("sector", e)}
        fetchData={(value) => fillFilter("sector", value)}
        searchPlaceHolder={resources.LandUse.Sector}
        textField="label"
        clearSelection={() => onClearSelection("sector")}
        onclickClear={() => clearFilterFeatures("sector")}
      />
      <DropDownShift
        ref={parcelDDL}
        searchLabel={resources.LandUse.Parcel}
        onSelectedChange={(e) => onFilterChange("parcel", e)}
        fetchData={(value) => fillFilter("parcel", value)}
        searchPlaceHolder={resources.LandUse.Parcel}
        textField="label"
        clearSelection={() => onClearSelection("parcel")}
        onclickClear={() => clearFilterFeatures("parcel")}
      />
    </>
  );
}

LandUsefilters.propTypes = {
  drawFilter: PropTypes.func,
  clearFilterFeatures: PropTypes.func,
  userVillages: PropTypes.string,
};
