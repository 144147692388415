import { usersActions } from "./index";

export const users = {
	/**
	 * @param {{Email: string; Password: string;}} credentials
	 */
	loginRequest: credentials => ({
		type: usersActions.LOGIN_REQUEST_START,
		payload: credentials
	}),
	logoutRquest: () => ({
		// TODO: Handle actual logout request (START) and log the event
		type: usersActions.LOGOUT_REQUEST_SUCCESS
	}),
	login: userData => ({
		type: usersActions.LOGIN_REQUEST_SUCCESS,
		payload: userData
	}),
	loginFailed: () => ({
		type: usersActions.LOGIN_REQUEST_FAILED
	}),
	loginConnectionError: err => ({
		type: usersActions.CONNECTION_ERROR,
		payload: err
	})
};
