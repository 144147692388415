import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GeoServerApi } from "../../../../api/geoserverController";
import LayersNames from "../../../../helpers/LayersNames";

export default function IrbidReportDetails({ hodName, villageName, dlsKey, withApartment }) {
  const [villageCode, setVillageCode] = useState("");
  const [hodCode, setHodCode] = useState("");
  const [sheetNo, setSheetNo] = useState("");
  const [parcelId, setParcelId] = useState("");
  const [sectorCode, setSectorCode] = useState("");
  const [area, setArea] = useState("");

  const getParcelData = async () => {
    const response = await GeoServerApi.getLayer({
      layer: LayersNames.parcels,
      max: 1,
      fields: "vill_code,hod_code,sheet_no,parcel_id,sector_cod,shape_area,dls_key",
      cqlFilter: `dls_key='${dlsKey}'`,
    }).catch((e) => {
      console.log(e);
    });
    if (response && response.data && response.data.features) {
      if (response.data.features.length) {
        let feature = response.data.features[0];
        setVillageCode(feature.properties.vill_code);
        setHodCode(feature.properties.hod_code);
        setSheetNo(feature.properties.sheet_no);
        setParcelId(feature.properties.parcel_id);
        setArea(feature.properties.shape_area);
        setSectorCode(feature.properties.sector_cod);
      }
    }
  };

  useEffect(() => {
    getParcelData();
  }, []);

  const label = (label, text) => {
    return (
      <div>
        <label className="irbid-report-details-lbl">{label}:</label>
        <label> {`${text ? text : "(..............)"}`} </label>
      </div>
    );
  };
  return (
    <>
      <div className="irbid-report-details-h">
        <div>
          <label className="irbid-report-details-lbl"> اسم المالك:</label>
          <input className="irbid-report-details-input" />
        </div>
        {label("البلدة", `${villageCode}/${villageName}`)}
        {label("الحوض", hodCode + (hodName !== "null" ? `/${hodName}` : ""))}
      </div>
      <div className="irbid-report-details">
        {label("الحي", `${sectorCode}`)}
        {label("رقم اللوحة", sheetNo)}
        <div>
          <label className="irbid-report-details-lbl">رقم القطعة:</label>
          {withApartment == "true" && (
            <>
              <input className="irbid-report-details-apart" />/
            </>
          )}
          <label className="irbid-report-details-lbl">{parcelId}</label>
        </div>
        <div>
          <label className="irbid-report-details-lbl">المساحة:</label>
          <input className="irbid-report-details-input-area" />
        </div>
      </div>
      <div>
        <label className="irbid-report-details-lbl">أحكام التنظيم:</label>
        <input className="irbid-report-details-input-rule" />
      </div>
    </>
  );
}

IrbidReportDetails.propTypes = {
  data: PropTypes.object,
};
