import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Button, Label } from "reactstrap";
import settingsApi, { isSuccessfulRequest } from "../../../../api/settingsApi";
import { useHistory } from "react-router-dom";
import { resources } from "../../../../assets/LocalizationResources";
import SettingsPageWrapper from "../SettingsPageWrapper";

export default function LayerUsers(props) {
  const router = useHistory();

  const state = props.location.state;
  const [selectedUsers, setselectedUsers] = useState(null);
  const [users, setUsers] = useState([]);

  const getAllUsers = async () => {
    const response = await settingsApi.users();
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      setUsers(
        response.data.map(user => ({
          label: user.Name,
          value: user.ID
        }))
      );
    }
  };

  const addLayerUsers = async () => {
    let UserIDs = [];
    selectedUsers.forEach(e => {
      UserIDs.push(e.value);
    });
    const response = await settingsApi.addLayerUsers({
      UserIDs: UserIDs.join(","),
      MapLayerID: state.layerID,
      CreatedByID: 1 /*To Do add login user */
    });
    if (isSuccessfulRequest(response)) router.push("./layers");
    //ToDo : Handle Add Layer Error
  };

  const getselectedUsers = async () => {
    const response = await settingsApi.layerusers({ MapLayerID: state.layerID });
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      setselectedUsers(
        response.data.map(user => ({
          label: user.Name,
          value: user.ID
        }))
      );
    }
  };
  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (users && users.length > 0) getselectedUsers();
  }, [users]);

  const handleChange = selectedUsers => {
    setselectedUsers(selectedUsers);
  };

  const onAddLayerUsers = () => {
    if (selectedUsers) addLayerUsers();
  };

  return (
    <SettingsPageWrapper title={resources.settingsResources.layers.addUsers}>
      <Label style={styles.Label}>اسم الطبقة : {state.layerName}</Label>
      <div style={styles.select}>
        <Select
          isMulti
          isSearchable
          placeholder="اختر المستخدمين"
          value={selectedUsers}
          onChange={handleChange}
          options={users}
          noOptionsMessage={() => {
            return "لا يوجد";
          }}
          styles={styles.selectStyles}
        />
      </div>
      <Button color="info" onClick={onAddLayerUsers}>
        حفظ
      </Button>
    </SettingsPageWrapper>
  );
}

const styles = {
  Label: {
    fontWeight: "bold"
  },
  select: {
    width: "400px",
    margin: "10px 0"
  },
  selectStyles: {
    multiValue: provided => {
      return {
        ...provided,
        backgroundColor: "#c6edf3",
        fontWeight: "bold",
        // paddingBottom: 3,
        lineHeight: "2",
        paddingRight: 5,
        borderRadius: 4
      };
    }
  }
};
