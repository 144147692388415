import React, { useEffect, useState } from "react";
import Table from "../../../ui-components/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { resources } from "../../../../assets/LocalizationResources";
import settingsApi, { isSuccessfulRequest } from "../../../../api/settingsApi";
import SettingsPageWrapper from "../SettingsPageWrapper";
import { Button } from "reactstrap";

export default function LayersView() {
  const router = useHistory();
  const [layers, setlayers] = useState([]);

  const getAllLayers = async () => {
    const response = await settingsApi.layers();
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      setlayers(response.data);
    }
  };

  useEffect(() => {
    getAllLayers();
  }, []);

  const getUsersCell = e => {
    return (
      <span
        title={resources.settingsResources.layers.addUsers}
        style={styles.icon}
        onClick={() => {
          router.push("/settings/layers/layerUsers", {
            layerID: e.row.original["ID"],
            layerName: e.row.original["Name"]
          });
        }}
      >
        <FontAwesomeIcon icon="user-plus" />
      </span>
    );
  };

  const getEditCell = e => {
    return (
      <span
        title={resources.settingsResources.layers.editLayer}
        style={styles.icon}
        onClick={() => {
          router.push("/settings/layers/ManageLayer", e.row.original);
        }}
      >
        <FontAwesomeIcon icon="edit" />
      </span>
    );
  };

  const columns = [
    { Header: "اسم الطبقة ", accessor: "Name" },
    { Header: "المنشئ", accessor: "CreatedBy" },
    { Header: "تاريخ الإنشاء", accessor: "CreatedDate", Cell: ({ cell }) => new Date(cell.value).toLocaleString() },
    {
      id: "edit",
      filterable: false,
      Cell: getEditCell
    },
    { id: "add", filterable: false, Cell: getUsersCell },
    ,
  ];

  return (
    <SettingsPageWrapper title={resources.layers}>
      <Button style={styles.btn} color="default" onClick={() => router.push("/settings/layers/ManageLayer")}>
        <span style={styles.icon}>
          <FontAwesomeIcon icon="plus" /> {resources.settingsResources.layers.addLayer}
        </span>
      </Button>
      <Table columns={columns} data={layers} withPagination />
    </SettingsPageWrapper>
  );
}

const styles = {
  icon: {
    fontSize: "16px",
    color: "#1f3559",
    cursor: "pointer"
  },
  btn: {
    marginRight: 10,
    border: "1px solid #ced4da",
    paddingBottom: 10
  }
};
