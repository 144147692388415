import React from "react";
import BaseMenu from "./BaseSearchMenu";
import { resources } from "../../../../assets/LocalizationResources";
import LayersNames from "../../../../helpers/LayersNames";

export default function VillagesMenu({ map, onFeatureSelected }) {
  return (
    <BaseMenu
      layerName={LayersNames.villages}
      filterField="vill_aname"
      idField="id"
      labelField="vill_aname"
      searchFieldLabel="اسم القرية"
      icon="search"
      map={map}
      onFeatureSelected={onFeatureSelected}
      layerFields="geometry_spa,id,vill_aname,vill_code,shape_area,distrect_a,mun_code,munic_code,vill_ename"
      infoFields={[
        {
          field: "distrect_a",
          label: "المنطقة",
          icon: "map-signs",
          valueExp: (val) => val || resources.misc.noData,
        },
        {
          field: "shape_area",
          label: "المساحة",
          icon: "vector-square",
          postLabel: <span>كم&#178;</span>,
          valueExp: (val) => val || resources.misc.noData,
        },
      ]}
    />
  );
}
