import React from "react";
import BaseMenu from "./BaseSearchMenu";
import { resources } from "../../../../assets/LocalizationResources";
import LayersNames from "../../../../helpers/LayersNames";

export default function ParcelsMenu({ map, onFeatureSelected }) {
  return (
    <BaseMenu
      layerName={LayersNames.parcels}
      filterField="dls_key"
      idField="id"
      labelField="dls_key"
      searchFieldLabel="أي جزء من ال DLS Key"
      icon="search"
      map={map}
      onFeatureSelected={onFeatureSelected}
      layerFields="geometry_spa,id,dls_key,parcel_id,shape_area,vill_code,hod_code,sector_cod,sheet_no,mun_code,munic_code"
      infoFields={[
        {
          field: "shape_area",
          label: "المساحة",
          icon: "vector-square",
          postLabel: <span>م&#178;</span>,
          valueExp: (val) => {
            if (!val) return resources.misc.noData;
            else return parseFloat(val).toFixed(3);
          },
        },
      ]}
    />
  );
}
