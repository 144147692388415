import React from "react";
import BaseMenu from "./BaseSearchMenu";
import { resources } from "../../../../assets/LocalizationResources";
import LayersNames from "../../../../helpers/LayersNames";

export default function BassinsMenu({ map, onFeatureSelected }) {
  return (
    <BaseMenu
      layerName={LayersNames.basins}
      filterField="hod_aname"
      idField="id"
      labelField="hod_aname"
      searchFieldLabel="اسم الحوض"
      icon="search"
      map={map}
      onFeatureSelected={onFeatureSelected}
      layerFields="geometry_spa,id,hod_aname,hod_code,hod_area,vill_aname"
      infoFields={[
        {
          field: "hod_area",
          label: "المساحة",
          icon: "vector-square",
          postLabel: <span>م&#178;</span>,
          valueExp: (val) => {
            if (!val) return resources.misc.noData;
            else return parseFloat(val).toFixed(3);
          },
        },
        {
          field: "vill_aname",
          label: "القرية",
          icon: "map",
          valueExp: (val) => val || resources.misc.noData,
        },
      ]}
    />
  );
}
