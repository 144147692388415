import React from "react";

export default function ReportFooter() {
  const currentDate = `${new Date().getDate()}/${
    new Date().getMonth() + 1
  }/${new Date().getFullYear()} `;
  return (
    <>
      <div className="irbid-report-footer-container">
        <label style={styles.label}>
          صدر وفق المخططات التنظيمية المصدقة و بموجب القوانين والأنظمة ذات
          العلاقة.
        </label>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            margin: "0px",
          }}
        >
          <label style={styles.label}>صدر بموجب سند تسجيل رقم</label>
          <input
            style={{
              fontSize: "12px",
              height: "25px",
              width: "200px",
              margin: "0px 5px",
            }}
          />
          <label> بتاريخ </label>
          <input
            style={{
              fontSize: "12px",
              height: "25px",
              width: "200px",
              margin: "0px 5px",
            }}
          />
        </div>

        <label style={styles.label}>
          يعمل بهذا المخطط لمدة سنة من تاريخ تصديق المهندس عليه وغير قابل
          للتجديد
        </label>
        <label style={styles.label}>
          يعتبر هذا المخطط لاغيا في حال أجرت عليه إضافات او كشوطات خلافا لما
          أصدر عليه
        </label>
        <label style={styles.label}>الخط الأحمر حد الشارع التنظيمي </label>
        <input
          style={{
            fontWeight: "bold",
            display: "block",
            fontSize: "12px",
            height: "25px",
            width: "99.5%",
            margin: "5px 0px 5px 5px",
          }}
        />
      </div>
      <div className="irbid-report-footer-container">
        <div className="irbid-report-footer">
          <div>
            <label className="irbid-report-footer-label">رسم :</label>
            <input className="irbid-report-footer-input" />
          </div>
          <div>
            <label className="irbid-report-footer-label">توقيعه:</label>
            <input className="irbid-report-footer-input" />
          </div>
          <div>
            <label className="irbid-report-footer-label">
              التاريخ:{currentDate}م
            </label>
          </div>
        </div>
        <div className="irbid-report-footer">
          <div>
            <label className="irbid-report-footer-label">تدقيق :</label>
            <input className="irbid-report-footer-input" />{" "}
          </div>
          <div>
            <label className="irbid-report-footer-label">توقيعه:</label>
            <input className="irbid-report-footer-input" />{" "}
          </div>
          <div>
            <label className="irbid-report-footer-label">
              التاريخ:{currentDate}م
            </label>
          </div>
        </div>
        <div className="irbid-report-footer">
          <div>
            <label className="irbid-report-footer-label">تصديق مهندس :</label>
            <input className="irbid-report-footer-input" />
          </div>
          <div>
            <label className="irbid-report-footer-label">توقيعه:</label>
            <input className="irbid-report-footer-input" />
          </div>
          <div>
            <label className="irbid-report-footer-label">
              التاريخ:{currentDate}م
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

const styles = {
  label: {
    display: "block",
    padding: 5,
  },
  table: {
    margin: 5,
    width: "738px",
  },
  box: {
    border: "1px solid black ",
    height: 20,
    width: 120,
  },
};
