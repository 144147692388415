import React from "react";
import OlMap from "ol/Map";
import { Tile as TileLayer, Group as LayerGroup, Vector as VectorLayer } from "ol/layer";
import OlView from "ol/View";
import { OSM, BingMaps, XYZ, Vector as VectorSource, TileWMS } from "ol/source";
import { defaults as defaultControls, MousePosition, ScaleLine, FullScreen, ZoomSlider, Rotate } from "ol/control";
import { defaults as defaultInteractions, DragRotateAndZoom } from "ol/interaction";
import { createStringXY } from "ol/coordinate";
import LayerSwitcher from "ol-layerswitcher";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style.js";
import Point from "ol/geom/Point.js";
import Feature from "ol/Feature";
import { GeoJSON } from "ol/format";
import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { currentSelectedSearchFeatureStyle, defaultSearchFeatureStyle, defaultNearbyFeatureStyle } from "./LayerStyles";
import Constants from "./Constants";
import LayersNames from "./LayersNames";
import { resources } from "../assets/LocalizationResources";
import defaultExtendedControls from "./MapControls";
// import {fetchMapLayers} from './../Controllers/MapLayersController';

proj4.defs(
  "EPSG:28191",
  "+proj=cass +lat_0=31.73409694444445 +lon_0=35.21208055555556 +x_0=170251.555 +y_0=126867.909 +a=6378300.789 +b=6356566.435 +towgs84=-275.722,94.7824,340.894,-8.001,-4.42,-11.821,1 +units=m +no_defs"
);

register(proj4);

const ServerPath = Constants.GceMapUrl;

let currentSelectedSearchFeature = null;

const searchVectorSource = new VectorSource({
  features: [],
});
const searchVectorLayer = new VectorLayer({
  id: "searchLayer",
  title: "نتائج البحث",
  visible: true,
  source: searchVectorSource,
  zIndex: 300,
  style: defaultSearchFeatureStyle,
  renderMode: "image",
  renderOrder: null,
});

const nearbyVectorSource = new VectorSource({
  features: [],
});
const nearbyVectorLayer = new VectorLayer({
  id: "nearbyLayer",
  title: "الاراضي المجاورة",
  visible: true,
  source: nearbyVectorSource,
  zIndex: 300,
  style: defaultNearbyFeatureStyle,
});

// const markersClusterSource = new Cluster({
//   distance: 10,
//   source: markersVectorSource
// });
const markersVectorSource = new VectorSource({
  features: [],
});

// const styleCache = {};
// const typeStyleCache = {};
const markersLayer = new VectorLayer({
  title: "المعالم",
  visible: false,
  source: markersVectorSource,
  zIndex: 10,
  // ToDo: Modify this method to handle generic styles -------------------------------------------------- :/
  style: function (feature) {
    return new Style({
      image: new CircleStyle({
        radius: 4,
        stroke: new Stroke({
          color: "#fff",
        }),
        fill: new Fill({
          color: "#319FD3",
        }),
      }),
    });
    //
    //   const size = feature.get('features').length;
    //   let style;
    //   if (size === 1) style = typeStyleCache[feature.get('features')[0].get('TypeID')];
    //   else style = styleCache[size];
    //
    //   if (!style) {
    //     style =
    //       size === 1
    //         ? new Style({
    //           image: new Icon({
    //             anchor: [0.5, 0.5],
    //             anchorXUnits: 'fraction',
    //             anchorYUnits: 'pixels',
    //             scale: 0.25,
    //             src: Constants.ComplaintTypes[
    //               feature.get('features')[0].get('TypeID')
    //               ]
    //               ? Constants.ComplaintTypes[
    //                 feature.get('features')[0].get('TypeID')
    //                 ].image
    //               : Constants.ComplaintTypes[0].image
    //           })
    //         })
    //         : new Style({
    //           image: new CircleStyle({
    //             radius: 15,
    //             stroke: new Stroke({
    //               color: '#fff'
    //             }),
    //             fill: new Fill({
    //               color: '#3399CC'
    //             })
    //           }),
    //           text: new Text({
    //             text: size.toString(),
    //             fill: new Fill({
    //               color: '#fff'
    //             })
    //           })
    //         });
    //
    //     if (size === 1) typeStyleCache[feature.get('features')[0].get('TypeID')] = style;
    //     else styleCache[size] = style;
    //   }
    //   return style;
  },
});

export const searchAndAnalyzeLayer = new TileLayer({
  title: "نتائج تحليل البيانات",
  visible: false,
  opacity: 1,
  source: new TileWMS({
    url: `http://${Constants.geoServerPath}/${Constants.geoserverWorkspace}/wms`,
    params: {
      service: "WMS",
      version: "1.1.0",
      request: "GetMap",
      layers: `${Constants.geoServerPath}:${LayersNames.landusePublic}`,
      bbox: "226399.3125,214244.109375,233425.203125,221475.828125",
      srs: "EPSG:28191",
    },
    serverType: "geoserver",
  }),
});

const markersLayerGroup = () =>
  new LayerGroup({
    id: "secondaryLayerGroup",
    title: resources.mapPage.layers,
    combine: false,
    layers: [markersLayer, searchVectorLayer, nearbyVectorLayer, searchAndAnalyzeLayer],
  });

export const LoadGeoserverLayers = (mapRef, userID = 0) => {
  // if (!mapRef) return;
  // fetchMapLayers(userID).then(
  //   layers => {
  //     const geoserverLayers = [];
  //     for (let index = 0; index < layers.length; index++) {
  //       const layer = layers[index];
  //       if (layer.ServiceType === 2) {
  //         geoserverLayers.push(
  //           new TileLayer({
  //             title: layer.Name,
  //             visible: layer.Visible,
  //             opacity: layer.Opacity,
  //             source: new TileWMS({
  //               url: Constants.geoServerPath + layer.URL,
  //               serverType: 'geoserver'
  //               // url: `${Constants.geoServerPath}/geoserver/GCE_SQL_SERVER/wms`,
  //               // params: {'LAYERS': 'GCE_SQL_SERVER:georestriction_1_8', 'TILED': true},
  //             })
  //           })
  //         );
  //       }
  //     }
  //
  //     const geoserverLayerGroup = new LayerGroup({
  //       title: 'طبقات المستخدم',
  //       combine: false,
  //       layers: geoserverLayers
  //     });
  //
  //     mapRef.addLayer(geoserverLayerGroup)
  //   },
  //   error => console.log(error)
  // );
};

const loadGeoserverUserLayers = (mapRef) => {
  const geoserverLayers = [
    new TileLayer({
      title: resources.mapMenu.roads,
      visible: false,
      opacity: 0.5,
      source: new TileWMS({
        url: `http://${Constants.geoServerPath}/${Constants.geoserverWorkspace}/wms`,
        params: {
          service: "WMS",
          version: "1.1.0",
          request: "GetMap",
          layers: `${Constants.geoserverWorkspace}:${LayersNames.street}`,
          bbox: "226399.3125,214244.109375,233425.203125,221475.828125",
          srs: "EPSG:28191",
        },
        serverType: "geoserver",
      }),
    }),
    new TileLayer({
      title: resources.mapMenu.basins,
      visible: false,
      opacity: 0.5,
      source: new TileWMS({
        url: `http://${Constants.geoServerPath}/${Constants.geoserverWorkspace}/wms`,
        params: {
          service: "WMS",
          version: "1.1.0",
          request: "GetMap",
          layers: `${Constants.geoserverWorkspace}:${LayersNames.basins}`,
          bbox: "219088.609375,198322.5,242536.984375,231180.765625",
          srs: "EPSG:28191",
        },
        serverType: "geoserver",
      }),
    }),
    new TileLayer({
      title: resources.mapMenu.parcels,
      visible: false,
      opacity: 0.5,
      source: new TileWMS({
        url: `http://${Constants.geoServerPath}/${Constants.geoserverWorkspace}/wms`,
        params: {
          service: "WMS",
          version: "1.1.0",
          request: "GetMap",
          layers: `${Constants.geoserverWorkspace}:${LayersNames.parcels}`,
          bbox: "218264.78125,198521.28125,245535.578125,230929.5625",
          srs: "EPSG:28191",
        },
        serverType: "geoserver",
      }),
    }),
    new TileLayer({
      title: resources.mapMenu.landuse,
      visible: false,
      opacity: 0.5,
      source: new TileWMS({
        url: `http://${Constants.geoServerPath}/${Constants.geoserverWorkspace}/wms`,
        params: {
          service: "WMS",
          version: "1.1.0",
          request: "GetMap",
          layers: `${Constants.geoserverWorkspace}:${LayersNames.landusePublic}`,
          bbox: "226399.3125,214244.109375,233425.203125,221475.828125",
          srs: "EPSG:28191",
        },
        serverType: "geoserver",
      }),
    }),
    // new TileLayer({
    //     title: "ECW",
    //     visible: false,
    //     opacity: 1,
    //     source: new XYZ({
    //         url: "http://localhost:9000/API/Raster?z={z}&x={x}&y={y}&lang=ar&clear=0"
    //     })
    // })
  ];

  const geoserverLayerGroup = new LayerGroup({
    title: resources.mapPage.userMapLayers,
    combine: false,
    layers: geoserverLayers,
  });

  mapRef.addLayer(geoserverLayerGroup);
};

const baseLayerGroup = ({ defaultMap }) =>
  new LayerGroup({
    title: resources.mapPage.baseMapLayers,
    combine: false,
    layers: [
      new TileLayer({
        title: "MOMA Analyze",
        visible: defaultMap === "MOMA Analyze",
        type: "base",
        source: new TileWMS({
          url: `http://${Constants.geoServerPath}/${Constants.geoserverWorkspace}/wms`,
          params: {
            service: "WMS",
            version: "1.1.0",
            request: "GetMap",
            layers: `${Constants.geoserverWorkspace}:${LayersNames.analyzeBaseMap}`,
            bbox: "226399.3125,214244.109375,233425.203125,221475.828125",
            srs: "EPSG:28191",
          },
          serverType: "geoserver",
        }),
      }),
      new TileLayer({
        title: "MOMA",
        visible: defaultMap === "MOMA",
        type: "base",
        source: new TileWMS({
          url: `http://${Constants.geoServerPath}/${Constants.geoserverWorkspace}/wms`,
          params: {
            service: "WMS",
            version: "1.1.0",
            request: "GetMap",
            layers: `${Constants.geoserverWorkspace}:${LayersNames.baseMap}`,
            bbox: "226399.3125,214244.109375,233425.203125,221475.828125",
            srs: "EPSG:28191",
          },
          serverType: "geoserver",
        }),
      }),
      new TileLayer({
        title: "OSM",
        visible: defaultMap === "OSM",
        type: "base",
        source: new OSM(),
      }),
      new TileLayer({
        title: "Bing",
        visible: defaultMap === "Bing",
        type: "base",
        source: new BingMaps({
          key: "AsJihSDzsujaimLwbAvgi5cSJlxtXkpN0nOP7BGQCr7P-oO_TFboQ1AvZZ-85AUj",
          imagerySet: "Road",
        }),
      }),
      new TileLayer({
        title: "Google Satellite",
        visible: defaultMap === "GoogleSatellite",
        type: "base",
        source: new XYZ({
          url: "http://mt{1-3}.google.com/vt/lyrs=s,h@129&hl=ar&x={x}&y={y}&z={z}&s=Galileo",
        }),
      }),
      new TileLayer({
        title: "Google",
        visible: defaultMap === "Google",
        type: "base",
        source: new XYZ({
          url: "http://mt{1-3}.google.com/vt/lyrs=m@129&hl=ar&x={x}&y={y}&z={z}&s=Galileo",
        }),
      }),
      new TileLayer({
        title: "GCE",
        visible: defaultMap === "GCE",
        type: "base",
        source: new XYZ({
          url: "http://" + ServerPath + "/EMMAP/JordanTile.aspx?z={z}&x={x}&y={y}&lang=ar&clear=0",
        }),
      }),
    ],
  });

// Remove the "Attribution" control
const defControls = defaultControls();
defControls.getArray().pop();

const mousePositionControl = new MousePosition({
  coordinateFormat: createStringXY(6),
  projection: "EPSG:4326",
  className: "custom-mouse-position",
  target: document.getElementById("mouse-position"),
  undefinedHTML: " ",
});

export const CreateMap = ({
  center = [330183.82, 50374.53],
  zoom = 7,
  controls = null,
  callOut = null,
  mapRef = null,
  defaultMapProvider = "MOMA",
  isEmpty = false,
  withRotation = false,
}) => {
  const map = new OlMap({
    loadTilesWhileAnimating: true,
    target: mapRef,
    view: new OlView({
      center: center,
      projection: "EPSG:28191",
      zoom: zoom,
      extent: [69099.91, -225947.9, 614689.82, 358373.34],
    }),
    interactions: !isEmpty
      ? defaultInteractions().extend([new DragRotateAndZoom()])
      : defaultInteractions({ mouseWheelZoom: false }),
    controls: !isEmpty
      ? controls ||
        defControls.extend([
          mousePositionControl,
          new ScaleLine(),
          new FullScreen(),
          new ZoomSlider(),
          new LayerSwitcher(),
        ])
      : withRotation
      ? [
          new Rotate({
            autoHide: false,
          }),
        ]
      : [],
    layers: !isEmpty
      ? [baseLayerGroup({ defaultMap: defaultMapProvider }), markersLayerGroup()]
      : [markersLayerGroup()],
  });

  loadGeoserverUserLayers(map);

  if (callOut) {
    map.on("click", function (e) {
      try {
        const pixel = map.getEventPixel(e.originalEvent);
        const hit = map.hasFeatureAtPixel(pixel);
        const feature = hit ? map.getFeaturesAtPixel(pixel)[0] : null;
        if (feature) {
          callOut(feature);
        }
      } catch (e) {
        console.log("e", e);
      }
    });
  }
  if (!isEmpty) {
    for (const extControl of defaultExtendedControls(map)) {
      map.addControl(extControl);
    }
  }

  return map;
};

export const CreateMapComponent = (center, zoom) => {
  const map = CreateMap({ center: center, zoom: zoom, controls: [] });
  setTimeout(() => {
    map.setTarget("mini-map");
    map.updateSize();
  }, 100);
  setTimeout(() => {
    map.renderSync();
  }, 500);
  return (
    <div
      id="mini-map"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 100,
      }}
    />
  );
};

export const AddMarker = (mapRef, xy, extraAttributes) => {
  if (mapRef && xy) {
    const iconFeature = new Feature({
      geometry: new Point(xy),
      ...extraAttributes,
    });
    markersVectorSource.addFeature(iconFeature);
  }
};

export const AddMarkersArray = (mapRef, data) => {
  if (mapRef && data && data.length) {
    for (let i = 0; i < data.length; i++) {
      AddMarker(mapRef, data[i].coords, data[i]);
    }
  }
};

export const clearMarkers = () => {
  markersVectorSource.clear();
};

export const ZoomTo = (mapRef, xy, zoom = 16) => {
  if (mapRef && xy) {
    mapRef.getView().setCenter(xy);
    mapRef.getView().setZoom(zoom);
  }
};

export const FlyTo = (mapRef, xy, zoom = 17, done) => {
  if (mapRef && xy) {
    const view = mapRef.getView();
    const currentZoom = view.getZoom();
    const duration = 1500;
    let parts = 2;
    let called = false;

    const callback = (complete) => {
      --parts;
      if (called) {
        return;
      }
      if (parts === 0 || !complete) {
        called = true;
        if (done) done(complete);
      }
    };

    view.animate(
      {
        center: xy,
        duration: duration,
      },
      callback
    );
    view.animate(
      {
        zoom: currentZoom - 1,
        duration: duration / 2,
      },
      {
        zoom: zoom,
        duration: duration / 2,
      },
      callback
    );
  }
};

export const getLayer = ({ groupID, layerID, map }) => {
  let rLayer;
  map.getLayers().forEach((group) => {
    if (group.get("id") === groupID) {
      group.getLayers().forEach((layer) => {
        if (layer.get("id") === layerID) {
          rLayer = layer;
        }
      });
    }
  });
  return rLayer;
};

export const getLayerGroup = ({ groupID, map }) => {
  let rGroup;
  map.getLayers().forEach((group) => {
    if (group.get("id") === groupID) {
      rGroup = group;
    }
  });
  return rGroup;
};

export const getLayerSource = ({ groupID, layerID, map }) => {
  let source;
  map.getLayers().forEach((group) => {
    if (group.get("id") === groupID) {
      group.getLayers().forEach((layer) => {
        if (layer.get("id") === layerID) {
          source = layer.getSource();
        }
      });
    }
  });
  return source;
};

export const clearSearchLayerSource = (map) => {
  const source = getLayerSource({
    groupID: "secondaryLayerGroup",
    layerID: "searchLayer",
    map: map,
  });
  source.clear();
};

export const clearNearbyLayerSource = (map) => {
  const source = getLayerSource({
    groupID: "secondaryLayerGroup",
    layerID: "nearbyLayer",
    map: map,
  });
  source.clear();
};

export const AddgeoserverLayerToMap = async (mapRef, layer, clearSource = true, cqlFilter) => {
  const source = getLayerSource({
    groupID: "secondaryLayerGroup",
    layerID: "searchLayer",
    map: mapRef,
  });
  if (clearSource) source.clear();

  const params = {
    service: "WMS",
    version: "1.1.0",
    request: "GetMap",
    layers: `${Constants.geoserverWorkspace}:` + layer,
    bbox: "226399.3125,214244.109375,233425.203125,221475.828125",
    srs: "EPSG:28191",
  };

  if (cqlFilter) params.CQL_FILTER = cqlFilter;

  const Layer = new TileLayer({
    visible: true,
    //  opacity: 0.9,
    source: new TileWMS({
      url: `http://${Constants.geoServerPath}/${Constants.geoserverWorkspace}/wms`,
      params: params,
      serverType: "geoserver",
    }),
  });

  mapRef.addLayer(Layer);
};

export const zoomToFeatureInSearchLayer = ({
  textField,
  idField,
  id,
  map,
  withHighlight = true,
  getFeature = false,
  textFields,
}) => {
  if (currentSelectedSearchFeature) currentSelectedSearchFeature.setStyle(null);

  const source = getLayerSource({
    groupID: "secondaryLayerGroup",
    layerID: "searchLayer",
    map: map,
  });
  for (const feature of source.getFeatures()) {
    if (feature.get(idField) === id) {
      const extent = feature.getGeometry().getExtent();
      if (withHighlight) {
        currentSelectedSearchFeature = feature;
        currentSelectedSearchFeature.setStyle(currentSelectedSearchFeatureStyle(feature, textFields || textField));
      }
      map.getView().fit(extent, {
        duration: 1000,
      });
      break;
    }
  }

  if (getFeature) {
    return currentSelectedSearchFeature;
  }
};

export const highlitedOrZoomMultiFeaturesInSearchLayer = ({
  textField,
  idField,
  map,
  withHighlight = true,
  zoom = true,
  id,
}) => {
  const source = getLayerSource({
    groupID: "secondaryLayerGroup",
    layerID: "searchLayer",
    map: map,
  });

  let features = source.getFeatures().filter((feature) => {
    if ((!id && feature.get(idField)) || (id && feature.get(idField) === id)) {
      if (withHighlight) {
        feature.setStyle(currentSelectedSearchFeatureStyle(feature, textField, 6));
      }
      return true;
    }
    return false;
  });

  if (zoom && features.length > 0) {
    let sourcefeatures = new VectorSource({
      features: features,
    });

    map.getView().fit(sourcefeatures.getExtent(), {
      duration: 1000,
    });
  }
};

export const addGeoJsonFeaturesToSearchLayer = ({ data, map, zoom = false, clearSource = true }) => {
  const source = getLayerSource({
    groupID: "secondaryLayerGroup",
    layerID: "searchLayer",
    map: map,
  });

  if (clearSource) source.clear();

  const features = new GeoJSON({
    // dataProjection: "EPSG:28191",
    // featureProjection: "EPSG:3857"
  }).readFeatures(data);

  source.addFeatures(features);
  if (zoom)
    setTimeout(() => {
      map.getView().fit(source.getExtent(), {
        padding: [170, 50, 30, 150],
        duration: 1000,
      });
    }, 200);
};

export const addGeoJsonFeaturesToNearbyLayer = ({ data, map, zoom = false, clearSource = true }) => {
  const source = getLayerSource({
    groupID: "secondaryLayerGroup",
    layerID: "nearbyLayer",
    map: map,
  });

  if (clearSource) source.clear();

  const features = new GeoJSON({
    // dataProjection: "EPSG:28191",
    // featureProjection: "EPSG:3857"
  }).readFeatures(data);
  source.addFeatures(features);
  if (zoom)
    setTimeout(() => {
      if (source.getFeatures().length)
        map.getView().fit(source.getExtent(), {
          padding: [170, 50, 30, 150],
          duration: 1000,
        });
    }, 200);
};
const getDpi = function () {
  var div = document.createElement("div");
  div.style.height = "1in";
  div.style.width = "1in";
  div.style.top = "100%";
  div.style.left = "100%";
  div.style.position = "absolute";

  document.body.appendChild(div);
  var dpi = div.offsetHeight;
  document.body.removeChild(div);
  console.log("currentDPi=" + dpi);
  return dpi;
};
export const getCenterAndZoomFeature = ({ data, map, scale }) => {
  if (scale) {
    const features = new GeoJSON().readFeatures(data);
    if (features && features.length > 0) {
      const extent = features[0].getGeometry().getExtent();
      let unit = map.getView().getProjection().getMetersPerUnit();
      let resolution;
      let dpi = getDpi();
      let INCHES_PER_Meters = 39.3701;
      resolution = scale / (unit * INCHES_PER_Meters * dpi);
      map.getView().fit(extent, { size: map.getSize() });
      map.getView().setResolution(resolution);
    }
  }
};
