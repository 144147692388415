import Axios from "axios";
import constants from "../helpers/Constants";

const axiosConfig = {
  baseURL: `${window.location.protocol}//${
    window.location.hostname + constants.port
  }/api/auth`,
};
const axios = Axios.create(axiosConfig);

const LocalCache = {};
const ttl = 1000 * 60 * 5; // x minutes request caching

// Axios.interceptors.request.use(
// 	async config => {
// 		//headers: !token ? {} : { Authorization: `Bearer ${token}` }
// 		config.headers["Authorization"] = await authService.getAccessToken();
// 		return config;
// 	},
// 	error => {
// 		return Promise.reject(error);
// 	}
// );

function getRequestWithCache(url) {
  if (
    LocalCache[url] &&
    isSuccessfulRequest(LocalCache[url].status) &&
    LocalCache[url].time > new Date().getTime()
  ) {
    return LocalCache[url].promise;
  }

  const request = axios.get(url);
  LocalCache[url] = {
    promise: request,
    time: new Date().getTime() + ttl,
  };
  return request;
}

export function isSuccessfulRequest(response) {
  // if the status = 400 or more (Failed request) the subtraction will be positive
  const success = response && response.status - 400 < 0 && response.data;
  console.log(success);
  return success;
}

/////////// Service end-points ///////////

const authApi = {
  lgoin: (model) => {
    return axios.post("login", model);
  },
  addUser: (model) => {
    return axios.post("addUser", model);
  },
  editUser: (model) => {
    return axios.post("editUser", model);
  },
  deleteuser: (model) => {
    return axios.post("deleteuser", model);
  },
  changePassword: (model) => {
    return axios.post("changePassword", model);
  },
};

export default authApi;
