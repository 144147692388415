import { Circle as CircleStyle, Fill, Stroke, Text, Style } from "ol/style.js";
import { resources } from "../assets/LocalizationResources";

const stringifyFeatureFields = (feature, fields) => {
  if (!fields.includes(",")) {
    let label = feature.get(fields);
    label = fields === "dls_key" && label ? label.substr(10, 5) : label;
    return label || resources.misc.noData;
  } else {
    return fields.split(",").reduce((p, c) => {
      let label = feature.get(c);
      label = c === "dls_key" && label ? label.substr(10, 5) : label;
      return `${p}\n${label || resources.misc.noData}`;
    }, "");
  }
};

export const currentSelectedSearchFeatureStyle = (feature, textField, textsize = 8) => {
  const label = stringifyFeatureFields(feature, textField);
  return feature.getGeometry().getType() === "Point"
    ? new Style({
        image: new CircleStyle({
          radius: 4,
          fill: new Fill({ color: "#dc3545" }),
          stroke: new Stroke({
            color: "#000",
            width: 1
          })
        }),
        text: new Text({
          text: label ? label.toString() : "",
          fill: new Fill({
            color: "#000"
          }),
          stroke: new Stroke({
            width: 3,
            color: "#FFFFFFAA"
          }),
          scale: 2,
          offsetY: -20,
          overflow: true
        })
      })
    : new Style({
        zIndex: 10,
        stroke: new Stroke({
          width: 3,
          color: "#dc3545"
        }),
        fill: new Fill({
          color: "#00CCBE33"
        }),
        text: new Text({
          text: label ? label.toString() : "",
          fill: new Fill({
            color: "#000"
          }),
          stroke: new Stroke({
            width: 5,
            color: "#FFFFFFAA"
          }),
          font: textsize + "px bold Calibri,sans-serif",
          scale: 2,
          offsetY: -20,
          overflow: true
        })
      });
};

export const defaultNearbyFeatureStyle = feature =>
  feature.getGeometry().getType() === "Point"
    ? new Style({
        image: new CircleStyle({
          radius: 3,
          fill: new Fill({ color: "#007bff" }),
          stroke: new Stroke({
            color: "#000",
            width: 1
          })
        })
      })
    : new Style({
        zIndex: 10,
        stroke: new Stroke({
          width: 1,
          color: "#997bbb"
        }),
        fill: new Fill({
          color: "#997bbb0A"
        }),
        text: new Text({
          text: stringifyFeatureFields(feature, "dls_key,landuse_de"),
          fill: new Fill({
            color: "#000"
          }),
          stroke: new Stroke({
            width: 2,
            color: "#FFFFFFAA"
          }),
          font: "5px bold Calibri,sans-serif",
          scale: 2,
          offsetY: -10,
          overflow: true
        })
      });

const defaultPointStyle = new Style({
  image: new CircleStyle({
    radius: 3,
    fill: new Fill({ color: "#007bff" }),
    stroke: new Stroke({
      color: "#000",
      width: 1
    })
  })
});

const defaultPolygonStyle = new Style({
  zIndex: 10,
  stroke: new Stroke({
    width: 1,
    color: "#007bff"
  }),
  fill: new Fill({
    color: "#007bff0A"
  })
});

export const defaultSearchFeatureStyle = (feature, textField) =>
  feature.getGeometry().getType() === "Point" ? defaultPointStyle : defaultPolygonStyle;
