const isPublic = true;
const _host = window.location.hostname;
const _GceMapUrl = isPublic ? _host : "192.168.100.10";
const port = "";
// const port = ":4000";
//"185.51.215.251";
const _geoServerPath =
  // isPublic ? `${_host}:8080/geoserver` : "localhost:8080/geoserver";
  //"185.51.215.251:8080/geoserver";
//  "172.16.0.200:8080/geoserver";
// "185.10.230.83:8080/geoserver"
   "geoserver.gisjordan.com/geoserver";
//const _geoserverWorkspace = "moma_postgis";
const _geoserverWorkspace = "moma_irbid_postgis";

export default class Constants {
  static host = _host;
  static geoServerPath = _geoServerPath;
  static GceMapUrl = _GceMapUrl;
  static geoserverAuth = "admin:geoserver";
  static geoserverWorkspace = _geoserverWorkspace;
  static port = port;
}
//For MOMA Irbid
// const isPublic = false;
// const _host = window.location.hostname;
// const _GceMapUrl = isPublic ? _host : "192.168.100.10";
// const port = "";
// //"185.51.215.251";
// const _geoServerPath =
//   // isPublic ? `${_host}:8080/geoserver` : "localhost:8080/geoserver";
//   // "185.51.215.251:8080/geoserver";
//   "172.16.0.200:8080/geoserver";
// const _geoserverWorkspace = "moma_irbid_postgis";

// export default class Constants {
//   static host = _host;
//   static geoServerPath = _geoServerPath;
//   static GceMapUrl = _GceMapUrl;
//   static geoserverAuth = "admin:geoserver";
//   static geoserverWorkspace = _geoserverWorkspace;
//   static port = port;
// }
