import React, { useState, useEffect, useRef } from "react";
import Map from "../../../Map/Map";
import { GeoServerApi } from "../../../../api/geoserverController";
import { AddgeoserverLayerToMap, getCenterAndZoomFeature } from "../../../../helpers/MapHelper";
import { debounce } from "throttle-debounce";
import FormInput from "../../../ui-components/FormInput";
import PropTypes from "prop-types";
import "./Report.css";
import LayersNames from "../../../../helpers/LayersNames";

//import InitMeasurementTools from "../../../../helpers/MapMeasurements";

export default function ReportMaps({ dlskey }) {
  const parcellayer = LayersNames.parcels;
  const landuseparcelstreetlayer = LayersNames.landuseReportBaseLayer;
  const selectedParcellayer = LayersNames.parcels;
  const selectedlanduselayer = LayersNames.landUse;

  const [isLoadingparcel, setisLoadingparcel] = useState(false);
  const [isLoadinglanduse, setisLoadinglanduse] = useState(false);
  const [parcelScale, setparcelScale] = useState("10000");
  const [landuseScale, setlanduseScale] = useState("2500");

  const parcelMap = useRef(null);
  const landuseMap = useRef(null);

  const loadmap = async (scale, type) => {
    let map, layer, selectedlayer, filter;

    const response = await GeoServerApi.getLayer({
      layer: parcellayer,
      max: "1",
      fields: "geometry_spa,dls_key",
      cqlFilter: `dls_key='${dlskey}'`,
    }).catch((e) => {
      console.log(e);
    });

    if (response && response.data && response.data.features) {
      if (response.data.features.length) {
        if (type === "parcel") {
          map = parcelMap.current.map;
          layer = parcellayer;
          setisLoadingparcel(true);
          selectedlayer = selectedParcellayer;
          filter = `dls_key ='${dlskey}'`;
        } else if (type === "landuse") {
          map = landuseMap.current.map;
          layer = landuseparcelstreetlayer;
          selectedlayer = selectedlanduselayer;
          filter = `dls_key='${dlskey}'`;
          setisLoadinglanduse(true);
        }

        getCenterAndZoomFeature({
          data: response.data,
          map: map,
          scale: scale,
        });
        AddgeoserverLayerToMap(map, selectedlayer, false, filter);
        AddgeoserverLayerToMap(map, layer);

        setTimeout(() => {
          if (type === "parcel") {
            setisLoadingparcel(false);
          } else if (type === "landuse") {
            setisLoadinglanduse(false);
          }
        }, 1000);
      }
    }
  };
  const scaleSelect = debounce(1000, loadmap);

  useEffect(() => {
    if (dlskey) {
      //For test
      // InitMeasurementTools(parcelMap.current.map);
      // InitMeasurementTools(landuseMap.currentmap);
      loadmap(parcelScale, "parcel");
      loadmap(landuseScale, "landuse");
    }
  }, []);

  const onScalechange = (event, type) => {
    if (!event.target.value) {
      return;
    }

    if (type === "parcel") setparcelScale(event.target.value);
    else setlanduseScale(event.target.value);

    scaleSelect(event.target.value, type);
  };

  return (
    <div className="landuse_Report_map_maincontain">
      <div className="landuse_Report_map_contain">
        <div className="landuse_Report_scalecontainer">
          <FormInput
            value={parcelScale}
            className="landuse_Report_scale"
            placeholder="المقياس"
            icon={isLoadingparcel ? "spinner" : "ruler"}
            onChange={(e) => onScalechange(e, "parcel")}
          />
        </div>
        <div className="landuse_Report_map">
          <Map ref={parcelMap} isEmpty={true} />
        </div>
      </div>
      <div className="landuse_Report_map_contain">
        <div className="landuse_Report_scalecontainer">
          <FormInput
            value={landuseScale}
            className="landuse_Report_scale"
            placeholder="المقياس"
            icon={isLoadinglanduse ? "spinner" : "ruler"}
            onChange={(e) => onScalechange(e, "landuse")}
          />
        </div>
        <div className="landuse_Report_map">
          <Map ref={landuseMap} isEmpty={true} />
        </div>
      </div>
    </div>
  );
}

ReportMaps.propTypes = {
  dlskey: PropTypes.string,
};
