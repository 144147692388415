import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { users } from "../../../redux/actions/action-creator";
import "./nav-user-badge.css";

import defaultImage from "../../../assets/images/default-user-image.png";
import { resources } from "../../../assets/LocalizationResources";

export default function NavUserBadge() {
  const { Name, authenticated } = useSelector(state => state.users);
  const dispatch = useDispatch();
  const router = useHistory();

  const [popupVisible, setPopupVisible] = useState(false);
  const showUserPopup = () => {
    setPopupVisible(!popupVisible);
  };

  const handleLoginState = () => {
    setPopupVisible(!popupVisible);
    if (authenticated) {
      dispatch(users.logoutRquest());
    } else {
      router.push("/Login");
    }
  };

  return (
    <div className="badge-container" onClick={showUserPopup}>
      <div className="user-img-container">
        <img src={defaultImage} alt="" className="user-img" />
        <FontAwesomeIcon icon={popupVisible ? "angle-up" : "angle-down"} className="user-profile-arrow" />
      </div>
      <div className="user-name-container">
        <p className="user-name-label user-badge-label">{Name}</p>
      </div>

      {popupVisible && (
        <div className={`nav-user-badge-popup fadein`}>
          {authenticated && (
            <>
              <img src={defaultImage} alt="" className="user-img mb-2" />
              <p className="mb-2">{Name}</p>
            </>
          )}
          <div className="nav-user-badge-popup-buttons-container">
            <Button color="primary" onClick={handleLoginState}>
              {authenticated ? resources.misc.logout : resources.misc.login}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
