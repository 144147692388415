import store from "../redux/store/store";

const Perm = {
  AddUser: 1,
  EditUser: 2,
  DeleteUser: 3,
  SearchLayers: 4,
  PrintLanduseReport: 5,
  AddLayer: 6,
  EditLayer: 7,
  DeleteLayer: 8,
  AssignLayerUsers: 9,
  ChangePassword: 10,
  ViewUsers: 11,
  ViewLayers: 12,
  AddGroup: 13,
  EditGroup: 14,
  DeleteGroup: 15,
  ViewGroups: 13,
  Settings: 17,
  ShowGpxVideo: 22,
  EditUserVillagesPermission: 23,
};
/**
 * @param {Number[]} IDs
 */
export function isAuthorized(...IDs) {
  const { permissions } = store.getState().users;
  for (var i = 0; i < IDs.length; i++)
    if (permissions.indexOf(IDs[i]) > -1) return true;
  return false;
}

export default Perm;
