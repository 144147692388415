import Axios from "axios";
import constants from "../helpers/Constants";
import qs from "querystring";

const LocalCache = {};
const ttl = 0; //1000 * 60 * 10; // x minutes request caching

// Axios.interceptors.request.use(
//     async config => {
//         config.headers["Authorization"] = "Basic " + btoa(constants.geoserverAuth);
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );

function getRequestWithCache(url) {
  if (
    LocalCache[url] &&
    isSuccessfulRequest(LocalCache[url].status) &&
    LocalCache[url].time > new Date().getTime()
  ) {
    return LocalCache[url].promise;
  }

  const request = Axios.get(url);
  LocalCache[url] = {
    promise: request,
    time: new Date().getTime() + ttl
  };
  return request;
}

function isSuccessfulRequest(status) {
  // if the status = 400 or more (Failed request) the subtraction will be positive
  return !!status && status - 400 < 0;
}

/////////// Service end-points ///////////

export const GeoServerApi = {
  SomePost: model => {
    return Axios.post("api/Controller/end-point", model);
  },
  SomeUpdate: model => {
    return Axios.put("api/Controller/end-point", model);
  },
  getLayer: ({
    layer,
    max = 50,
    fields,
    filter = {},
    cqlFilter,
    startIndex = 0,
    viewparams = null
  }) => {
    const options = {
      service: "WFS",
      version: "1.1.0",
      request: "GetFeature",
      typeName: `${constants.geoserverWorkspace}:${layer}` || "null",
      maxFeatures: max,
      outputFormat: "application/json",
      format_options: "CHARSET:UTF-8",
      propertyName: fields
        .replace(/\s/g, "")
        .split(",")
        .reduce((p, c) => `${p},${c}`, "")
        .substr(1)
    };

    if (!cqlFilter) {
      for (const key in filter) if (!filter[key]) delete filter[key];
      // TODO: Handle multi-filter option with p
      if (Object.keys(filter).length)
        options.CQL_FILTER = Object.keys(filter).reduce(
          (p, key) => `${key} LIKE '%${filter[key]}%'`,
          ""
        );
    } else {
      options.CQL_FILTER = cqlFilter;
    }

    if (viewparams) options.viewparams = viewparams;
    if (startIndex) options.startIndex = startIndex;

    return getRequestWithCache(
      `http://${constants.geoServerPath}/${
        constants.geoserverWorkspace
      }/ows?${qs.stringify(options)}`
    );
  }
};
