import React, { useState } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resources } from "../../../../assets/LocalizationResources";
import "./MapNavBar.css";

export default function MapNavBar({ onOptionSelected, mapMenuOptions }) {
  // TODO: Set the selected option inside the store as it's being used by [MapNavBar/index.js]
  const [selectedOption, setSelectedOption] = useState("");
  const onOptSelected = option => {
    setSelectedOption(option);
    if (onOptionSelected) onOptionSelected(option);
  };

  const createButton = (option, icon) => {
    return (
      <Button
        key={option}
        outline
        color="info"
        active={selectedOption === option}
        onClick={() => onOptSelected(option)}
      >
        <FontAwesomeIcon icon={icon} style={{ margin: "0 5px" }} />
        <label style={{ cursor: "pointer" }}>{resources.mapMenu[option]}</label>
      </Button>
    );
  };

  return (
    <div className="map-bavbar">
      {Object.keys(mapMenuOptions).map(option => createButton(option, mapMenuOptions[option].icon))}
    </div>
  );
}
