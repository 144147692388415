import React from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import IrbidReportMaps from "./IrbidReportMaps";
import IrbidReportHeader from "./IrbidReportHeader";
import IrbidReportDetails from "./IrbidReportDetails";
import IrbidReportFooter from "./IrbidReportFooter";
import IrbidRulesData from "./IrbidRulesData";
import "./IrbidReport.scss";

export default function IrbidLandUseReport() {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (query.type == "A3") {
    document.body.style.width = "29.7cm";
    document.body.style.height = "42cm";
  } else {
    document.body.style.width = "21cm";
    document.body.style.height = "29.7cm";
  }
  document.body.style.float = "right";
  return (
    <div className="rtl rtl-text">
      <div
        className={
          query.type == "A3" ? "landuse-report-A3" : "landuse-report-A4"
        }
      >
        <div className="landuse-report-irbid">
          <table>
            <thead>
              <tr>
                <th>
                  <IrbidReportHeader
                    districtName={query.vn}
                    dlsKey={query.dls}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <IrbidReportDetails
                    villageName={query.vn}
                    dlsKey={query.dls}
                    hodName={query.hn}
                    withApartment={query.apart}
                  />
                  <IrbidRulesData dlskey={query.dls} />
                  <IrbidReportMaps
                    dlskey={query.dls}
                    withApartment={query.apart}
                  />
                  <IrbidReportFooter />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
