import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

export default function Modal({ header, content, footer, onDismiss, dismissLabel, ButtonColor }) {
  return (
    <div style={styles.wrapper}>
      <div style={styles.bg} onClick={onDismiss} />
      <div style={styles.modalWrapper}>
        <div style={styles.header}>{header}</div>
        <div style={styles.content}>{content}</div>
        <div style={styles.footer}>
          {footer}
          <Button onClick={onDismiss} color={"dark"}>
            {dismissLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  dismissLabel: "some"
};

Modal.prototype = {
  header: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
  onDismiss: PropTypes.func,
  dismissLabel: PropTypes.string,
  ButtonColor: PropTypes.string
};

const styles = {
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 999,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  },
  bg: {
    backgroundColor: "black",
    opacity: 0.5,
    position: "absolute",
    zIndex: -1,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0
  },
  modalWrapper: {
    maxWidth: 650,
    minWidth: 350,
    zIndex: 1
  },
  header: {
    padding: 15,
    borderBottom: "1px solid lightgray",
    backgroundColor: "white",
    borderRadius: "8px 8px 0 0"
  },
  content: {
    padding: "10px 20px",
    minHeight: 70,
    backgroundColor: "white"
  },
  footer: {
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid lightgray",
    backgroundColor: "white",
    borderRadius: "0 0 8px 8px"
  }
};
