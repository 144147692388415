import React from "react";
import "./compass.css";
import compassImage from "../../../assets/images/compass-image2.png";
import compassNeedle from "../../../assets/images/needle.png";

export default function Compass({ direction }) {
  return (
    <div className="compass-container">
      <img src={compassImage} alt="" className="compass-img" />
      <div className="compass-angle">{direction}°</div>
      {/* <div
        className="compass-needle-container"
        style={{ transform: `rotate(${direction}deg)` }}
      >
        <span className="compass-needle"></span>
      </div> */}
      <div className="compass-needle-container">
        <img
          src={compassNeedle}
          alt=""
          className="compass-needle-img"
          style={{ transform: `rotate(${direction}deg)` }}
        />
      </div>
    </div>
  );
}
