import React, { useState } from "react";
import NavMenu from "./NavMenu/NavMenu";
import { NavLink } from "react-router-dom";
import "./Layout.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resources } from "../../assets/LocalizationResources";
import Perm, { isAuthorized } from "../../helpers/Permissions";
import { useSelector } from "react-redux";
export default function Layout({ children }) {
  const [sideMenuIsOpen, setsideMenuIsOpen] = useState(false);

  const toggleSideMenuState = () => {
    setsideMenuIsOpen(!sideMenuIsOpen);
  };

  const expandedSideList = () => {
    return (
      <div className="vertical-flex-separation">
        <div className="side-menu-ul">
          {isAuthorized(Perm.SearchLayers) && (
            <NavLink to="/map-search" activeClassName="active-side-link">
              <div className="side-nav-li">
                <FontAwesomeIcon icon="search" className="side-nav-li-icon" />
                <p className="side-menu-label">{resources.mapSearch}</p>
              </div>
            </NavLink>
          )}
          {isAuthorized(Perm.SearchLayers) && (
            <NavLink to="/search-analyze" activeClassName="active-side-link">
              <div className="side-nav-li">
                <FontAwesomeIcon
                  icon="chart-pie"
                  className="side-nav-li-icon"
                />
                <p className="side-menu-label">{resources.analyzeData}</p>
              </div>
            </NavLink>
          )}
          {isAuthorized(Perm.PrintLanduseReport) && (
            <NavLink to="/PrintLandUse" activeClassName="active-side-link">
              <div className="side-nav-li">
                <FontAwesomeIcon
                  icon="layer-group"
                  className="side-nav-li-icon"
                />
                <p className="side-menu-label">{resources.LandUse.LandUse}</p>
              </div>
            </NavLink>
          )}
          
            <NavLink to="/StreetDriveVideo" activeClassName="active-side-link">
              <div className="side-nav-li">
                <FontAwesomeIcon icon="video" className="side-nav-li-icon" />
                <p className="side-menu-label">
                  {resources.gpxStreetDrivePlayer.StreetDrivePlayer}
                </p>
              </div>
            </NavLink>
         
          {isAuthorized(Perm.Settings) && (
            <NavLink to="/settings" activeClassName="active-side-link">
              <div className="side-nav-li">
                <FontAwesomeIcon icon="cogs" className="side-nav-li-icon" />
                <p className="side-menu-label">{resources.settings}</p>
              </div>
            </NavLink>
          )}
        </div>
        <p className="copyright-label">{resources.copyrights}</p>
      </div>
    );
  };

  const iconsSideList = () => {
    return (
      <div className="side-menu-ul closed">
        {isAuthorized(Perm.SearchLayers) && (
          <NavLink to="/map-search" activeClassName="active-side-link-icon">
            <div className="side-nav-li closed">
              <FontAwesomeIcon icon="search" className="side-nav-li-icon" />
            </div>
          </NavLink>
        )}
        {isAuthorized(Perm.SearchLayers) && (
          <NavLink to="/search-analyze" activeClassName="active-side-link-icon">
            <div className="side-nav-li closed">
              <FontAwesomeIcon icon="chart-pie" className="side-nav-li-icon" />
            </div>
          </NavLink>
        )}
        {isAuthorized(Perm.PrintLanduseReport) && (
          <NavLink to="/PrintLandUse" activeClassName="active-side-link-icon">
            <div className="side-nav-li closed">
              <FontAwesomeIcon
                icon="layer-group"
                className="side-nav-li-icon"
              />
            </div>
          </NavLink>
        )}
        {/* {isAuthorized(Perm.ShowGpxVideo) && (
          <NavLink
            to="/StreetDriveVideo"
            activeClassName="active-side-link-icon"
          >
            <div className="side-nav-li closed">
              <FontAwesomeIcon icon="video" className="side-nav-li-icon" />
            </div>
          </NavLink>
        )} */}
        {isAuthorized(Perm.Settings) && (
          <NavLink to="/settings" activeClassName="active-side-link-icon">
            <div className="side-nav-li closed">
              <FontAwesomeIcon icon="cogs" className="side-nav-li-icon" />
            </div>
          </NavLink>
        )}
      </div>
    );
  };

  return (
    <div>
      <NavMenu onBurgerClicked={toggleSideMenuState} />
      <div className="site-body">
        <div
          className={
            "site-content rtl rtl-text " +
            (sideMenuIsOpen ? "side-menu-open" : "")
          }
        >
          {children}
        </div>
        <div
          className={
            "side-nav-menu rtl rtl-text " +
            (sideMenuIsOpen ? "side-menu-open" : "")
          }
        >
          {sideMenuIsOpen ? expandedSideList() : iconsSideList()}
        </div>
      </div>
    </div>
  );
}
