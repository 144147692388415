import React from "react";
import { Input, FormGroup, Label, FormText } from "reactstrap";
import { resources } from "../../../../assets/LocalizationResources";
import PropTypes from "prop-types";

/**
 *  @param {{handleInputChanges: function; confirmPasswordError:bool }} data
 */
export default function PasswordForm({ handleInputChanges, confirmPasswordError }) {
  return (
    <>
      <FormGroup>
        <Label>{resources.user.password}</Label>
        <Input
          name="Password"
          type="password"
          pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
          required
          onChange={handleInputChanges}
        />
        <FormText>{resources.user.passwordRegex}</FormText>
      </FormGroup>
      <FormGroup>
        <Label>{resources.user.confirmPassword}</Label>
        <Input
          name="ConfirmPassword"
          pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
          type="password"
          required
          onChange={handleInputChanges}
          invalid={confirmPasswordError}
        />
        {confirmPasswordError && (
          <FormText>
            <label style={{ color: "#dc3545" }}>{resources.user.confirmPasswordError}</label>
          </FormText>
        )}
      </FormGroup>
    </>
  );
}

PasswordForm.propTypes = {
  handleInputChanges: PropTypes.func,
  confirmPasswordError: PropTypes.bool
};
