import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CheckboxTree from "react-checkbox-tree";
import PropTypes from "prop-types";
import "./Tree.css";
/**
 *  @param {{nodes: object; onTreeCheck: function; outchecked:Array }} options
 */
export default function Tree({ nodes, onTreeCheck, outchecked, outexpanded }) {
  const [checked, setchecked] = useState([]);
  const [expanded, setexpanded] = useState([]);

  const onCheck = checked => {
    setchecked(checked);
    onTreeCheck(checked);
  };

  const onExpand = expanded => {
    setexpanded(expanded);
  };
  const renderBox = () => {
    return <span style={{ border: "1px solid var(--color-primary-lighter)", padding: 6, borderRadius: 3 }}></span>;
  };
  const renderangle = angle => {
    return (
      <span style={{ fontSize: 15, color: "var(--color-primary-lighter)" }}>
        <FontAwesomeIcon icon={`angle-${angle}`} />
      </span>
    );
  };

  const icons = {
    check: (
      <span style={{ fontSize: 13, color: " var(--color-highlighted-blue)" }}>
        <FontAwesomeIcon icon="check" />
      </span>
    ),
    uncheck: renderBox(),
    halfCheck: (
      <span style={{ fontSize: 10, color: "var(--color-primary-lighter)", marginTop: "0px" }}>
        <FontAwesomeIcon icon="square-full" />
      </span>
    ),
    expandClose: renderangle("left"),
    expandOpen: renderangle("down"),
    expandAll: renderangle("down"),
    expandClose: renderangle("left"),
    parentClose: <span></span>,
    parentOpen: <span></span>,
    leaf: (
      <span style={{ fontSize: 10, color: "var(--color-primary-lighter)", marginTop: "0px" }}>
        <FontAwesomeIcon icon="key" />
      </span>
    )
  };

  return (
    <div style={styles.tree}>
      <CheckboxTree
        nodes={nodes}
        checked={checked.concat(outchecked)}
        expanded={expanded.concat(outexpanded)}
        onCheck={onCheck}
        onExpand={onExpand}
        icons={icons}
      />
    </div>
  );
}
const styles = {
  tree: {
    border: "1px solid #ced4da",
    height: "min-content",
    maxHeight: "400px",
    borderRaduis: "4px",
    overflow: "auto"
  }
};
Tree.propTypes = {
  onTreeCheck: PropTypes.func,
  outchecked: PropTypes.array,
  outexpanded: PropTypes.array
};
