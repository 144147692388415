import { Input } from "reactstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const getDefaultSelectionColumn = () => {
  return {
    id: "selection",
    Header: ({ getToggleAllRowsSelectedProps }) => (
      <Input type="checkbox" color="primary" {...getToggleAllRowsSelectedProps()} />
    ),
    Cell: ({ row }) => <Input type="checkbox" color="primary" {...row.getToggleRowSelectedProps()} />,
    filterable: false
  };
};

export const getDefaultFilterColumn = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
  return (
    <div style={{ position: "relative" }}>
      <FontAwesomeIcon style={{ position: "absolute", left: 7, bottom: 12 }} icon="search" />
      <Input
        type="text"
        value={filterValue || ""}
        onChange={e => {
          console.log(setFilter);
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </div>
  );
};

export const generateViewableColumns = model => {
  return model.getViewableFields().map(field => {
    return {
      Header: field.title,
      accessor: field.field
    };
  });
};
