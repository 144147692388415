import React from "react";
import BaseMenu from "./BaseSearchMenu";
import { resources } from "../../../../assets/LocalizationResources";
import LayersNames from "../../../../helpers/LayersNames";

export default function LanduseMenu({ map, onFeatureSelected }) {
  return (
    <BaseMenu
      layerName={LayersNames.landusePublic}
      filterField="dls_key"
      idField="id"
      labelField="dls_key"
      featureLabelFields="dls_key,landuse_de"
      searchFieldLabel="أي جزء من ال DLS Key"
      icon="search"
      map={map}
      showNearbyLanduse={true}
      onFeatureSelected={onFeatureSelected}
      layerFields="geometry_spa,id,dls_key,landuse_de,shape_area,plot_no,secondary_landuse_no,village_no,block_no,sector_no,sheet_no"
      infoFields={[
        {
          field: "shape_area",
          label: "المساحة",
          icon: "vector-square",
          postLabel: <span>م&#178;</span>,
          valueExp: (val) => {
            if (!val) return resources.misc.noData;
            else return parseFloat(val).toFixed(3);
          },
        },
        {
          field: "landuse_de",
          label: "الاستعمال",
          icon: "map-marked-alt",
          valueExp: (val) => val || resources.misc.noData,
        },
      ]}
    />
  );
}
