import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Paginator({ currentPage, totalCount, pageSize, onPaging, containerStyle /*, currentCount */ }) {
    const maxPage = Math.ceil(totalCount / pageSize);
    return !totalCount ? null : (
        <div style={{ ...styles.container, ...containerStyle }}>
            <FontAwesomeIcon
                icon="angle-right"
                style={{ ...styles.arrow, ...(currentPage >= maxPage - 1 ? styles.dimmed : {}) }}
                onClick={() => onPaging && onPaging(currentPage < maxPage - 1 ? 1 : 0)}
            />
            <span style={styles.text}>
                ({maxPage} / {currentPage + 1})
            </span>
            <FontAwesomeIcon
                icon="angle-left"
                style={{ ...styles.arrow, ...(currentPage <= 0 ? styles.dimmed : {}) }}
                onClick={() => onPaging && onPaging(currentPage > 0 ? -1 : 0)}
            />
        </div>
    );
}

Paginator.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired
    // currentCount: PropTypes.number.isRequired
};

const styles = {
    container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    arrow: {
        fontSize: 34,
        cursor: "pointer",
        userSelect: "none"
    },
    text: {
        fontSize: 18,
        fontWeight: "bold",
        userSelect: "none"
    },
    dimmed: {
        color: "lightgray",
        cursor: "auto"
    }
};
