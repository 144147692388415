import React from "react";
import PropTypes from "prop-types";
import { ListGroup, ListGroupItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { resources } from "../../assets/LocalizationResources";

export default function LandmarkSearchResultsList({ items, clickable, onItemClick, listContainerStyle = {} }) {
    // TODO :: Assumption items = [{ label: string, id: integer }];

    let options = {};
    if (clickable) options = { ...options, action: true, tag: "button" };

    const onItemClickLocal = item => {
        if (typeof onItemClick === "function") onItemClick(item);
    };

    return (
        <ListGroup style={{ ...styles.list, ...listContainerStyle }}>
            {items && items.length
                ? items.map(item => (
                      <ListGroupItem key={item.myCustomeID} {...options} onClick={() => onItemClickLocal(item)}>
                          <FontAwesomeIcon icon="map-marker-alt" style={styles.icon} />
                          <b>{item.label || resources.misc.noNameNum}</b>
                          {item.info && item.info.length
                              ? item.info.map(infoItem => (
                                    <div key={infoItem.field} style={styles.infoDiv}>
                                        <FontAwesomeIcon style={styles.icon} icon={infoItem.icon} />
                                        <b>{infoItem.label}</b>:
                                        <label style={styles.infoValue}>{infoItem.valueExp(infoItem.value)}</label>
                                        {infoItem.postLabel}
                                    </div>
                                ))
                              : null}
                      </ListGroupItem>
                  ))
                : null}
        </ListGroup>
    );
}

const styles = {
    list: {
        paddingRight: 0,
        marginTop: 10,
        marginBottom: 10,
        overflowY: "auto"
    },
    icon: {
        marginLeft: 10
    },
    infoDiv: {
        marginTop: 5
    },
    infoValue: {
        margin: "0 0 0 5px"
    }
};

LandmarkSearchResultsList.propTypes = {
    items: PropTypes.array.isRequired,
    clickable: PropTypes.bool,
    onItemClick: PropTypes.func,
    listContainerStyle: PropTypes.object
};
