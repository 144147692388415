// @ts-nocheck
import { takeLatest, put } from "redux-saga/effects";
import { usersActions } from "../actions";
import { users } from "../actions/action-creator";
import authApi, { isSuccessfulRequest } from "../../api/authApi";
import { loginSchema } from "../../models/user.models";
import validator from "../../helpers/SchemaValidator";

/**
 * @param {{ payload: any; }} action
 */
function* login(action) {
	const validationResult = validator(action.payload, loginSchema);
	if (validationResult.valid) {
		try {
			const response = yield authApi.lgoin(action.payload);
			if (isSuccessfulRequest(response) && response.data && response.data.ID) {
				yield put(users.login(response.data));
			} else {
				yield put(users.loginFailed());
			}
		} catch (error) {
			console.log("error at login", error);
			yield put(users.loginConnectionError(error));
		}
	} else {
		yield put(users.loginFailed());
	}
}

export function* loginRequested() {
	yield takeLatest(usersActions.LOGIN_REQUEST_START, login);
}
