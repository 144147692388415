import React, { useState, useRef, useEffect } from "react";
import Map from "../../Map/Map";
import "../../Map/map.css";
import { resources } from "../../../assets/LocalizationResources";
import LandUsefilters from "./LandUsefilters";
import { Button, Input, FormGroup, Label } from "reactstrap";
import { GeoServerApi } from "../../../api/geoserverController";
import {
  addGeoJsonFeaturesToSearchLayer,
  zoomToFeatureInSearchLayer,
  clearSearchLayerSource,
  highlitedOrZoomMultiFeaturesInSearchLayer,
} from "../../../helpers/MapHelper";
import InitMeasurementTools from "../../../helpers/MapMeasurements";
import LayersNames from "../../../helpers/LayersNames";
import { useSelector } from "react-redux";
import settingsApi, { isSuccessfulRequest } from "../../../api/settingsApi";
import Constants from "../../../helpers/Constants";

export default function PrintLandUse() {
  const [dlskey, setDlskey] = useState(null);
  const [villageName, setVillageName] = useState(null);
  const [hodeName, setHodeName] = useState(null);
  const [secondaryLanduseNo, setSecondaryLanduseNo] = useState(null);
  const [cfeatures, setCfeatures] = useState(null);
  const [gfeatures, setGfeatures] = useState(null);
  const [mfeatures, setMfeatures] = useState(null);
  const [vfeatures, setVfeatures] = useState(null);
  const [bfeatures, setBfeatures] = useState(null);
  const [sfeatures, setSFeatures] = useState(null);
  const [pfeatures, setPfeatures] = useState(null);
  const [info, setInfo] = useState(null);
  const [withApartment, setWithApartment] = useState(false);
  const mapRef = useRef(null);
  const [userVillages, setUserVillages] = useState("");
  const { ID } = useSelector((s) => s.users);

  useEffect(() => {
    getUserVillages();
    InitMeasurementTools(mapRef.current.map);
    return () => {
      clearSearchLayerSource(mapRef.current.map);
    };
  }, []);

  const getUserVillages = async () => {
    if (ID) {
      const response = await settingsApi.getVillageLayerPermission({
        userID: ID,
      });
      if (
        isSuccessfulRequest(response) &&
        response &&
        response.data &&
        response.data.length
      )
        setUserVillages(response.data[0].VillagesIDs);
    }
  };
  const drawAllfeatures = () => {
    clearSearchLayerSource(mapRef.current.map);
    if (cfeatures) drawGeoJson(cfeatures);
    if (gfeatures) drawGeoJson(gfeatures);
    if (mfeatures) drawGeoJson(mfeatures);
    if (vfeatures) drawGeoJson(vfeatures);
    if (bfeatures) drawGeoJson(bfeatures);
    if (sfeatures) drawGeoJson(sfeatures);
    if (pfeatures) drawGeoJson(pfeatures);
    if (info) {
      if (
        info.type === "sector" ||
        info.type === "parcel" ||
        info.type === "sheet"
      ) {
        highlitedOrZoomMultiFeaturesInSearchLayer({
          textField: info.labelField,
          idField: info.idField,
          map: mapRef.current.map,
          id: info.type === "parcel" ? info.id : null,
        });
      } else {
        zoomToFeatureInSearchLayer({
          textField: info.labelField,
          idField: info.idField,
          id: info.id,
          map: mapRef.current.map,
        });
      }
    }
  };

  useEffect(() => {
    drawAllfeatures();
  }, [
    cfeatures,
    gfeatures,
    mfeatures,
    vfeatures,
    bfeatures,
    sfeatures,
    pfeatures,
  ]);

  const onPrintClick = (type) => {
    if (dlskey)
      window.open(
        `./LanduseReport?dls=${dlskey}&vn=${villageName}&hn=${hodeName}&sn=${secondaryLanduseNo}&type=${type}&apart=${withApartment}`
      );
  };

  const setChangedFeatures = (type, searchResults) => {
    let features = !searchResults.features.length ? null : searchResults;
    switch (type) {
      case "country":
        setCfeatures(features);
        setGfeatures(null);
        setMfeatures(null);
        setVfeatures(null);
        setBfeatures(null);
        setSFeatures(null);
        setPfeatures(null);
        break;
      case "governorate":
        setGfeatures(features);
        setMfeatures(null);
        setVfeatures(null);
        setBfeatures(null);
        setSFeatures(null);
        setPfeatures(null);
        break;
      case "municipality":
        setMfeatures(features);
        setVfeatures(null);
        setBfeatures(null);
        setSFeatures(null);
        setPfeatures(null);
        break;
      case "village":
        setVfeatures(features);
        setBfeatures(null);
        setSFeatures(null);
        setPfeatures(null);
        setVillageName(
          features ? features.features[0].properties["vill_aname"] : null
        );
        break;
      case "basin":
        setBfeatures(features);
        setSFeatures(null);
        setPfeatures(null);
        setHodeName(
          features ? features.features[0].properties["hod_aname"] : null
        );
        break;
      case "sheet":
        setSFeatures(features);
        setPfeatures(null);
      case "sector":
        setSFeatures(features);
        setPfeatures(null);
        break;
      case "parcel":
        setPfeatures(features);
        setSecondaryLanduseNo(
          features
            ? features.features[0].properties["secondary_landuse_no"]
            : null
        );
        break;
      default:
        return;
    }
  };

  const drawGeoJson = (features) => {
    addGeoJsonFeaturesToSearchLayer({
      data: features,
      map: mapRef.current.map,
      clearSource: false,
    });
  };

  const getSelectedFilterProperties = (info) => {
    switch (info.type) {
      case "country":
        return {
          layer: LayersNames.country,
          max: "1",
          fields: "geometry_spa,id,country_name",
          cqlFilter: `id = ${info.id}`,
        };
      case "governorate":
        return {
          layer: LayersNames.governorate,
          max: "1",
          fields: "geometry_spa,id,gov_a",
          cqlFilter: `id = ${info.id}`,
        };
      case "municipality":
        return {
          layer: LayersNames.municipality,
          max: "1",
          fields: "geometry_spa,id,munic_name",
          cqlFilter: `id = ${info.id}`,
        };
      case "village":
        return {
          layer: LayersNames.villages,
          max: "1",
          fields: "geometry_spa,id,vill_aname",
          cqlFilter: `id = ${info.id}`,
        };
      case "basin":
        return {
          layer: LayersNames.basins,
          max: "1",
          fields: "geometry_spa,id,hod_aname,hod_code",
          cqlFilter: `id = ${info.id} `,
        };
      case "sheet":
        return {
          layer: LayersNames.landUse,
          fields: "geometry_spa,dls_key,plot_no",
          cqlFilter: `sheet_no = ${info.id} AND block_no = ${info.basinID} AND village_no = ${info.villageID}`,
        };
      case "sector":
        return {
          layer: LayersNames.landUse,
          fields: "geometry_spa,dls_key,plot_no",
          cqlFilter: `sector_no = ${info.id} AND block_no = ${info.basinID} AND village_no = ${info.villageID}`,
        };
      case "parcel":
        return {
          layer: LayersNames.landUse,
          // max: "1",
          fields: "geometry_spa,dls_key,plot_no,secondary_landuse_no",
          cqlFilter: `dls_key = '${info.id}'`,
        };
      default:
        return;
    }
  };

  const drawFilter = async (info) => {
    setDlskey(info.type === "parcel" ? info.id : dlskey);
    setInfo(info);
    const response = await GeoServerApi.getLayer(
      getSelectedFilterProperties(info)
    ).catch((e) => {
      console.log(e);
    });

    if (response && response.data && response.data.features) {
      setChangedFeatures(info.type, response.data);
    }
  };

  const clearFilterFeatures = (type) => {
    switch (type) {
      case "country":
        setCfeatures(null);
        setVfeatures(null);
        setBfeatures(null);
        setSFeatures(null);
        setPfeatures(null);
        break;
      case "village":
        setVfeatures(null);
        setBfeatures(null);
        setSFeatures(null);
        setPfeatures(null);
        break;
      case "basin":
        setBfeatures(null);
        setSFeatures(null);
        setPfeatures(null);
      case "sheet":
        setSFeatures(null);
        setPfeatures(null);
      case "sector":
        setSFeatures(null);
        setPfeatures(null);
        break;
      case "parcel":
        setPfeatures(null);
        break;
      default:
        return;
    }
  };

  return (
    <div className="map-page-container">
      <div style={styles.Header}>
        <h5>{resources.LandUse.printLandUse}</h5>
      </div>
      <div style={styles.container}>
        <div style={styles.Searchbox}>
          {userVillages ? (
            <>
              <LandUsefilters
                userVillages={userVillages}
                drawFilter={drawFilter}
                clearFilterFeatures={clearFilterFeatures}
              />
              <Button
                color="primary"
                style={styles.printBtn}
                onClick={() => onPrintClick("A4")}
                disabled={!dlskey}
              >
                A4 طباعة
              </Button>
              <Button
                color="primary"
                style={styles.printBtn}
                onClick={() => onPrintClick("A3")}
                disabled={!dlskey}
              >
                A3 طباعة
              </Button>
              {Constants.geoserverWorkspace == "moma_irbid_postgis" && (
                <FormGroup check inline style={{ marginTop: 10 }}>
                  <Label check>
                    <Input
                      type="checkbox"
                      color="primary"
                      onChange={(e) => setWithApartment(!withApartment)}
                    />
                    مخطط شقق
                  </Label>
                </FormGroup>
              )}
            </>
          ) : (
            <div> لا يوجد صلاحيات على أي بلدية أو منطقة أو قرية </div>
          )}
        </div>

        <Map ref={mapRef} />
      </div>
    </div>
  );
}

const styles = {
  Header: {
    padding: "5px 10px",
    boxShadow: "0 0.25rem 0.75rem rgba(0, 0, 0, 0.075)",
    borderBottom: "1px solid var(--color-gray-border)",
  },
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row-reverse",
  },
  Searchbox: {
    width: "300px",
    backgroundColor: "var(--color-light-gray-bg)",
    borderRight: "1px solid var(--color-gray-border)",
    boxShadow: "0.5rem 0.3rem 0.5rem rgba(0, 0, 0, 0.05)",
    overflowY: "auto",
    overflowX: "hidden",
    padding: 10,
  },
  printBtn: {
    marginRight: 4,
    marginTop: "10px",
    height: "max-content",
    fontSize: 14,
  },
};
