import React from "react";

export default function ReportFooter() {
  const getTablefooter = () => {
    return (
      <div style={styles.container}>
        <table style={styles.table}>
          <tbody>
            {getRowData({ label: "رقم المبنى(القسیمة)" }, { label: "تدقیق ق التنظیم" }, { label: "طباعة" })}
            {getRowData({ label: "اسم الحي" }, { label: "تدقیق رئیس القسم الھندسي" }, { label: "الرسام" })}
          </tbody>
        </table>
      </div>
    );
  };

  const getRowData = (td1, td2, td3) => {
    return (
      <tr>
        {gettd(td1)}
        {gettd(td2)}
        {gettd(td3)}
      </tr>
    );
  };

  const gettd = td => {
    return (
      <>
        <td style={styles.td}>
          <label>{td.label}</label>
        </td>
        <td style={styles.td}>{td.field ? <label>{td.field}</label> : <div style={styles.box}></div>}</td>
      </>
    );
  };

  const GetfooterNotes = () => {
    return (
      <div style={styles.container}>
        <label style={styles.label}>• مخطط الموقع صالح لمدة سنة واحد من تاريخ صدوره </label>
        <label style={styles.label}>
          • يعتبر ھذا المخطط لاغیا إذا صدرت علیه اية معلومات او إضافات او كشوطات خلافاً لاي مخطط تنظیمي مقرر
        </label>
      </div>
    );
  };
  return (
    <>
      {getTablefooter()}
      {GetfooterNotes()}
    </>
  );
}

const styles = {
  container: {
    margin: "20px 5px 0px 5px",
    border: "1px solid black",
    fontSize: "9px",
    fontWeight: "bold",
    display: "inline-block",
    width: "738px"
  },
  label: {
    display: "block",
    padding: 5
  },
  table: {
    margin: 5,
    width: "738px"
  },
  box: {
    border: "1px solid black ",
    height: 20,
    width: 120
  }
};
