import React from "react";
import BaseMenu from "./BaseSearchMenu";
import { resources } from "../../../../assets/LocalizationResources";
import LayersNames from "../../../../helpers/LayersNames";

export default function RoadsMenu({ map, onFeatureSelected }) {
  return (
    <BaseMenu
      layerName={LayersNames.street}
      filterField="name_a"
      idField="id"
      labelField="name_a"
      searchFieldLabel="اسم الشارع"
      icon="search"
      map={map}
      onFeatureSelected={onFeatureSelected}
      layerFields="geometry_spa,id,name_a,district_n,street_len,hod_aname,street_wid,hod_code"
      infoFields={[
        {
          field: "street_len",
          label: "طول الشارع",
          icon: "vector-square",
          postLabel: "م",
          valueExp: (val) => {
            if (!val) return resources.misc.noData;
            else return parseFloat(val).toFixed(3);
          },
        },
        {
          field: "hod_aname",
          label: "الحوض",
          icon: "directions",
          valueExp: (val) => val || resources.misc.noData,
        },
        {
          field: "district_n",
          label: "المنطقة",
          icon: "map-signs",
          valueExp: (val) => val || resources.misc.noData,
        },
      ]}
    />
  );
}
