import React, { useState, useRef, useEffect } from "react";
import DropDownShift from "../../../ui-components/DropDownShift/DropDownShift";
import "./analyzer-filters.css";
import { resources } from "../../../../assets/LocalizationResources";
import { fillFilter, DynamicFiltersMenu, getAnalyzeData } from "./FilterHelpers";
import { searchAndAnalyzeLayer } from "../../../../helpers/MapHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PieChart from "react-minimal-pie-chart";

export default function AnalyzeFilters({ onAnalyzeDataReady = [] }) {
  const [villageID, setVillageID] = useState(0);
  const [basinID, setBasinID] = useState(0);
  const basinDllRef = useRef(null);
  const [selectedLanduse, setSelectedLanduse] = useState([]);
  const [analyzeData, setAnalyzeData] = useState([]);

  useEffect(() => {
    searchAndAnalyzeLayer.setVisible(true);
    return () => {
      searchAndAnalyzeLayer.setVisible(false);
    };
  }, []);

  useEffect(() => {
    if (!Boolean(villageID) && !Boolean(basinID) && !Boolean(selectedLanduse.length)) {
      searchAndAnalyzeLayer.setOpacity(0);
      searchAndAnalyzeLayer.getSource().updateParams({
        CQL_FILTER: ""
      });
      searchAndAnalyzeLayer.getSource().refresh();
      // onAnalyzeDataReady([]);
      setAnalyzeData([]);
      return;
    }

    searchAndAnalyzeLayer.setOpacity(1);

    let filter = "";
    let landuseFilter = selectedLanduse.reduce(
      (p, c, i) => p + ` landuse_de='${c}' ` + (i !== selectedLanduse.length - 1 ? " or " : ""),
      ""
    );

    if (villageID) filter += `village_no = ${villageID} `;
    if (basinID) filter += `${filter ? " AND " : ""} block_no = ${basinID} `;
    if (selectedLanduse.length) filter += (filter ? " AND " : "") + "(" + landuseFilter + ")";

    searchAndAnalyzeLayer.getSource().updateParams({
      CQL_FILTER: filter
    });

    getAnalyzeData(landuseFilter, `village_no:${villageID};block_no:${basinID}`).then(
      data => {
        // onAnalyzeDataReady(data);
        setAnalyzeData(data);
      },
      e => console.log("error", e)
    );

    return () => {
      searchAndAnalyzeLayer.setOpacity(0);
      searchAndAnalyzeLayer.getSource().updateParams({
        CQL_FILTER: ""
      });
    };
  }, [villageID, basinID, selectedLanduse]);

  const onFilterChange = (type, e) => {
    switch (type) {
      case "village":
        setVillageID(e.id);
        basinDllRef.current.clearSelectionOutside();
        break;
      case "basin":
        setBasinID(e.id);
        break;
    }
  };

  const onClearSelection = type => {
    switch (type) {
      case "village":
        setVillageID(0);
        break;
      case "basin":
        setBasinID(0);
        break;
    }
  };

  const clearFilterFeatures = type => {};

  const onSelectLanduse = landuse => {
    setSelectedLanduse([...selectedLanduse, landuse]);
  };

  const removeSelectedLanduse = item => {
    const newlanduses = [...selectedLanduse];
    newlanduses.splice(selectedLanduse.indexOf(item), 1);
    setSelectedLanduse(newlanduses);
  };

  return (
    <div className="analyze-filters-container">
      <DropDownShift
        searchLabel={resources.LandUse.Village}
        onSelectedChange={e => onFilterChange("village", e)}
        fetchData={village => fillFilter("village", null, village)}
        searchPlaceHolder={resources.LandUse.Village}
        textField="label"
        clearSelection={() => onClearSelection("village")}
        onclickClear={() => clearFilterFeatures("village")}
      />
      <DropDownShift
        ref={basinDllRef}
        searchLabel={resources.LandUse.Basin}
        onSelectedChange={e => onFilterChange("basin", e)}
        fetchData={basin => fillFilter("basin", basin, villageID)}
        searchPlaceHolder={resources.LandUse.Basin}
        textField="label"
        clearSelection={() => onClearSelection("basin")}
        onclickClear={() => clearFilterFeatures("basin")}
      />
      <DynamicFiltersMenu selectedLanduse={selectedLanduse} onSelectLanduse={landuses => onSelectLanduse(landuses)} />

      {selectedLanduse.map(item => (
        <div key={item} style={styles.selectedlanduse}>
          {item}
          <FontAwesomeIcon onClick={() => removeSelectedLanduse(item)} style={styles.clearIcon} icon="times" />
        </div>
      ))}

      {Boolean(analyzeData.length) && (
        <div className="analyze-pie-container">
          <PieChart
            data={analyzeData}
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            radius={42}
            startAngle={0}
            viewBoxSize={[100, 100]}
          />
          <ul>
            {analyzeData.map(landuse => {
              const ratio = ((landuse.value / analyzeData.reduce((p, c) => p + c.value, 0)) * 100).toFixed(2);
              return (
                <li key={landuse.title} style={{ color: landuse.color }}>
                  {landuse.value} ({ratio}%) : {landuse.title}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
const styles = {
  selectedlanduse: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "0.375rem 0.75rem",
    fontSize: 15,
    color: "#495057",
    boxSizing: "border-box",
    borderBottom: "1px solid #ced4da",
    borderRadius: "0.25rem",
    transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"
  },
  clearIcon: {
    marginRight: 5,
    color: "crimson",
    cursor: "pointer"
  }
};
