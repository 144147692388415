import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GeoServerApi } from "../../../../api/geoserverController";
import IrbidReportNote from "./IrbidReportNote";
import LayersNames from "../../../../helpers/LayersNames";

export default function IrbidRulesData({ dlskey }) {
  const [data, setdata] = useState([]);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (dlskey) getRulesData();
  }, []);

  const getRulesData = async () => {
    let fronts = [286, 287, 288, 289, 290, 291, 293, 292];
    let fronts2 = [294, 295, 296, 298, 299, 297];
    let backs = [344];
    let sides = [345];

    let frontsValue = "";
    let fronts2Value = "";
    let backsValue = "";
    let sidesValues = "";
    let addedIndex = -1;
    const response = await GeoServerApi.getLayer({
      layer: LayersNames.generalRules,
      fields: "",
      viewparams: "dlskey:" + dlskey,
    }).catch((e) => {
      console.log(e);
    });
    if (response && response.data && response.data.features) {
      if (response.data.features.length) {
        let tempData = [],
          notes = [];
        console.log("response.data");
        console.log(response.data);
        response.data.features.forEach((feature) => {
          notes.push(feature.properties["note"]);
          if (
            fronts.find(
              (f) => f === +feature.properties["secondary_landuse_no"]
            )
          )
            frontsValue += feature.properties["front_offside"];
          else if (
            fronts2.find(
              (f) => f === +feature.properties["secondary_landuse_no"]
            )
          )
            fronts2Value += feature.properties["front_offside"];
          else if (
            backs.find((f) => f === +feature.properties["secondary_landuse_no"])
          )
            backsValue += feature.properties["back_offside"];
          else if (
            sides.find((f) => f === +feature.properties["secondary_landuse_no"])
          )
            sidesValues += feature.properties["back_offside"];
          else {
            if (
              +feature.properties["secondary_landuse_no"] === 18 ||
              +feature.properties["secondary_landuse_no"] === 19 ||
              +feature.properties["secondary_landuse_no"] === 20 ||
              +feature.properties["secondary_landuse_no"] === 22
            )
              addedIndex = tempData.length;
            tempData.push([
              feature.properties["landuse_de"] || "",
              feature.properties["front_offside"] || "",
              feature.properties["side_offside"] || "",
              feature.properties["back_offside"] || "",
              feature.properties["percentage"] || "",
              feature.properties["percentage_floor"] || "",
              feature.properties["floor_count"] || "",
              feature.properties["building_higth"] || "",
              feature.properties["green_area"] || "",
              feature.properties["split_area"] || "",
              feature.properties["min_frontage"] || "",
            ]);
          }
        });

        if (addedIndex != -1) {
          if (frontsValue || fronts2Value)
            tempData[addedIndex][1] =
              (frontsValue ? frontsValue : "") +
              (frontsValue ? " + " : "") +
              (fronts2Value ? fronts2Value : "");
          if (sidesValues) tempData[addedIndex][2] = sidesValues;
          if (backsValue) tempData[addedIndex][3] = backsValue;
          tempData[addedIndex][1] =
            +tempData[addedIndex][1] === 0 ? "صفر" : tempData[addedIndex][1];
          tempData[addedIndex][2] =
            +tempData[addedIndex][2] === 0 ? "صفر" : tempData[addedIndex][2];
          tempData[addedIndex][3] =
            +tempData[addedIndex][3] === 0 ? "صفر" : tempData[addedIndex][3];
        }
        setdata(tempData);
        console.log("tempData");
        console.log(tempData);
        setNotes(notes);
      }
    }
  };

  const columns = [["أمامي"], ["جانبي"], ["خلفي"], ["المساحة"], ["الواجهة"]];

  return (
    <>
      {data && (
        <table className="irbid-report-rules">
          <tbody className="irbid-report-rules">
            <tr>
              <th rowSpan={2}>
                <label className="irbid-report-rules-label">الأحكام</label>
              </th>
              <th colSpan={3}>
                <label className="irbid-report-rules-label">الارتدادات</label>
              </th>
              <th rowSpan={2}>
                <label className="irbid-report-rules-label">
                  النسبة المئوية
                </label>
              </th>
              <th rowSpan={2}>
                <label className="irbid-report-rules-label">
                  النسبة الطابقية %
                </label>
              </th>
              <th rowSpan={2}>
                <label className="irbid-report-rules-label">عدد الأدوار</label>
              </th>
              <th rowSpan={2}>
                <label className="irbid-report-rules-label"> الأرتفاع</label>
              </th>
              <th rowSpan={2}>
                <label className="irbid-report-rules-label">
                  المساحة الخضراء{" "}
                </label>
              </th>
              <th colSpan={2}>
                <label className="irbid-report-rules-label">
                  الحد الأدنى من الإفراز
                </label>
              </th>
            </tr>
            <tr>
              {columns.map((column, idx) => (
                <th key={idx}>
                  <label className="irbid-report-rules-label">{column}</label>
                </th>
              ))}
            </tr>
            {data.map((row, idx) => (
              <tr key={idx}>
                {row.map((data, idx) => (
                  <th key={idx}>
                    <label className="irbid-report-rules-label">
                      {data ? data : "-"}
                    </label>
                  </th>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <IrbidReportNote notes={notes} />
    </>
  );
}

IrbidRulesData.prototypes = {
  dlskey: PropTypes.string,
};
