import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import createSaga from "redux-saga";
import rootReducer from "../reducers/root.reducer";
import rootSaga from "../sagas/root.sagas";

const logger = createLogger();
const sagaMiddleware = createSaga();
const middleWares = [logger, sagaMiddleware];

const store = createStore(rootReducer, applyMiddleware(...middleWares));
sagaMiddleware.run(rootSaga);

export default store;
