import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

/**
 *  @param {{title: string; children: any;}} options
 */
export default function SettingsPageWrapper({ title, children }) {
  const router = useHistory();

  const handleBackClicked = () => {
    router.goBack();
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.Header}>
        <h5>{title}</h5>
        <FontAwesomeIcon
          onClick={handleBackClicked}
          style={styles.backIcon}
          icon="angle-left"
        />
      </div>
      <div style={styles.container}>{children}</div>
    </div>
  );
}

const styles = {
  wrapper: {
    height: "100%",
    backgroundColor: "rgb(238, 238, 238)",
  },
  container: {
    margin: 10,
    padding: 15,
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.15)",
    borderBottom: "1px solid var(--color-gray-border)",
    backgroundColor: "#FFF",
    borderRadius: 4,
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#FFF",
    padding: "5px 10px",
    boxShadow: "0 0.25rem 0.75rem rgba(0, 0, 0, 0.075)",
    borderBottom: "1px solid var(--color-gray-border)",
  },
  backIcon: {
    fontSize: 20,
    color: "#23456e",
    marginLeft: 5,
    cursor: "pointer",
  },
};
