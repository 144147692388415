import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FormInput({
  containerStyle,
  inputStyle,
  labelStyle,
  iconStyle,
  placeholder,
  onChange,
  value,
  label,
  icon,
  notControlled,
  iconLabel,
}) {
  return (
    <FormGroup style={{ ...styles.formGroup, ...containerStyle }}>
      {label ? <Label style={{ ...labelStyle }}>{label}</Label> : null}
      {notControlled ? (
        <Input
          style={{ ...styles.searchInput, ...inputStyle }}
          onChange={onChange}
          placeholder={placeholder || ""}
        />
      ) : (
        <Input
          style={{ ...styles.searchInput, ...inputStyle }}
          value={value}
          onChange={onChange}
          placeholder={placeholder || ""}
        />
      )}
      {icon ? (
        <FontAwesomeIcon
          icon={icon}
          style={{ ...styles.searchIcon, ...iconStyle }}
        />
      ) : null}
      {iconLabel ? (
        <label style={{ ...styles.iconLabel, ...iconStyle }}>{iconLabel}</label>
      ) : null}
    </FormGroup>
  );
}

const styles = {
  formGroup: {
    position: "relative",
  },
  searchIcon: {
    position: "absolute",
    left: 10,
    bottom: 10,
    color: "gray",
  },
  iconLabel: {
    position: "absolute",
    left: 35,
    bottom: 10,
    margin: "0px",
    fontWeight: "bold",
  },
  searchInput: {
    fontSize: 14,
  },
};

FormInput.propTypes = {
  containerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  icon: PropTypes.string,
};
