import React, { useState } from "react";
import "./notification-badge.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function NavMenuNotificationBadge(props) {
  const [shouldNotify, setshouldNotify] = useState(false);
  const [popupIsOpen, setpopupIsOpen] = useState(false);
  const [hover, sethover] = useState(false);

  const NotificationPopup = props => {
    return (
      <div className="notification-popup-container">
        <div className="notification-popup-title">{props.title}</div>
        <div className="notification-popup-body" style={{ color: props.textColor || "var(--color-dark-font)" }}>
          {props.children}
        </div>
      </div>
    );
  };

  const togglePopup = newState => {
    setpopupIsOpen(newState);
  };

  const toggleHover = newState => sethover(newState);

  return (
    <div>
      <div
        className="nav-notification-badge"
        onClick={() => (props.onClick ? props.onClick() : togglePopup(true))}
        style={{
          backgroundColor: hover
            ? props.hoverColor || "var(--color-default-badge-hover-bg)"
            : props.badgeColor || "var(--color-default-badge-bg)"
        }}
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
      >
        {shouldNotify ? <span className="nav-notification-badge-dot" /> : null}
        <FontAwesomeIcon
          icon={props.icon}
          className={"nav-notification-badge-icon " + (shouldNotify ? "pulse" : "")}
          style={{ color: props.iconColor || "var(--color-default-badge-icon)" }}
        />
        {popupIsOpen && props.children ? NotificationPopup(props) : null}
      </div>
      {popupIsOpen && props.children ? <div className="screen-backdrop" onClick={() => togglePopup(false)} /> : null}
    </div>
  );
}
