import React, { useState, useEffect } from "react";
import SettingsPageWrapper from "../SettingsPageWrapper";
import { resources } from "../../../../assets/LocalizationResources";
import { Button, Form, Label } from "reactstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { GeoServerApi } from "../../../../api/geoserverController";
import settingsApi, { isSuccessfulRequest } from "../../../../api/settingsApi";
import { useSelector } from "react-redux";
import LayersNames from "../../../../helpers/LayersNames";

export default function UserLayersPermissions({ location }) {
  const [villageError, setVillageError] = useState(false);
  const [villages, setVillages] = useState([]);
  const [selectedVillages, setSelectedVillages] = useState([]);
  const router = useHistory();
  const { ID } = useSelector((s) => s.users);

  useEffect(() => {
    getVillages();
  }, []);

  const getSelectedVillages = async () => {
    if (location && location.state.ID) {
      const response = await settingsApi.getVillageLayerPermission({
        userID: location.state.ID,
      });
      if (isSuccessfulRequest(response) && response && response.data && response.data.length) {
        const selected = response.data[0].VillagesIDs.split(",");
        setSelectedVillages(villages.filter((f) => selected.find((s) => s == f.value)));
      }
    }
  };

  const getVillages = async () => {
    const response = await GeoServerApi.getLayer({
      layer: LayersNames.villages,
      fields: "vill_code,vill_aname",
    }).catch((e) => {
      console.log(e);
    });
    if (response && response.data && response.data.features && response.data.features.length) {
      setVillages(
        response.data.features.map((feature) => {
          return {
            value: feature.properties["vill_code"],
            label: feature.properties["vill_aname"],
          };
        })
      );
    }
  };

  useEffect(() => {
    if (villages && villages.length > 0) getSelectedVillages();
  }, [villages]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (selectedVillages.length <= 0) {
      setVillageError(true);
      return;
    }
    let options = {
      villagesIDs: selectedVillages.map((g) => g.value).join(","),
      userID: location.state.ID,
      CreatedBy: ID,
    };
    const response = await settingsApi.addVillageLayerPermission(options);
    if (isSuccessfulRequest(response)) router.goBack();
  };

  const onVillageChange = (e) => {
    setVillageError(false);
    if (e) setSelectedVillages(e);
  };

  return (
    <div>
      <SettingsPageWrapper title="تحديد قرى المستخدم">
        <Form onSubmit={handleSubmit} style={styles.form}>
          <Label style={styles.lbl}>
            {resources.user.name} : {location.state.Name}
          </Label>
          <Select
            isMulti
            isSearchable
            placeholder="حدد قرى المستخدم"
            value={selectedVillages}
            onChange={onVillageChange}
            options={villages}
            noOptionsMessage={() => {
              return resources.misc.noData;
            }}
            styles={{
              ...styles.selectStyles,
              control: (provided) => {
                return {
                  ...provided,
                  borderColor: villageError ? "red" : "#ced4da",
                };
              },
            }}
          />
          <Button type="submit" color="primary" style={styles.formBtn}>
            {resources.misc.save}
          </Button>
          <Button type="button" color="danger" onClick={() => router.goBack()} style={styles.formBtn}>
            {resources.misc.cancel}
          </Button>
        </Form>
      </SettingsPageWrapper>
    </div>
  );
}
const styles = {
  form: {
    maxWidth: "400px",
  },
  formBtn: {
    marginTop: 20,
    marginLeft: 10,
  },
  lbl: {
    marginBottom: "30px",
  },
};
