import React from "react";
import BaseMenu from "./BaseSearchMenu";
import { resources } from "../../../../assets/LocalizationResources";
import LayersNames from "../../../../helpers/LayersNames";

export default function LandmarksMenu({ map, onFeatureSelected }) {
  return (
    <BaseMenu
      layerName={LayersNames.landmarkes}
      filterField="poi_name_a"
      idField="id"
      labelField="poi_name_a"
      searchFieldLabel="اسم المعلم"
      icon="search"
      map={map}
      onFeatureSelected={onFeatureSelected}
      layerFields="geometry_spa,id,poi_name_a,poi_type_a,district_n,street_aname,hod_aname,vill_aname,hod_code,vill_code,district_c,parcel_id,sheet_no"
      infoFields={[
        {
          field: "poi_type_a",
          label: "التصنيف",
          icon: "building",
          valueExp: (val) => val || resources.misc.noData,
        },
        {
          field: "vill_aname",
          label: "القرية",
          icon: "map",
          valueExp: (val) => val || resources.misc.noData,
        },
        {
          field: "hod_aname",
          label: "الحوض",
          icon: "directions",
          valueExp: (val) => val || resources.misc.noData,
        },
        {
          field: "district_n",
          label: "المنطقة",
          icon: "map-signs",
          valueExp: (val) => val || resources.misc.noData,
        },
        {
          field: "street_aname",
          label: "الشارع",
          icon: "road",
          valueExp: (val) => val || resources.misc.noData,
        },
      ]}
    />
  );
}
