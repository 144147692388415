import React, { useState, useRef, useEffect } from "react";
import Map from "../../Map/Map";
import {
  clearSearchLayerSource,
  clearNearbyLayerSource,
  zoomToFeatureInSearchLayer,
  addGeoJsonFeaturesToNearbyLayer,
} from "../../../helpers/MapHelper";
import { GeoServerApi } from "../../../api/geoserverController";
import "./map-page.css";
import MapNavBar from "../MapPage/MapNavBar/MapNavBar";
import { resources } from "../../../assets/LocalizationResources";
import RoadsMenu from "../MapPage/MapLayerPanels/RoadsMenu";
import BassinsMenu from "../MapPage/MapLayerPanels/BassinsMenu";
import VillagesMenu from "../MapPage/MapLayerPanels/VillagesMenu";
import ParcelsMenu from "../MapPage/MapLayerPanels/ParcelsMenu";
import LanduseMenu from "../MapPage/MapLayerPanels/LanduseMenu";
import DistrictsMenu from "../MapPage/MapLayerPanels/DistrictsMenu";
import LandmarksMenu from "../MapPage/MapLayerPanels/LandmarksMenu";
import MunPropertyMenu from "../MapPage/MapLayerPanels/MunPropertyMenu";
import PermitsLayer from "../MapPage/MapLayerPanels/PermitsLayer";
import { Transition, animated } from "react-spring/renderprops";
import Lottie from "lottie-web";
import animationDate from "../../../assets/animations/animation-search-marker";
import InitMeasurementTools from "../../../helpers/MapMeasurements";
import Modal from "../../ui-components/Modal";
import { Button, Spinner } from "reactstrap";
import LayersNames from "../../../helpers/LayersNames";
export default function MapPage() {
  const [option, setOption] = useState("");
  const [animation, setAnimation] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [loadingNearby, setloadingNearby] = useState(false);

  const mapRef = useRef(null);

  const playCompassAnimation = () => {
    if (!animation)
      setTimeout(
        () =>
          setAnimation(
            Lottie.loadAnimation({
              container: document.getElementById("animation-container"),
              renderer: "svg",
              loop: true,
              autoplay: true,
              animationData: animationDate,
            })
          ),
        100
      );
  };

  useEffect(() => {
    if (!option) playCompassAnimation();
    InitMeasurementTools(mapRef.current.map);

    // Clean up function
    return () => {
      clearSearchLayerSource(mapRef.current.map);
      clearNearbyLayerSource(mapRef.current.map);
    };
  }, []);

  const onFeatureSelected = (item) => {
    // TODO: on item selected from the menu
  };

  let mapMenuOptions = {};
  if (LayersNames.villages)
    mapMenuOptions = {
      ...mapMenuOptions,
      villages: {
        icon: "map",
        template: (map) => <VillagesMenu map={map} onFeatureSelected={onFeatureSelected} />,
      },
    };
  if (LayersNames.basins)
    mapMenuOptions = {
      ...mapMenuOptions,
      basins: {
        icon: "directions",
        template: (map) => <BassinsMenu map={map} onFeatureSelected={onFeatureSelected} />,
      },
    };
  if (LayersNames.district)
    mapMenuOptions = {
      ...mapMenuOptions,
      districts: {
        icon: "map-signs",
        template: (map) => <DistrictsMenu map={map} onFeatureSelected={onFeatureSelected} />,
      },
    };
  if (LayersNames.parcels)
    mapMenuOptions = {
      ...mapMenuOptions,
      parcels: {
        icon: "map-marked",
        template: (map) => <ParcelsMenu map={map} onFeatureSelected={onFeatureSelected} />,
      },
    };
  if (LayersNames.landusePublic)
    mapMenuOptions = {
      ...mapMenuOptions,
      landuse: {
        icon: "map-marked-alt",
        template: (map) => <LanduseMenu map={map} onFeatureSelected={onFeatureSelected} />,
      },
    };
  if (LayersNames.street)
    mapMenuOptions = {
      ...mapMenuOptions,
      roads: {
        icon: "road",
        template: (map) => <RoadsMenu map={map} onFeatureSelected={onFeatureSelected} />,
      },
    };
  if (LayersNames.landmarkes)
    mapMenuOptions = {
      ...mapMenuOptions,
      landmarks: {
        icon: "map-marker-alt",
        template: (map) => <LandmarksMenu map={map} onFeatureSelected={onFeatureSelected} />,
      },
    };
  if (LayersNames.property)
    mapMenuOptions = {
      ...mapMenuOptions,
      mun_property: {
        icon: "home",
        template: (map) => <MunPropertyMenu map={map} onFeatureSelected={onFeatureSelected} />,
      },
    };
  if (LayersNames.permit)
    mapMenuOptions = {
      ...mapMenuOptions,
      sample_permit: {
        icon: "home",
        template: (map) => <PermitsLayer map={map} onFeatureSelected={onFeatureSelected} />,
      },
    };

  const navOptionSelected = (option) => {
    setOption(option);
    clearSearchLayerSource(mapRef.current.map);
    clearNearbyLayerSource(mapRef.current.map);
  };

  const renderSelectedOption = (option, style) => {
    return (
      <animated.div style={{ ...style }} className="map-side-animated-container">
        {option ? (
          mapMenuOptions[option].template(mapRef.current.map)
        ) : (
          <div>
            <div id="animation-container" />
            <h5 className="select-option-prompt-text">{resources.mapPage.mapOptionSelectPrompt}</h5>
          </div>
        )}
      </animated.div>
    );
  };

  const onFeatureClicked = (feature) => {
    if (!feature.id_) return;

    // on feature clicked on the map from the menu
    setSelectedFeature(feature);

    // TODO: Centeralize the Field IDs and layer sources
    let featureLabelFields, idField;
    switch (feature.id_.split(".")[0]) {
      case LayersNames.property:
        featureLabelFields = "dls_key";
        idField = "id";
        break;
      case LayersNames.landmarkes:
        featureLabelFields = "poi_name_a";
        idField = "id";
        break;
      case LayersNames.street:
        featureLabelFields = "name_a";
        idField = "id";
        break;
      case LayersNames.landmarkes:
        featureLabelFields = "dls_key,landuse_de";
        idField = "id";
        break;
      case LayersNames.parcels:
        featureLabelFields = "dls_key";
        idField = "id";
        break;
      case LayersNames.district:
        featureLabelFields = "district_n";
        idField = "id";
        break;
      case LayersNames.basins:
        featureLabelFields = "hod_aname";
        idField = "id";
        break;
      case LayersNames.villages:
        featureLabelFields = "vill_aname";
        idField = "id";
        break;
      case LayersNames.permit:
        featureLabelFields = "license_number";
        idField = "id";
        break;
      default:
        return;
    }

    zoomToFeatureInSearchLayer({
      textFields: featureLabelFields,
      idField: idField,
      id: feature.get(idField),
      map: mapRef.current.map,
    });
  };

  const stringifyFeatureInfo = () => {
    const values = selectedFeature.values_;
    return Object.keys(values).map((key) =>
      key != "geometry" ? (
        <div key={key}>
          <label>{key}</label> : <label>{values[key]}</label>
        </div>
      ) : null
    );
  };

  const onModalDismiss = () => {
    if (loadingNearby) return;
    setSelectedFeature(null);
  };

  const onShowNearbyLanduseClicked = async () => {
    if (loadingNearby) return;

    setloadingNearby(true);

    const response = await GeoServerApi.getLayer({
      layer: LayersNames.landuseAround,
      max: 500,
      filter: {},
      fields: "id,dls_key,landuse_de,geometry_spa",
      viewparams: "dlskey:" + selectedFeature.get("dls_key"),
    }).catch((e) => {
      console.log(e);
      // Todo: popup or some error notification
    });

    setloadingNearby(false);
    setSelectedFeature(null);

    // TODO: Separate these into a different layer and clear it when selection is changed and zoom only to this extent
    if (response && response.data && response.data.features) {
      addGeoJsonFeaturesToNearbyLayer({
        data: response.data,
        map: mapRef.current.map,
        clearSource: true,
        zoom: true,
      });
    }
  };

  return (
    <>
      {selectedFeature && (
        <Modal
          content={stringifyFeatureInfo()}
          onDismiss={onModalDismiss}
          header={<h4>معلومات القطعة</h4>}
          dismissLabel={loadingNearby ? resources.misc.pleaseWait : resources.misc.close}
          footer={
            option === "landuse" && (
              <span>
                <Button onClick={onShowNearbyLanduseClicked} color="dark">
                  {loadingNearby ? <Spinner /> : resources.misc.showNearbyLands}
                </Button>
              </span>
            )
          }
        />
      )}
      <div className="map-page-container">
        <MapNavBar mapMenuOptions={mapMenuOptions} onOptionSelected={navOptionSelected} />
        <div className="map-content-container">
          <div className="map-side-container">
            <Transition
              native
              reset
              unique
              items={option}
              from={{ opacity: 0, transform: "translate3d(-50%,0,0)" }}
              enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
              leave={{ opacity: 0, transform: "translate3d(-50%,0,0)" }}
            >
              {(option) => (style) => renderSelectedOption(option, style)}
            </Transition>
          </div>
          <Map withLegend={true} ref={mapRef} onFeatureClick={onFeatureClicked} />
        </div>
      </div>
    </>
  );
}
