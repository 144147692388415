import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GeoServerApi } from "../../../../../api/geoserverController";
import LayersNames from "../../../../../helpers/LayersNames";
export default function GeneralData({ data }) {
  const [villcode, setvillcode] = useState("");
  const [hodCode, sethodCode] = useState("");
  const [sheetNo, setsheetNo] = useState("");
  const [parcelId, setparcelId] = useState("");
  const [sectorCode, setsectorCode] = useState("");
  const [Area, setArea] = useState("");

  const getParcelData = async () => {
    const response = await GeoServerApi.getLayer({
      layer: LayersNames.parcels,
      max: "1",
      fields:
        "vill_code,hod_code,sheet_no,parcel_id,sector_cod,shape_area,dls_key",
      cqlFilter: `dls_key='${data.dlskey}'`,
    }).catch((e) => {
      console.log(e);
    });
    if (response && response.data && response.data.features) {
      if (response.data.features.length) {
        let feature = response.data.features[0];
        setvillcode(feature.properties.vill_code);
        sethodCode(feature.properties.hod_code);
        setsheetNo(feature.properties.sheet_no);
        setparcelId(feature.properties.parcel_id);
        setArea(feature.properties.shape_area);
        setsectorCode(feature.properties.sector_cod);
      }
    }
  };

  useEffect(() => {
    getParcelData();
  }, []);

  const getRowData = ({ label, data = "", titleSpan = 1, dataSpan = 1 }) => {
    return (
      <>
        <td colSpan={titleSpan} style={styles.td}>
          <label style={styles.label}> {label} </label>
        </td>
        <td colSpan={dataSpan} style={styles.td}>
          <label style={styles.label}> {data} </label>
        </td>
      </>
    );
  };

  return (
    <table style={styles.table}>
      <tbody>
        <tr>
          {getRowData({
            label: "اسم طالب المخطط / المالك",
            titleSpan: 2,
            dataSpan: 2,
          })}
        </tr>
        <tr>
          {getRowData({
            label: "إسم ورقم القرية",
            data: `${data.villageName} - ${villcode}`,
          })}
          {getRowData({ label: "لوحة رقم", data: `${sheetNo}` })}
        </tr>
        <tr>
          {getRowData({
            label: "إسم ورقم الحوض",
            data: `${data.hodeName} - ${hodCode}`,
          })}
          {getRowData({ label: "رقم القطعة", data: `${parcelId}` })}
        </tr>
        <tr>
          {getRowData({ label: "رقم الحي", data: `${sectorCode}` })}
          {getRowData({ label: "مساحة القطعة ", data: `${Area}` })}
        </tr>
        <tr>
          {getRowData({ label: "نوع وفئة الإستعمال" })}
          {getRowData({ label: "انظر الاحكام و الاستعمال جانبا" })}
        </tr>
        <tr>
          {getRowData({
            label: "مخطط حالي رقم / قرار",
            titleSpan: 2,
            dataSpan: 2,
          })}
        </tr>
        <tr>
          {getRowData({
            label: "مخطط سابق رقم / قرار",
            titleSpan: 2,
            dataSpan: 2,
          })}
        </tr>
      </tbody>
    </table>
  );
}

GeneralData.propTypes = {
  data: PropTypes.object,
};

const styles = {
  table: {
    border: "1px solid black",
    borderCollapse: "separate",
    height: "100%",
    maxHeight: "500",
    width: "100%",
  },
  td: {
    border: "1px solid black",
    height: "5%",
    width: "24%",
  },
  label: {
    textAlign: "right",
    fontSize: "9px",
    fontWeight: "bold",
    margin: "0px",
    display: "block",
  },
};
