import React, { useState } from "react";

export const legendColors = [
  { label: "سكن أ", style: { backgroundColor: "#ff8e52" }, id: [1, 304, 360] },
  {
    label: "سكن أ خاص",
    style: {
      background:
        "repeating-linear-gradient(45deg, rgb(255,142,82,255), rgb(255,142,82,255) 1.5px ,black 2.5px)",
    },
    id: [249, 278],
  },
  { label: "سكن ب", style: { backgroundColor: "#01feff" }, id: [2, 361, 366] },
  {
    label: "سكن ب خاص",
    style: {
      background:
        "repeating-linear-gradient(45deg, rgb(1,254,255,255), rgb(1,254,255,255) 1.5px ,black 2.5px)",
    },
    id: [
      241, 242, 243, 248, 263, 264, 269, 277, 279, 306, 307, 376, 379, 383, 384,
      385,
    ],
  },
  { label: "سكن ج", style: { backgroundColor: "#fefe1f" }, id: [3, 362, 367] },
  {
    label: "سكن ج خاص",
    style: {
      background:
        "repeating-linear-gradient(45deg, rgb(254,254,31,255), rgb(254,254,31,255) 1.5px ,black 2.5px)",
    },
    id: [309, 377, 382],
  },
  { label: "سكن د", style: { backgroundColor: "#e08c76" }, id: [4, 363] },
  {
    label: "سكن د خاص",
    style: {
      background:
        "repeating-linear-gradient(45deg, rgb(224,140,118,255), rgb(224,140,118,255) 1.5px ,black 2.5px)",
    },
    id: [252, 265, 333, 381],
  },
  {
    label: "سكن أخضر",
    style: {
      background:
        "repeating-linear-gradient(-45deg, transparent, transparent 1.5px ,rgb(134,254,148,255) 2.5px),repeating-linear-gradient(45deg, white, white 1.5px ,rgb(134,254,148,255) 2.5px)",
      border: "1px solid rgb(134,254,148,255)",
    },
    id: [15],
  },
  { label: "حديقة", style: { backgroundColor: "#00ff58" }, id: [46, 337] },
  { label: "مباني عامة", style: { backgroundColor: "#fe4040" }, id: [51] },
  {
    label: "مباني عامة بأحكام خاصة",
    style: {
      background:
        "repeating-linear-gradient(45deg, #fe4040, #fe4040 1.5px ,black 2.5px)",
    },
    id: [255],
  },
  {
    label: "تجاري",
    style: { backgroundColor: "#8e8f8e" },
    id: [
      5, 6, 7, 8, 9, 10, 11, 18, 19, 20, 21, 160, 246, 247, 254, 256, 257, 258,
      260, 266, 267, 268, 282, 281, 282, 283, 339, 347, 365, 371, 373, 375,
    ],
  },
  {
    label: "مقبرة",
    style: {
      background:
        "repeating-linear-gradient(45deg, #fed618, #fed618 1.5px ,#32b023 2.5px)",
    },
    id: [54],
  },
  {
    label: "ارتداد امامي",
    style: { backgroundColor: "#01a925" },
    id: [286, 287, 288, 289, 290, 291, 292, 341, 342, 343, 344, 345],
  },
  {
    label: "ارتداد امامي اقواس",
    style: { backgroundColor: "#fefe1f" },
    id: [295, 296, 297],
  },
  {
    label: "متعدد استعمال محطة وقود",
    style: {
      background:
        "repeating-linear-gradient(45deg, white, white 1.5px ,black 2.5px)",
    },
    id: [120, 237, 238, 239, 240, 251, 274, 275, 276, 305],
  },
  {
    label: "تطوير حضري مرحلة أولى",
    style: {
      background:
        "repeating-linear-gradient(-45deg, transparent, transparent 1.5px ,black 2.5px),repeating-linear-gradient(45deg, #feefb2, #feefb2 1.5px ,black 2.5px)",
    },
    id: [335, 369],
  },
  {
    label: "تطوير حضري مرحلة ثانية",
    style: {
      background:
        "repeating-linear-gradient(-45deg, #feefb2, #feefb2 1.5px ,black 2.5px)",
    },
    id: [370],
  },
  {
    label: "تطوير حضري مرحلة ثالثة",
    style: {
      background:
        "repeating-linear-gradient(45deg, #feefb2, #feefb2 1.5px ,black 2.5px)",
    },
    id: [372],
  },
  {
    label: "مخيم اربد",
    style: {
      background:
        "repeating-linear-gradient(45deg, white, white 1.5px ,black 2.5px)",
      border: "1px solid #e7a794",
    },
    id: [301],
  },
  { label: "حرفية", style: { backgroundColor: "#edcbff" }, id: [364] },
  {
    label: "حرفية خاصة",
    style: {
      background:
        "repeating-linear-gradient(45deg, #edcbff, #edcbff 1.5px ,black 2.5px)",
    },
    id: [244, 245, 380],
  },
  {
    label: "  سكن تطوير حضري بأحكام خاصة",
    style: { backgroundColor: "#e0d816" },
    id: [250],
  },
  {
    label: "مسجد",
    style: {
      background:
        "repeating-linear-gradient(45deg, #9f6f48, #9f6f48 1.5px ,black 2.5px)",
    },
    id: [103, 303],
  },
  { label: "خدمات", style: { backgroundColor: "#0003b4" }, id: [161] },
  {
    label: "زراعي داخلي تنظيم",
    style: {
      background:
        "repeating-linear-gradient(45deg, white, white 1.5px ,rgb(134,254,148,255) 2.5px)",
      border: "0.1px solid rgb(134,254,148,255)",
    },
    id: [14, 359],
  },
  {
    label: "موقف سيارات",
    style: {
      background:
        "repeating-linear-gradient(transparent, transparent 3px ,black 4px),repeating-linear-gradient(90deg, white, white 3px ,black 4px)",
      border: "0.1px solid black",
    },
    id: [55],
  },
  {
    label: "ساحات",
    style: {
      background:
        "repeating-linear-gradient(transparent, transparent 3px ,#606061 4px),repeating-linear-gradient(90deg, white, white 3px ,#606061 4px)",
      border: "0.1px solid black",
    },
    id: [177],
  },
  {
    label: "سكن ريفي",
    style: {
      background:
        "repeating-linear-gradient(transparent, transparent 3px ,#60ff72 4px),repeating-linear-gradient(90deg, white, white 3px ,#60ff72 4px)",
      border: "0.1px solid #60ff72",
    },
    id: [16, 368, 378],
  },
  {
    label: "زراعي خارج التنظيم",
    style: {
      background:
        "repeating-linear-gradient(transparent, transparent 1.5px ,transparent 2.5px)",
      border: "1px solid white",
    },
    id: [374],
  },
  // {
  //   label: "سكن شعبي",
  //   style: {
  //     background:
  //       "repeating-linear-gradient(45deg,#AB755D, #AB755D 1.5px ,#e3b49f 2.5px)",
  //   },
  //   id: [],
  // },
  // {
  //   label: "سكن ب بأحكام د",
  //   style: {
  //     background:
  //       "repeating-linear-gradient(45deg,#1F95CD, #1F95CD 1.5px ,#4D8F52 2.5px)",
  //   },
  //   id: [],
  // },
];

export default function MapReportLegend({ selected }) {
  let tempLeg = [];
  return (
    selected.length > 0 && (
      <div className="irbid-map-legend">
        {selected.map((e, idx) => {
          console.log("object", e);
          let leg = legendColors.find((f) => f.id.find((id) => id == e));
          if (typeof leg !== "undefined") {
            if (tempLeg.includes(leg.label)) return false;
            tempLeg.push(leg.label);
          }
          if (!leg) {
            leg = {
              label: "أخرى",
              style: {
                backgroundColor: "#ad8847",
              },
              id: -1,
            };
          }
          return (
            <div key={idx} style={styles.iconContainer}>
              <div style={{ ...styles.legendIcon, ...leg.style }}></div>
              <label style={styles.lbl}>{leg.label}</label>
            </div>
          );
        })}
      </div>
    )
  );
}

const styles = {
  legendIcon: {
    height: "14px",
    width: "14px",
    id: 1,
  },
  iconContainer: {
    display: "flex",
    alignItems: "flex-end",
    id: 1,
  },
  lbl: {
    fontSize: "12px",
    paddingRight: "5px",
    padding: "0px 6px 0px 0px",
    margin: "1px",
    fontWeight: "bold",
    color: "var(--color-bright-font)",
  },
};
