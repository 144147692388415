import React, { Component } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import Downshift from "downshift";
import PropTypes from "prop-types";
import { debounce } from "throttle-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../DropDownShift/DropDownShift.css";

export default class DropDownShift extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ddlData: this.props.initialData,
    };

    this.autoCompleteDebounce = debounce(500, this.autoCompleteSearch);
  }

  onInputchage = (event) => {
    if (!event.target.value) {
      this.setState({ ddlData: [] });
      return;
    }
    this.autoCompleteDebounce(event.target.value);
  };

  autoCompleteSearch = async (value) => {
    this.setState({
      isLoading: true,
    });
    this.setState(
      {
        ddlData: await this.props.fetchData(value),
      },
      () => {
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  clearSelectionOutside = () => {
    this.downshift.clearSelection();
    if (this.props.clearSelection) this.props.clearSelection();
  };

  onSelectedChange = (selection) => {
    if (selection) {
      if (this.props.clearSelection) this.props.clearSelection();
      this.props.onSelectedChange(selection);
    }
  };

  render() {
    return (
      <Downshift
        onChange={(selection) => this.onSelectedChange(selection)}
        // initialSelectedItem={this.props.initialData ? this.props.initialData[0] : {}}
        itemToString={(item) => (item ? item[this.props.textField] : "")}
        ref={(ref) => (this.downshift = ref)}
      >
        {({ getInputProps, getItemProps, getLabelProps, isOpen, highlightedIndex, selectedItem, clearSelection }) => {
          return (
            <div>
              <FormGroup className="dropDownShift_formGroup">
                <Label {...getLabelProps()}>{this.props.searchLabel}</Label>
                <Input
                  className="dropDownShift_searchInput"
                  {...getInputProps({
                    placeholder: this.props.searchPlaceHolder || "بحث",
                    onChange: this.onInputchage,
                  })}
                />
                <FontAwesomeIcon
                  icon={this.state.isLoading ? "spinner" : "times"}
                  className={this.state.isLoading ? "dropDownShift_Loading" : "dropDownShift_clearIcon"}
                  onClick={() => {
                    if (!this.state.isLoading) {
                      if (this.props.onclickClear) this.props.onclickClear();
                      if (this.props.clearSelection) this.props.clearSelection();
                      clearSelection();
                    }
                  }}
                />
              </FormGroup>
              {isOpen ? (
                <div className="dropDownShift_Container">
                  {Boolean(this.state.ddlData) &&
                    this.state.ddlData.slice(0, 10).map((item, index) => (
                      <div
                        className="dropdown-item"
                        {...getItemProps({ key: index, index, item })}
                        style={{
                          backgroundColor: highlightedIndex === index ? "var(--color-primary-lighter)" : "white",
                          fontWeight: selectedItem === item ? "bold" : "normal",
                          color: highlightedIndex === index ? "white" : "black",
                        }}
                      >
                        {item[this.props.textField]}
                      </div>
                    ))}
                </div>
              ) : null}
            </div>
          );
        }}
      </Downshift>
    );
  }
}

DropDownShift.propTypes = {
  onSelectedChange: PropTypes.func,
  fetchData: PropTypes.func,
  searchLabel: PropTypes.string,
  searchPlaceHolder: PropTypes.string,
  textField: PropTypes.string.isRequired,
  clearSelection: PropTypes.func,
  onclickClear: PropTypes.func,
  initialData: PropTypes.array,
};
