import React, { Component } from "react";
import { CreateMap } from "../../helpers/MapHelper";
import PropTypes from "prop-types";
import "ol/ol.css";
import "ol-layerswitcher/src/ol-layerswitcher.css";
import "./map.css";
import "ol-ext/dist/ol-ext.min.css";
import MapLegend from "./MapLegend";
export default class Map extends Component {
  componentDidMount() {
    this.map = CreateMap({
      mapRef: this.refs.mapContainer,
      defaultMapProvider: this.props.mapProvider || "MOMA",
      isEmpty: this.props.isEmpty,
      callOut: this.props.onFeatureClick,
      withRotation: this.props.withRotation,
    });
  }

  render() {
    return (
      <div ref="mapContainer" className="map">
        {Boolean(this.props.withLegend) && <MapLegend />}
      </div>
    );
  }
}

Map.proptype = {
  isEmpty: PropTypes.bool,
  onFeatureClick: PropTypes.func,
  mapProvider: PropTypes.string,
};
