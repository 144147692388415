import React from "react";
import BaseMenu from "./BaseSearchMenu";
import { resources } from "../../../../assets/LocalizationResources";

export default function PermitsLayer({ map, onFeatureSelected }) {
  return (
    <BaseMenu
      layerName="sample_permit"
      filterField="license_number"
      idField="id"
      labelField="license_number"
      searchFieldLabel="رقم الرخصة"
      icon="search"
      map={map}
      onFeatureSelected={onFeatureSelected}
      layerFields="geometry_spa,id,license_number,profession,licensee,owner,district_name,basin_name,street_name,date_of_license,dls_key"
      infoFields={[
        {
          field: "profession",
          label: "الترخيص",
          icon: "map-signs",
          valueExp: val => val || resources.misc.noData
        },
        {
          field: "licensee",
          label: "المرخص له",
          icon: "map-signs",
          valueExp: val => val || resources.misc.noData
        },
        {
          field: "owner",
          label: "المالك",
          icon: "map-signs",
          valueExp: val => val || resources.misc.noData
        }
      ]}
    />
  );
}
