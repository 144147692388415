import React from "react";
import { Collapse, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import "./nav-menu.css";
import logo from "../../../assets/images/logo.png";
import { resources } from "../../../assets/LocalizationResources";
import NavUserBadge from "../NavUserBadge/NavUserBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faBars } from "@fortawesome/free-solid-svg-icons";
import NotificationBadge from "../../NotificationBadge/NotificationBadge";
import Map from "../../Map/Map";

const divider = () => <span className="navbar-divider" />;

export default class NavBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			burgerMenuIsOpen: false
		};
	}

	toggle = () => {
		this.setState({
			isOpen: !this.state.isOpen
		});
	};

	toggleBurgerMenu = () => {
		this.setState(
			{
				burgerMenuIsOpen: !this.state.burgerMenuIsOpen
			},
			() => {
				if (Map.map) setTimeout(() => Map.map.updateSize(), 250);
			}
		);

		if (this.props.onBurgerClicked) this.props.onBurgerClicked();
	};

	render() {
		return (
			<header className="site-header">
				<Navbar className="rtl custom-navbar navbar-expand-sm navbar-toggleable-sm box-shadow" dark>
					<FontAwesomeIcon
						icon={this.state.burgerMenuIsOpen ? faAngleRight : faBars}
						className="burger-menu-icon"
						onClick={this.toggleBurgerMenu}
					/>
					<NavbarBrand className="m-0">
						<img className="logo-image" src={logo} alt="" />
						{resources.projectTitle}
					</NavbarBrand>
					<NavbarToggler onClick={this.toggle} className="ml-2 no-outline custom-navbar-toggler" />
					<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
						<NavUserBadge />
						{divider()}
						<NotificationBadge
							icon="bell"
							badgeColor="#f44336"
							hoverColor="#ff5722"
							iconColor="#FFFFFF"
							textColor="#333333"
							title={resources.notification}
						>
							No Notification
						</NotificationBadge>
						<NotificationBadge
							icon="envelope"
							badgeColor="#009688"
							hoverColor="#09ada2"
							iconColor="#FFFFFF"
							textColor="#333333"
							title={resources.messages}
						>
							No Notification
						</NotificationBadge>
						<NotificationBadge
							icon="search"
							badgeColor="#2196f3"
							hoverColor="#49aeff"
							iconColor="#FFFFFF"
							textColor="#333333"
							onClick={() => alert("ToDo: Landmark search")}
						/>
						<span className="spacer" />
					</Collapse>
				</Navbar>
			</header>
		);
	}
}
