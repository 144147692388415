import React, { useState, useEffect } from "react";
import { GeoServerApi } from "../../../../api/geoserverController";
import { resources } from "../../../../assets/LocalizationResources";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label, FormGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import rcolor from "rcolor";
import LayersNames from "../../../../helpers/LayersNames";

export const fillFilter = async (type, bassin, village) => {
  let properties;
  let id;
  let label;
  let withCode = false;
  let primaryid;
  switch (type) {
    case "village":
      properties = {
        layer: LayersNames.villages,
        max: "10",
        fields: "vill_aname,vill_code, id",
        cqlFilter: !+village ? `vill_aname Like '%${village}%'` : `vill_code = ${village}`,
      };
      id = "vill_code";
      label = "vill_aname";
      withCode = true;
      primaryid = "id";
      break;
    case "basin":
      properties = {
        layer: LayersNames.basins,
        max: "10",
        fields: "hod_aname,hod_code,id",
        cqlFilter:
          (!+bassin ? `hod_aname Like '%${bassin}%'` : `hod_code =  ${bassin}`) +
          (Boolean(village) ? ` AND vill_code = ${village} ` : ""),
      };
      id = "hod_code";
      label = "hod_aname";
      withCode = true;
      primaryid = "id";
      break;
    default:
      return Promise.resolve([]);
  }

  const response = await GeoServerApi.getLayer(properties).catch((e) => {
    console.log(type, e);
    // Todo: popup or some error notification
    return Promise.resolve([]);
  });

  if (response && response.data && response.data.features) {
    if (!response.data.features.length) {
      return Promise.resolve([]);
    } else {
      return Promise.resolve(
        response.data.features.map((feature, idx) => {
          return {
            primaryid: feature.properties[primaryid],
            id: feature.properties[id],
            label: !withCode ? feature.properties[label] : `${feature.properties[label]} - ${feature.properties[id]}`,
          };
        })
      );
    }
  } else {
    // Todo: popup or some error notification
    return Promise.resolve([]);
  }
};

export const DynamicFiltersMenu = ({ selectedLanduse, onSelectLanduse }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [items, setItems] = useState([
    "سكن ب بأحكام خاصة طابقين وروف",
    "سكن ج خاص",
    "ارتداد تجاري 4م اقواس",
    "صناعات خاصة",
    "ارتداد تجاري 2.5 م",
    "سكن بأحكام خاصة",
    "ارتداد امامي 3م اقواس",
    "معارض تجارية",
    "حديقة ومركز ثقافي وترفيهي  وخدمات تجاري",
    "سكن د",
    "مصنع",
    "مركز تجاري",
    "سكن زراعي",
    "سكن د خاص بأحكام خاصة",
    "سكن أ",
    "زراعي خارج التنظيم",
    "ارتداد تجاري 6م",
    "حديقة ومركز ثقافي وترفيهي  ومركز تجاري",
    "ارتداد تجاري 3م",
    "سكن أ خاص",
    "مؤسسة اسكان",
    "سكن ب خاص",
    "تجاري بأحكام خاصة",
    "تجاري محلي",
    "خدمات ومركز تجاري",
    "ارتداد خلفي 8م",
    "سكن ب",
    "سكن ج",
    "ارتداد تجاري2م",
    "ارتداد تجاري 7م",
    "صناعات حرفية",
    "ارتداد تجاري",
    "ارتداد تجاري 3م اقواس",
    "ساحات مبلطة",
    "سكن أخضر",
    "شركة الكهرباء",
    "أماكن عبادة",
    "سكن شعبي بأحكام خاصة",
    "حرفي بأحكام خاصة",
    "خدمات",
    "تجاري طولي خاص",
    "مخيم اربد",
    "ارتداد تجاري 5م",
    "مباني عامة",
    "سكن د خاص",
    "شارع خدمات",
    "مقبرة",
    "سكن ب بأحكام سكن د",
    "حديقة",
    "تجاري طولي",
    "ارتداد تجاري 4م",
    "ارتداد تجاري 2م",
    "سكن ريفي",
  ]);

  const addlandusefilter = (e) => {
    onSelectLanduse(e);
  };

  const handleSearchInput = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <FormGroup style={{ marginTop: 15 }}>
      <Label>{resources.mapMenu.landuse}</Label>
      <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} style={{ width: "100%" }}>
        {!isOpen ? (
          <DropdownToggle
            color="default"
            style={{
              width: "100%",
              textAlign: "right",
              border: "1px solid #ced4da",
              backgroundColor: "white",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label style={{ fontSize: 14 }}>استعمالات الأراضي</label>
              <FontAwesomeIcon icon="plus" style={{ color: "gray" }} />
            </span>
          </DropdownToggle>
        ) : (
          <Input autoFocus={true} placeholder={resources.search} onChange={handleSearchInput} value={searchTerm} />
        )}
        <DropdownMenu right style={{ textAlign: "right", fontSize: 12, width: "100%" }}>
          {items
            .filter((e) => !selectedLanduse.includes(e) && e.includes(searchTerm))
            .map((item) => (
              <DropdownItem onClick={() => addlandusefilter(item)} key={item}>
                {item}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </Dropdown>
    </FormGroup>
  );
};

export const getAnalyzeData = async (cqlfilter, params) => {
  const response = await GeoServerApi.getLayer({
    layer: LayersNames.landuseCount,
    fields: "sum,landuse_de",
    cqlFilter: cqlfilter,
    viewparams: params,
  }).catch((e) => {
    console.log("error", e);
    return Promise.reject();
  });

  if (response && response.data && response.data.features) {
    if (!response.data.features.length) {
      return Promise.resolve([]);
    } else {
      return Promise.resolve(
        response.data.features
          .map((feature) => {
            const color = rcolor({ saturation: 0.5 });
            return {
              value: feature.properties["sum"],
              color: color,
              title: feature.properties["landuse_de"],
            };
          })
          .sort((a, b) => b.value - a.value)
      );
    }
  } else return Promise.resolve([]);
};
