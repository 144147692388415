import React, { useEffect } from "react";
import Lottie from "lottie-web";
import "./not-found-404.css";
import animationDate from "../../../assets/animations/animation-404.json";

export default function NotFound404() {
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.getElementById("animation-container"), // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: animationDate
      //path: animationDate // the path to the animation json
    });
  }, []);

  return (
    <div className="page-404-container">
      <div className="page-404-animation-container" id="animation-container" />
      <label className="page-404-label">الرابط المطلوب غير موجود</label>
    </div>
  );
}
