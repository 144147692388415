// //Irbid;
export default class LayersNames {
  static villages = "irbid_villages";
  static basins = "greater_irbid_basin";
  static parcels = "irbid_municipality_parceles";
  static landUse = "landuse_final";
  static sector = "distinct_landue_sector_codes";
  static sheets = "distinct_sheets";
  static district = "greater_irbid_districts";
  static property = "irbid_mun_property";
  static landmarkes = "irbid_landmarkes";
  static street = "street_centerline_irbid";
  static landusePublic = "landuse_final";
  static permit = "sample_permit";
  static landuseReportBaseLayer = "moma_landuseReport_Base_Layer";
  static generalRules = "landuse_rules_layer";
  static specialRules = null;
  static analyzeBaseMap = "moma_analyze_base_map";
  static baseMap = "moma_base_map";
  static landuseCount = "irbid_landuse_count";
}

// //MOMA
// export default class LayersNames {
//   static country = "country";
//   static governorate = "governorates";
//   static municipality = "municipality";
//   static villages = "villages";
//   static basins = "basin";
//   static sector = "distinct_sector_codes";
//   static parcels = "parcels";
//   static landUse = "landuse";
//   static district = "district";
//   static property = null;
//   static landmarkes = null;
//   static street = null;
//   static landusePublic = null;
//   static permit = null;
//   static landuseReportBaseLayer = "moma_landuseReport_Base_Layer";
//   static generalRules = "general_rule";
//   static specialRules = "landuse_special_rules";
//   static analyzeBaseMap = "moma_analyze_base_map";
//   static baseMap = "moma_base_map";
//   static landuseCount = "irbid_landuse_count";
// }
