import React, { useRef, useState, useEffect } from "react";
import Map from "../../Map/Map";
import "./search-and-analyze.css";
import AnalyzeFilters from "./Filters/AnalyzeFilters";

export default function SearchAndAnalyze() {
  const mapRef = useRef(null);

  return (
    <div className="analyze-page-container">
      <div className="analyze-map-container">
        <Map ref={mapRef} withLegend mapProvider="MOMA Analyze" />
      </div>
      <div className="analyze-content-container">
        <AnalyzeFilters />
      </div>
    </div>
  );
}
