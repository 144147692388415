import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FormInput from "../../../ui-components/FormInput";
import LandmarkSearchResultsList from "../../../ui-components/LandmarkSearchResultsList";
import {
  addGeoJsonFeaturesToSearchLayer,
  zoomToFeatureInSearchLayer,
  clearSearchLayerSource
} from "../../../../helpers/MapHelper";
import { Button } from "reactstrap";
import { GeoServerApi } from "../../../../api/geoserverController";
import Paginator from "../../../ui-components/Paginator";
import { Input } from "reactstrap";

export default function BaseSearchMenu(props) {
  const [searchTerm, setsearchTerm] = useState("");
  const [searchResult, setsearchResult] = useState([]);
  const [currentPage, setcurrentPage] = useState(0);
  const [totalFeatures, settotalFeatures] = useState(0);
  const [numberReturned, setnumberReturned] = useState(0);
  const [maxFeatures, setmaxFeatures] = useState(100);

  const onItemClick = item => {
    const feature = zoomToFeatureInSearchLayer({
      textField: props.labelField,
      textFields: props.featureLabelFields,
      idField: props.idField,
      id: item.id,
      map: props.map,
      getFeature: true
    });

    if (props.onFeatureSelected) {
      props.onFeatureSelected({
        textField: props.labelField,
        idField: props.idField,
        id: item.id,
        feature: feature
      });
    }

    //if (props.showNearbyLanduse) this.loadFeaturesAroundLanduse(item.label);
  };

  const onSearch = async () => {
    setsearchResult([]);
    const response = await GeoServerApi.getLayer({
      layer: props.layerName,
      max: maxFeatures,
      filter: { [props.filterField]: searchTerm },
      fields: props.layerFields,
      startIndex: currentPage * maxFeatures
    }).catch(e => {
      console.log(e);
      // Todo: popup or some error notification
    });

    if (response && response.data && response.data.features) {
      const { totalFeatures, numberReturned } = response.data;
      const searchResults = response.data.features.map((feature, idx) => {
        const info = props.infoFields.map(infoField => ({
          value: feature.properties[infoField.field],
          ...infoField
        }));
        return {
          myCustomeID: idx,
          id: feature.properties[props.idField],
          label: feature.properties[props.labelField],
          info: info
        };
      });

      if (!response.data.features.length) clearSearchLayerSource(props.map);
      else addGeoJsonFeaturesToSearchLayer({ data: response.data, map: props.map, zoom: true });
      setsearchResult(searchResults);
      settotalFeatures(totalFeatures);
      setnumberReturned(numberReturned);
    }
  };

  const onPaging = dir => {
    if (dir === 0) return;
    setcurrentPage(currentPage + dir);
  };

  const onFeaturesCountChange = e => {
    setmaxFeatures(+e.target.value);
  };

  useEffect(() => {
    onSearch();
  }, [maxFeatures, currentPage]);

  return (
    <div style={styles.container}>
      <div style={styles.inputGroup}>
        <FormInput
          containerStyle={styles.formInput}
          label={props.searchFieldLabel}
          value={searchTerm}
          icon={props.icon}
          onChange={e => setsearchTerm(e.target.value)}
        />
        <Button color="primary" style={styles.searchBtn} onClick={onSearch}>
          بحث
        </Button>
      </div>
      <LandmarkSearchResultsList
        listContainerStyle={styles.searchList}
        items={searchResult}
        clickable
        onItemClick={onItemClick}
      />

      {totalFeatures < 100 /* 100: minimum features count for paging */ ? null : (
        <div style={styles.featuresCountContainer}>
          <Input type="select" style={styles.featuresCountList} value={maxFeatures} onChange={onFeaturesCountChange}>
            <option value="100">100</option>
            <option value="200">200</option>
            <option value="300">300</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
            <option value="2000">2000</option>
            <option value="10000">10000 😨</option>
          </Input>
          <Paginator
            currentPage={currentPage}
            totalCount={totalFeatures}
            pageSize={maxFeatures}
            onPaging={onPaging}
            containerStyle={styles.paginator}
          />
        </div>
      )}
    </div>
  );
}

BaseSearchMenu.defaultProps = {
  maxFeatures: 100,
  infoFields: []
};

BaseSearchMenu.propTypes = {
  layerName: PropTypes.string.isRequired,
  filterField: PropTypes.string.isRequired,
  idField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  maxFeatures: PropTypes.number,
  searchFieldLabel: PropTypes.string,
  icon: PropTypes.string,
  layerFields: PropTypes.string.isRequired,
  infoFields: PropTypes.array,
  map: PropTypes.any,
  featureLabelFields: PropTypes.string,
  showNearbyLanduse: PropTypes.bool
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 10,
    left: 10,
    right: 10,
    bottom: 10
  },
  inputGroup: {
    display: "flex",
    alignItems: "flex-end"
  },
  formInput: {
    flex: 1,
    marginBottom: 0
  },
  searchBtn: {
    marginRight: 5,
    height: "max-content",
    fontSize: 14
  },
  searchList: {
    flex: 1,
    paddingLeft: 5,
    marginBottom: 0
  },
  featuresCountContainer: {
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #CCC",
    paddingTop: 6
  },
  featuresCountList: {
    marginLeft: 5,
    marginRight: 5,
    display: "inline-block",
    width: "unset"
  },
  paginator: {
    flex: 1
  }
};
