import React, { useRef, useEffect, useState } from "react";
import {
  Player,
  LoadingSpinner,
  ControlBar,
  ReplayControl,
  ForwardControl,
  PlaybackRateMenuButton,
  BigPlayButton
} from "video-react";
import Map from "../../Map/Map";
import { Gauge } from "react-advanced-gauge";
import { Button } from "reactstrap";
import {
  vehicleMarkerObj,
  prepareRouteLayer,
  removeRouteLayer,
  parseGpxXml
} from "./gpxMapHelper";
import "video-react/dist/video-react.css";
import "./gpx-drive-video-player.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Compass from "../../ui-components/Compass/Compass";

export default function GPXDriveVideoPlayer() {
  const player = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [polyline, setPolyline] = useState(null);
  const [playerCurrentDuration, setPlayerCurrentDuration] = useState(0);
  const [timeShift, setTimeShift] = useState(0);
  const [locationInfo, setLocationInfo] = useState({
    bearing: 0,
    speed: 0,
    distance: 0
  });
  const mapRef = useRef(null);

  useEffect(() => {
    player.current.subscribeToStateChange(handlePlayerStateChanges);

    // TODO: Make a file selector
    console.log("selecting new video");
    setSelectedVideo("Track-24-02-2020-001");

    return () => {
      // TODO: Cleanup
    };
  }, []);

  const handlePlayerStateChanges = (state, prevState) => {
    const currentTime = Math.floor(state.currentTime);
    const prevCurrentTime = Math.floor(prevState.currentTime);
    if (prevCurrentTime !== currentTime) setPlayerCurrentDuration(currentTime);
  };

  useEffect(() => {
    if (
      Boolean(polyline) &&
      polyline.trackLocations[playerCurrentDuration + timeShift]
    ) {
      vehicleMarkerObj.move({
        locationData:
          polyline.trackLocations[playerCurrentDuration + timeShift],
        currentSecond: playerCurrentDuration + timeShift
      });

      setLocationInfo(
        polyline.trackLocations[playerCurrentDuration + timeShift]
      );
    }
  }, [playerCurrentDuration]);

  useEffect(() => {
    if (selectedVideo) {
      removeRouteLayer(mapRef.current.map);
      fetch(`${window.location.origin}/StreetDriveVideos/${selectedVideo}.gpx`)
        .then(res => res.text())
        .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
        .then(xml => {
          setPolyline(parseGpxXml(xml));
        })
        .catch(err => {
          console.log(err);
          setPolyline(null);
        });
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (polyline) {
      prepareRouteLayer({
        map: mapRef.current.map,
        polyline: polyline
      });
    }
  }, [polyline]);

  return (
    <div className="gpx-container">
      <div className="gpx-top">
        <div className="gpx-top-right">
          <Player
            ref={player}
            playsInline
            muted
            fluid={false}
            height="100%"
            src={`${window.location.origin}/StreetDriveVideos/${selectedVideo}.mp4`}
          >
            <LoadingSpinner />
            <BigPlayButton position="center" />
            <ControlBar autoHide={true} disableDefaultControls={false}>
              <ReplayControl seconds={10} order={2.1} />
              <ForwardControl seconds={10} order={2.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
            </ControlBar>
          </Player>
        </div>
        <div className="gpx-top-left">
          <Map ref={mapRef} isEmpty={false} mapProvider="Google" />
        </div>
      </div>
      <div className="gpx-bottom">
        <div className="gpx-bottom-right">
          <h5 style={{ marginBottom: 20 }}>المقاطع المتوفرة</h5>
          <Button
            color="dark"
            style={{ width: "100%", marginBottom: 10 }}
            outline
            onClick={() => setSelectedVideo("Track-24-02-2020-001")}
          >
            Track-24-02-2020-001
          </Button>
          <Button
            color="dark"
            style={{ width: "100%", marginBottom: 10 }}
            outline
            onClick={() => setSelectedVideo("Track-24-02-2020-002")}
          >
            Track-24-02-2020-002
          </Button>
          <Button
            color="dark"
            style={{ width: "100%", marginBottom: 10 }}
            outline
            onClick={() => setSelectedVideo("Track-24-02-2020-003")}
          >
            Track-24-02-2020-003
          </Button>
        </div>
        <div className="gpx-bottom-left">
          <Gauge
            min={0}
            max={220}
            thresholds={[50, 70]}
            unit="km/h"
            value={locationInfo.speed}
            valueMatchColor
            showDomain
            showUnit
            width={280}
            height={280}
          />
          <Compass direction={locationInfo.bearing} />
          <div className="gpx-distance-container">
            <FontAwesomeIcon icon="ruler-horizontal" />
            <p>
              {(Math.round(locationInfo.distance * 1000) / 1000).toFixed(3)} km
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
