import React from "react";

export default function ReportNote() {
  return (
    <div style={styles.container}>
      <label style={styles.label}>الملاحظات:</label>
      <div style={styles.subcontainer}></div>
    </div>
  );
}

const styles = {
  container: {
    margin: "20px 5px 0px 5px",
    border: "1px solid black",
  },
  subcontainer: {
    height: 100,
    border: "1px solid black",
    margin: 5,
  },
  label: {
    fontSize: "13px",
    fontWeight: "bold",
    display: "block",
    marginRight: 5,
  },
};
