import React from "react";

export const legendColors = [
  { label: "سكن أ", style: { backgroundColor: "#ff8e52" } },
  {
    label: "سكن أ خاص",
    style: {
      background:
        "repeating-linear-gradient(45deg, rgb(255,142,82,255), rgb(255,142,82,255) 1.5px ,black 2.5px)",
    },
  },
  { label: "سكن ب", style: { backgroundColor: "#01feff" } },
  {
    label: "سكن ب خاص",
    style: {
      background:
        "repeating-linear-gradient(45deg, rgb(1,254,255,255), rgb(1,254,255,255) 1.5px ,black 2.5px)",
    },
  },
  { label: "سكن ج", style: { backgroundColor: "#fefe1f" } },
  {
    label: "سكن ج خاص",
    style: {
      background:
        "repeating-linear-gradient(45deg, rgb(254,254,31,255), rgb(254,254,31,255) 1.5px ,black 2.5px)",
    },
  },
  { label: "سكن د", style: { backgroundColor: "#e08c76" } },
  {
    label: "سكن د خاص",
    style: {
      background:
        "repeating-linear-gradient(45deg, rgb(224,140,118,255), rgb(224,140,118,255) 1.5px ,black 2.5px)",
    },
  },
  {
    label: "سكن أخضر",
    style: {
      background:
        "repeating-linear-gradient(-45deg, transparent, transparent 1.5px ,rgb(134,254,148,255) 2.5px),repeating-linear-gradient(45deg, white, white 1.5px ,rgb(134,254,148,255) 2.5px)",
      border: "1px solid rgb(134,254,148,255)",
    },
  },
  { label: "حديقة", style: { backgroundColor: "#00ff58" } },
  { label: "مباني عامة", style: { backgroundColor: "#fe4040" } },
  {
    label: "مباني عامة بأحكام خاصة",
    style: {
      background:
        "repeating-linear-gradient(45deg, #fe4040, #fe4040 1.5px ,black 2.5px)",
    },
  },
  {
    label: "تجاري",
    style: { backgroundColor: "#8e8f8e" },
  },
  {
    label: "مقبرة",
    style: {
      background:
        "repeating-linear-gradient(45deg, #fed618, #fed618 1.5px ,#32b023 2.5px)",
    },
  },
  { label: "ارتداد امامي", style: { backgroundColor: "#01a925" } },
  { label: "ارتداد امامي اقواس", style: { backgroundColor: "#fefe1f" } },
  {
    label: "متعدد استعمال محطة وقود",
    style: {
      background:
        "repeating-linear-gradient(45deg, white, white 1.5px ,black 2.5px)",
    },
  },
  {
    label: "تطوير حضري مرحلة أولى",
    style: {
      background:
        "repeating-linear-gradient(-45deg, transparent, transparent 1.5px ,black 2.5px),repeating-linear-gradient(45deg, #feefb2, #feefb2 1.5px ,black 2.5px)",
    },
  },
  {
    label: "تطوير حضري مرحلة ثانية",
    style: {
      background:
        "repeating-linear-gradient(-45deg, #feefb2, #feefb2 1.5px ,black 2.5px)",
    },
  },
  {
    label: "تطوير حضري مرحلة ثالثة",
    style: {
      background:
        "repeating-linear-gradient(45deg, #feefb2, #feefb2 1.5px ,black 2.5px)",
    },
  },
  {
    label: "مخيم اربد",
    style: {
      background:
        "repeating-linear-gradient(45deg, white, white 1.5px ,black 2.5px)",
      border: "1px solid #e7a794",
    },
  },
  { label: "حرفية", style: { backgroundColor: "#edcbff" } },
  {
    label: "حرفية خاصة",
    style: {
      background:
        "repeating-linear-gradient(45deg, #edcbff, #edcbff 1.5px ,black 2.5px)",
    },
  },
  {
    label: "  سكن تطوير حضري بأحكام خاصة",
    style: { backgroundColor: "#e0d816" },
  },
  {
    label: "مسجد",
    style: {
      background:
        "repeating-linear-gradient(45deg, #9f6f48, #9f6f48 1.5px ,black 2.5px)",
    },
  },
  { label: "خدمات", style: { backgroundColor: "#0003b4" } },
  {
    label: "زراعي داخلي تنظيم",
    style: {
      background:
        "repeating-linear-gradient(45deg, white, white 1.5px ,rgb(134,254,148,255) 2.5px)",
      border: "0.1px solid rgb(134,254,148,255)",
    },
  },
  {
    label: "موقف سيارات",
    style: {
      background:
        "repeating-linear-gradient(transparent, transparent 3px ,black 4px),repeating-linear-gradient(90deg, white, white 3px ,black 4px)",
      border: "0.1px solid black",
    },
  },
  {
    label: "ساحات",
    style: {
      background:
        "repeating-linear-gradient(transparent, transparent 3px ,#606061 4px),repeating-linear-gradient(90deg, white, white 3px ,#606061 4px)",
      border: "0.1px solid black",
    },
  },
  {
    label: "سكن ريفي",
    style: {
      background:
        "repeating-linear-gradient(transparent, transparent 3px ,#60ff72 4px),repeating-linear-gradient(90deg, white, white 3px ,#60ff72 4px)",
      border: "0.1px solid #60ff72",
    },
  },
  {
    label: "زراعي خارج التنظيم",
    style: {
      background:
        "repeating-linear-gradient(transparent, transparent 1.5px ,transparent 2.5px)",
      border: "1px solid white",
    },
  },
  {
    label: "سكن شعبي",
    style: {
      background:
        "repeating-linear-gradient(45deg,#AB755D, #AB755D 1.5px ,#e3b49f 2.5px)",
    },
  },
  {
    label: "سكن ب بأحكام د",
    style: {
      background:
        "repeating-linear-gradient(45deg,#1F95CD, #1F95CD 1.5px ,#4D8F52 2.5px)",
    },
  },
];

export default function MapLegend() {
  return (
    <div className="map-legend">
      {legendColors.map((e, idx) => (
        <div key={idx} style={styles.iconContainer}>
          <div style={{ ...styles.legendIcon, ...e.style }}></div>
          <label style={styles.lbl}>{e.label}</label>
        </div>
      ))}
    </div>
  );
}

const styles = {
  legendIcon: {
    height: "14px",
    width: "14px",
  },
  iconContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  lbl: {
    fontSize: "10px",
    paddingRight: "5px",
    padding: "0px 6px 0px 0px",
    margin: "1px",
    fontWeight: "bold",
    color: "var(--color-bright-font)",
  },
};
