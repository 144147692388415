import React from "react";
import { Input } from "reactstrap";

export default function IrbidReportNote({ notes = [] }) {
  return (
    <>
      <div className="irbid-report-notes">
        <label style={styles.headerLabel}>الملاحظات:</label>
        {notes.map(
          (note, idx) =>
            note && (
              <label key={idx} style={styles.label}>
                - {note}
              </label>
            )
        )}
      </div>
      <Input className="irbid-report-notes-input" placeholder="ملاحظات إضافية" />
    </>
  );
}

const styles = {
  label: {
    fontSize: "10px",
    fontWeight: "bold",
    display: "block",
    margin: "0px 5px",
  },
  headerLabel: {
    fontSize: "13px",
    fontWeight: "bold",
    display: "block",
    marginRight: 5,
  },
};
