import React, { useState, useEffect } from "react";
import { Button, Input, Form, FormGroup, Label, FormText } from "reactstrap";
import { resources } from "../../../../assets/LocalizationResources";
import SettingsPageWrapper from "../SettingsPageWrapper";
import Select from "react-select";
import settingsApi, { isSuccessfulRequest } from "../../../../api/settingsApi";
import authApi, { isSuccessfulRequest as authsucces } from "../../../../api/authApi";
import { useHistory } from "react-router-dom";
import PasswordForm from "./PasswordForm";

export default function RegisterPage({ location }) {
  const [user, setUser] = useState({});
  const [selectedgroups, setselectedgroups] = useState([]);
  const [groups, setgroups] = useState([]);
  const [confirmPasswordError, setconfirmPasswordError] = useState(false);
  const [groupError, setgroupError] = useState(false);
  const router = useHistory();

  const getGroups = async () => {
    const response = await settingsApi.groupsdll();
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      setgroups(
        response.data.map(group => ({
          label: group.Name,
          value: group.ID
        }))
      );
    }
  };

  useEffect(() => {
    getGroups();
  }, []);

  const getUserData = async ID => {
    const response = await settingsApi.getuser({ ID });
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      setUser(response.data[0]);
    }
  };

  const getUsergroups = async ID => {
    const response = await settingsApi.getusergroups({ ID });
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      setselectedgroups(
        response.data.map(group => ({
          label: group.Name,
          value: group.ID
        }))
      );
    }
  };

  useEffect(() => {
    if (groups.length > 0 && location && location.state) {
      getUserData(location.state);
      getUsergroups(location.state);
    }
  }, [groups]);

  const handleSubmit = async event => {
    event.preventDefault();
    if (user.Password != user.ConfirmPassword) {
      setconfirmPasswordError(true);
      return;
    }
    if (selectedgroups.length <= 0) {
      setgroupError(true);
      return;
    }
    let options = {
      groupsIDs: selectedgroups.map(g => g.value).join(","),
      Name: user.Name,
      Password: user.Password,
      Email: user.Email,
      phone: user.Phone,
      CreatedByID: 1 /*To Do add login user */
    };
    const response = user.ID ? await authApi.editUser({ ...options, ID: user.ID }) : await authApi.addUser(options);
    if (authsucces(response)) router.goBack();
    //ToDo : Handle Add user Error
  };

  const onGroupChange = e => {
    setgroupError(false);
    if (e) setselectedgroups(e);
  };

  const handleInputChanges = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
    if ([e.target.name] == "ConfirmPassword") setconfirmPasswordError(false);
  };

  return (
    <SettingsPageWrapper
      title={user.ID ? resources.settingsResources.users.editUser : resources.settingsResources.users.addUser}
    >
      <Form onSubmit={handleSubmit} style={styles.form}>
        <FormGroup>
          <Label>{resources.user.name}</Label>
          <Input
            name="Name"
            type="text"
            autoComplete="disabled"
            required
            maxLength="255"
            minLength="3"
            onChange={handleInputChanges}
            value={user.Name || ""}
          />
        </FormGroup>
        <FormGroup>
          <Label>{resources.user.email}</Label>
          <Input
            name="Email"
            type="email"
            autoComplete="disabled"
            required
            maxLength="255"
            onChange={handleInputChanges}
            value={user.Email || ""}
          />
        </FormGroup>
        <FormGroup>
          <Label>{resources.settingsResources.users.usersPermissions}</Label>
          <Select
            isMulti
            isSearchable
            placeholder={resources.settingsResources.users.selectusersPermissions}
            value={selectedgroups}
            onChange={onGroupChange}
            options={groups}
            noOptionsMessage={() => {
              return resources.misc.noData;
            }}
            styles={{
              ...styles.selectStyles,
              control: provided => {
                return {
                  ...provided,
                  borderColor: groupError ? "red" : "#ced4da"
                };
              }
            }}
          />
          {groupError && (
            <FormText>
              <label style={{ color: "#dc3545" }}>{resources.settingsResources.users.usergroupsError}</label>
            </FormText>
          )}
        </FormGroup>
        <FormGroup>
          <Label>{resources.user.phone}</Label>
          <Input
            name="Phone"
            type="phone"
            pattern="^[0-9]*$"
            autoComplete="disabled"
            required
            maxLength="10"
            value={user.Phone || ""}
            onChange={handleInputChanges}
          />
        </FormGroup>
        {!user.ID && (
          <PasswordForm handleInputChanges={handleInputChanges} confirmPasswordError={confirmPasswordError} />
        )}
        <Button type="submit" color="primary" style={styles.formBtn}>
          {resources.misc.save}
        </Button>
        <Button type="button" color="danger" onClick={() => router.goBack()} style={styles.formBtn}>
          {resources.misc.cancel}
        </Button>
      </Form>
    </SettingsPageWrapper>
  );
}

const styles = {
  form: {
    maxWidth: "400px"
  },
  formBtn: {
    marginTop: 20,
    marginLeft: 10
  },
  selectStyles: {
    multiValue: provided => {
      return {
        ...provided,
        backgroundColor: "#c6edf3",
        fontWeight: "bold",
        lineHeight: "2",
        paddingRight: 5,
        borderRadius: 4
      };
    }
  }
};
