import React from "react";
import { Route, Switch } from "react-router-dom";
import { resources } from "./assets/LocalizationResources";
import "./index.css";
import "./components/Layout/Layout.css";
import Layout from "./components/Layout/Layout";
import { Home } from "./components/pages/Home/Home";
import PrintLandUse from "./components/pages/PrintLandUse/PrintLandUse";
import NotFound404 from "./components/pages/NotFound404";
import MapPage from "./components/pages/MapPage";
import LoginPage from "./components/pages/LoginPage/LoginPage";
import LanduseReport from "./components/pages/PrintLandUse/LanduseReport/LanduseReport";
import Settings from "./components/pages/Settings/Settings";
import LayersView from "./components/pages/Settings/Layers/LayersView";
import LayerUsers from "./components/pages/Settings/Layers/LayerUsers";
import "./index.css";
import "./components/Layout/Layout.css";
import RegisterPage from "./components/pages/Settings/Users/RegisterPage";
import UsersView from "./components/pages/Settings/Users/UsersView";
import ManageLayer from "./components/pages/Settings/Layers/AddEditLayers";
import ManageGroupPermissions from "./components/pages/Settings/Permissions/AddEditGroupPermissions";
import GroupsView from "./components/pages/Settings/Permissions/GroupsView";
import ChangePassword from "./components/pages/Settings/Users/ChangePassword";
import { useSelector } from "react-redux";
import { library as fontawesomeIcons } from "@fortawesome/fontawesome-svg-core";
import Perm, { isAuthorized } from "./helpers/Permissions";
import UserLayersPermissions from "./components/pages/Settings/Users/UserLayersPermissions";
import GPXDriveVideoPlayer from "./components/pages/GPXDriveVideoPlayer/GPXDriveVideoPlayer";

import {
  faBars,
  faHome,
  faLayerGroup,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faMapMarked,
  faMap,
  faMapSigns,
  faBell,
  faEnvelope,
  faSearch,
  faTrafficLight,
  faRoad,
  faGlobeAsia,
  faBuilding,
  faCarCrash,
  faLightbulb,
  faCogs,
  faTimes,
  faDirections,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faAngleUp,
  faVectorSquare,
  faSpinner,
  faRuler,
  faEdit,
  faKey,
  faAt,
  faUserPlus,
  faPlus,
  faCheck,
  faSquareFull,
  faCircle,
  faTrashAlt,
  faChartPie,
  faVideo,
  faRulerHorizontal,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import SearchAndAnalyze from "./components/pages/SearchAndAnalyze/SearchAndAnalyze";
import IrbidLandUseReport from "./components/pages/PrintLandUse/IrbidLandUseReport/IrbidLandUseReport";
import Constants from "./helpers/Constants";
export default function App() {
  const displayName = App.name;
  resources.setLanguage("ar");

  fontawesomeIcons.add(
    faBars,
    faHome,
    faLightbulb,
    faLayerGroup,
    faBell,
    faEnvelope,
    faSearch,
    faMapMarkerAlt,
    faTrafficLight,
    faRoad,
    faGlobeAsia,
    faBuilding,
    faCarCrash,
    faCogs,
    faTimes,
    faMapMarkedAlt,
    faMapMarked,
    faMap,
    faMapSigns,
    faDirections,
    faAngleDown,
    faAngleRight,
    faAngleLeft,
    faAngleUp,
    faVectorSquare,
    faSpinner,
    faRuler,
    faEdit,
    faKey,
    faAt,
    faUserPlus,
    faPlus,
    faCheck,
    faSquareFull,
    faCircle,
    faTrashAlt,
    faChartPie,
    faVideo,
    faRulerHorizontal,
    faSyncAlt
  );

  const { authenticated } = useSelector((s) => s.users);
  return !authenticated ? (
    <Route component={LoginPage} />
  ) : (
    <Switch>
      {/* <Route path="/LanduseReport" component={IrbidLandUseReport} exact /> */}
      <Route
        path="/LanduseReport"
        component={
          Constants.geoserverWorkspace == "moma_irbid_postgis"
            ? IrbidLandUseReport
            : LanduseReport
        }
        exact
      />

      <Route path="/Login" component={LoginPage} exact />
      <Layout>
        <Switch>
          <Route
            exact
            path="/"
            component={
              isAuthorized(Perm.SearchLayers)
                ? MapPage
                : isAuthorized(Perm.PrintLanduseReport)
                ? PrintLandUse
                : Settings
            }
          />
          {isAuthorized(Perm.SearchLayers) && (
            <Route path="/map-search" component={MapPage} />
          )}
          {isAuthorized(Perm.SearchLayers) && (
            <Route path="/search-analyze" component={SearchAndAnalyze} />
          )}
          {isAuthorized(Perm.PrintLanduseReport) && (
            <Route path="/PrintLandUse" component={PrintLandUse} />
          )}
          {isAuthorized(Perm.ChangePassword) && (
            <Route
              path="/Settings/Users/ChangePassword"
              component={ChangePassword}
            />
          )}
          {isAuthorized(Perm.AddUser, Perm.EditUser) && (
            <Route path="/Settings/Users/ManageUser" component={RegisterPage} />
          )}
          {isAuthorized(Perm.EditUserVillagesPermission) && (
            <Route
              path="/Settings/Users/UserLayersPermissions"
              component={UserLayersPermissions}
            />
          )}
          {isAuthorized(Perm.ViewUsers) && (
            <Route path="/Settings/Users" component={UsersView} />
          )}
          {isAuthorized(Perm.AssignLayerUsers) && (
            <Route path="/Settings/layers/layerUsers" component={LayerUsers} />
          )}
          {isAuthorized(Perm.AddLayer, Perm.EditLayer) && (
            <Route
              path="/Settings/layers/ManageLayer"
              component={ManageLayer}
            />
          )}
          {isAuthorized(Perm.ViewLayers) && (
            <Route path="/Settings/layers" component={LayersView} />
          )}
          {isAuthorized(Perm.AddGroup, Perm.EditGroup) && (
            <Route
              path="/Settings/Permissions/ManageGroupPermissions"
              component={ManageGroupPermissions}
            />
          )}
          {isAuthorized(Perm.ViewGroups) && (
            <Route path="/Settings/permissions" component={GroupsView} />
          )}
          {isAuthorized(Perm.Settings) && (
            <Route path="/settings" component={Settings} />
          )}
          
            <Route path="/StreetDriveVideo" component={GPXDriveVideoPlayer} />
          
          <Route component={NotFound404} />
        </Switch>
      </Layout>
    </Switch>
  );
}
