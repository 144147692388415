import React from "react";
import BaseMenu from "./BaseSearchMenu";
import { resources } from "../../../../assets/LocalizationResources";
import LayersNames from "../../../../helpers/LayersNames";

export default function MunPropertyMenu({ map, onFeatureSelected }) {
  return (
    <BaseMenu
      layerName={LayersNames.property}
      filterField="dls_key"
      idField="id"
      labelField="dls_key"
      searchFieldLabel="أي جزء من ال DLS Key"
      icon="search"
      map={map}
      onFeatureSelected={onFeatureSelected}
      layerFields="geometry_spa,id,hod_aname,district_n,rules,ownership,area,dls_key,parcels_id,sheet_no,numbering,hod_code"
      infoFields={[
        {
          field: "rules",
          label: "الأحكام",
          icon: "building",
          valueExp: (val) => val || resources.misc.noData,
        },
        {
          field: "ownership",
          label: "الملكية",
          icon: "home",
          valueExp: (val) => val || resources.misc.noData,
        },
        {
          field: "area",
          label: "المساحة",
          icon: "vector-square",
          postLabel: <span>م&#178;</span>,
          valueExp: (val) => {
            if (!val) return resources.misc.noData;
            else return parseFloat(val).toFixed(3);
          },
        },
        {
          field: "hod_aname",
          label: "الحوض",
          icon: "directions",
          valueExp: (val) => val || resources.misc.noData,
        },
        {
          field: "district_n",
          label: "المنطقة",
          icon: "map-signs",
          valueExp: (val) => val || resources.misc.noData,
        },
      ]}
    />
  );
}
