import React from "react";
import GeneralData from "./GeneralData";
import RulesData from "./RulesData.js";
import PropTypes from "prop-types";

export default function ReportDeatails({ data }) {
  return (
    <div style={styles.container}>
      <div style={styles.subcontainerRight}>
        <GeneralData data={data} />
      </div>
      <div style={styles.subcontainerLeft}>
        <RulesData dlskey={data.dlskey} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    margin: "5px 5px 5px 5px",
    border: "1px solid black"
  },
  subcontainerRight: {
    display: "inline-block",
    width: "50%",
    padding: "5px"
  },
  subcontainerLeft: {
    width: "50%",
    borderRight: "1px solid black",
    padding: "5px"
  }
};

ReportDeatails.propTypes = {
  data: PropTypes.object
};
