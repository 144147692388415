import React, { useState } from "react";
import SettingsPageWrapper from "../SettingsPageWrapper";
import { resources } from "../../../../assets/LocalizationResources";
import PasswordForm from "./PasswordForm";
import { Button, Form, Label } from "reactstrap";
import { useHistory } from "react-router-dom";
import authApi, { isSuccessfulRequest } from "../../../../api/authApi";

export default function ChangePassword({ location }) {
  const [confirmPasswordError, setconfirmPasswordError] = useState(false);
  const [user, setUser] = useState({});
  const router = useHistory();

  const handleSubmit = async event => {
    event.preventDefault();
    if (user.Password != user.ConfirmPassword) {
      setconfirmPasswordError(true);
      return;
    }
    const response = await authApi.changePassword({ ID: location.state.ID, Password: user.Password });
    if (isSuccessfulRequest(response)) router.goBack();
  };

  const handleInputChanges = e => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
    if ([e.target.name] == "ConfirmPassword") setconfirmPasswordError(false);
  };

  return (
    <div>
      <SettingsPageWrapper title={resources.settingsResources.users.changePassword}>
        <Form onSubmit={handleSubmit} style={styles.form}>
          <Label style={styles.lbl}>
            {resources.user.name} : {location.state.Name}
          </Label>
          <PasswordForm handleInputChanges={handleInputChanges} confirmPasswordError={confirmPasswordError} />
          <Button type="submit" color="primary" style={styles.formBtn}>
            {resources.misc.save}
          </Button>
          <Button type="button" color="danger" onClick={() => router.goBack()} style={styles.formBtn}>
            {resources.misc.cancel}
          </Button>
        </Form>
      </SettingsPageWrapper>
    </div>
  );
}

const styles = {
  form: {
    maxWidth: "400px"
  },
  formBtn: {
    marginTop: 20,
    marginLeft: 10
  },
  lbl: {
    marginBottom: "30px"
  }
};
