import React from "react";
import jordanlogo from "../../../../assets/images/jordanlogo.PNG";
import PropTypes from "prop-types";

export default function ReportHeader({ villageName }) {
  const getRowData = ({ label = "", fieled = "" }) => {
    return (
      <tr>
        <td style={styles.td}>
          <label style={styles.sublabel2}>{label}</label>
        </td>
        <td style={styles.td}>
          <label style={styles.sublabel2}>{fieled}</label>
        </td>
      </tr>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.subcontainerRight}>
        <img style={styles.logoimg} src={jordanlogo} alt="" />
        <label style={styles.boldlabel}> المملكة الاردنية الهاشمية</label>
        <label style={styles.sublabel2}>بلدية أربد الكبرى </label>
      </div>
      <div style={styles.subcontainerCenter}>
        <label style={styles.boldlabel}>مخطط موقع تنظيمي</label>
        <label style={styles.boldlabel}>المنطقة: {villageName ? villageName : ""}</label>
      </div>
      <div style={styles.subcontainerLeft}>
        <table style={styles.table}>
          <tbody>
            {getRowData({ label: "رقم الصادر / الوارد" })}
            {getRowData({ label: "رقم الوصل " })}
            {getRowData({ label: "التاريخ : السنه/اليوم/الشهر", fieled: `${new Date().toLocaleTimeString()}` })}
            {getRowData({ label: "الوقت", fieled: `${new Date().toLocaleDateString()}` })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    height: "120px",
    margin: "20px 5px 15px 5px",
    border: "1px solid black"
  },
  logoimg: {
    height: "60%",
    objectFit: "contain"
  },
  subcontainerRight: {
    display: "inline-block",
    textAlign: "center",
    width: "25%",
    height: "100%"
  },
  subcontainerCenter: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "25%",
    height: "100%"
  },
  subcontainerLeft: {
    display: "block",
    width: "50%",
    height: "100%",
    borderRight: "1px solid black",
    padding: "5px",
    textAlign: "right"
  },
  boldlabel: {
    fontSize: "13px",
    fontWeight: "bold",
    display: "block"
  },
  sublabel2: {
    fontSize: "12px",
    fontWeight: "bold",
    margin: "0px",
    display: "block"
  },
  table: {
    height: "100%",
    width: "100%",
    borderCollapse: "separate",
    border: "1px solid black"
  },
  td: {
    border: "1px solid black",
    height: "24%",
    width: "49%"
  }
};

ReportHeader.propTypes = {
  villageName: PropTypes.string
};
