import LocalizedStrings from "react-localization";

export let resources = new LocalizedStrings({
  ar: {
    home: "الرئيسية",
    map: "الخريطة",
    layers: "الطبقات",
    landmarks: "المعالم",
    mapSearch: "بحث في الطبقات",
    analyzeData: "تحليل البيانات",
    settings: "الإعدادات",
    projectTitle: "بلدية أربد الكبرى",
    notification: "التنبيهات",
    messages: "الرسائل",
    search: "بحث عن",
    coordinates: "إحداثيات",
    mapMenu: {
      trafficLights: "إشارات المرور",
      lighting: "الاضاءة",
      roads: "الشوارع",
      buildings: "المباني",
      accidents: "الحوادث",
      internationalRoads: "الطرق الدولية",
      basins: "الأحواض",
      villages: "القرى",
      parcels: "قطع الاراضي",
      landuse: "استعمالات الاراضي",
      districts: "مناطق إدارية",
      landmarks: "المعالم",
      mun_property: "املاك البلدية",
      sample_permit: "رخص المهن",
    },
    mapPage: {
      mapOptionSelectPrompt: "يرجى اختيار احد التصنيفات من القائمة اعلاه",
      baseMapLayers: "الطبقات الأساسية",
      userMapLayers: "طبقات المستخدم",
      layers: "الطبقات",
    },
    misc: {
      noNameNum: "الأسم او الرقم غير مدخل",
      noData: "غير مدخل",
      pleaseWait: "يرجى الانتظار",
      showNearbyLands: "عرض القطع المجاورة",
      invalidField: "ادخال غير صحيح",
      close: "إغلاق",
      save: "حفظ",
      cancel: "إالغاء",
      login: "تسجيل دخول",
      logout: "تسجيل خروح",
    },
    LandUse: {
      printLandUse: "طباعة المخطط التفصيلي",
      LandUse: " المخطط التفصيلي",
      Village: "القرية",
      Basin: "الحوض",
      Sector: "الحي",
      Parcel: "رقم القطعة",
      country: "الدولة",
      governorates: "المحافظة",
      municipality: "البلدية",
      district: "المنطقة",
      sheet: "رقم اللوحة",
    },
    mapMeasurements: {
      measurementLayerGroup: "القياسات",
      measurementLayer: "القياسات",
      startDrawing: "انقر لبدء الرسم",
      continuePolygon: "انقر لاكمال رسم المضلع",
      continueLineString: "انقر لاكمال رسم الخط",
      m: "م",
      km: "كم",
      calcArea: "حساب المساحة",
      calcLength: "حساب الطول",
      clearMeasures: "ازالة الحسابات",
    },
    user: {
      name: "اسم المستخدم",
      email: "البريد الالكتروني",
      phone: "الهاتف",
      permissions: "الصلاحيات",
      password: "كلمة المرور",
      confirmPassword: "تأكيد كلمة المرور",
      passwordRegex: "يجب ان تحتوي كلمة المرور على حرف كبير وحرف صغير ورقم ولا تقل عن 8 خانات",
      invalidLogin: "تسجيل دخول غير صحيح",
      confirmPasswordError: "كلمة السر غير متطابقة",
    },
    settingsResources: {
      layers: {
        layers: "طبقات الخريطة",
        addUsers: "إضافة مستخدمين للطبقة",
        editLayer: "تعديل الطبقة",
        addLayer: "إضافة طبقة جديدة",
        layerName: "اسم الطبقة",
        layerUrl: "رابط الطبقة - Geoserver URL",
      },
      users: {
        addUser: "إضافة مستخدم جديد",
        editUser: "تعديل المستخدم ",
        deleteuser: "حذف المستخدم",
        changePassword: "تغيير الرقم السري",
        users: "المستخدمين",
        usersPermissions: "صلاحيات المستخدم",
        selectusersPermissions: "اختر مجموعات الصلاحيات",
        usergroupsError: "اختر على الأقل مجموعة صلاحيات",
        deleteuserConfirmMessage: "هل انت متأكد من حذف المستخدم ؟",
        layerpermission: " صلاحيات الطبقات",
      },
      permissions: {
        permissions: "الصلاحيات",
        groupName: "اسم مجموعة الصلاحيات",
        addGroupPermissions: "إضافة مجموعة صلاحيات",
        editGroupPermissions: "تعديل  مجموعة الصلاحيات",
        groupPermissions: "مجموعات الصلاحيات",
        deletGroupPermissions: "حذف مجموعة الصلاحيات",
        deletGroupPermissionsMessage: "هل أنت متأكد من حذف مجموعة الصلاحيات",
      },
      general: {
        createdBy: "المنشئ",
        createdDate: "تاريخ الإنشاء",
        delete: "حذف",
      },
    },
    analyze: {
      dynamicFilterTitle: "بحث متقدم",
      dynamicFilterPlaceholder: "اضافة حقل بحث",
    },
    copyrights: "جميع الحقوق محفوظة ® 2020 الشركة العامة للحاسبات والالكترونيات",
  },
  en: {
    home: "Home",
    map: "Map",
    landmarks: "Landmarks",
    mapSearch: "Search Map Layers",
    analyzeData: "Analyze Data",
    layers: "Layers",
    settings: "Settings",
    projectTitle: "Greater Irbid Municipality",
    notification: "Notifications",
    messages: "Messages",
    search: "Search for",
    coordinates: "Coordinates",
    mapMenu: {
      trafficLights: "Traffic Lights",
      lighting: "Lighting",
      roads: "Roads",
      buildings: "Buildings",
      accidents: "Accidents",
      internationalRoads: "International Roads",
      basins: "Basins",
      villages: "Villages",
      parcels: "Parcels",
      landuse: "Landuse",
      districts: "Districts",
      landmarks: "Landmarks",
      mun_property: "Municipality Property",
      sample_permit: "Permits",
    },
    mapPage: {
      mapOptionSelectPrompt: "Please pick an item from the options menu",
      baseMapLayers: "Base Maps",
      userMapLayers: "User Layers",
      layers: "Layers",
    },
    misc: {
      noNameNum: "Name/Number unavailable",
      noData: "N/A",
      pleaseWait: "Please Wait",
      showNearbyLands: "Show Nearby Lands",
      invalidField: "This field is invalid",
      close: "Close",
      save: "Save",
      cancel: "Cancel",
      login: "Login",
      logout: "Logout",
    },
    LandUse: {
      printLandUse: "printLandUse",
      LandUse: "LandUse",
      Village: "Village",
      Basin: "Basin",
      Sector: "Sector",
      Parcel: "Parcel",
      country: "country",
      governorates: "governorates",
      municipality: "municipality",
      district: "district",
      sheet: "sheet",
    },
    mapMeasurements: {
      measurementLayerGroup: "Measurments",
      measurementLayer: "Measurments",
      startDrawing: "Click to start drawing",
      continuePolygon: "Click to continue drawing the polygon",
      continueLineString: "Click to continue drawing the line",
      m: "m",
      km: "km",
      calcArea: "Calculate Area",
      calcLength: "Calculate Length",
      clearMeasures: "Clear Measures",
    },
    user: {
      name: "Name",
      email: "Email",
      phone: "Phone",
      permissions: "Permissions",
      password: "Password",
      confirmPassword: "Confirm Password",
      passwordRegex:
        "Password must contain at least one capital letter, one small letter and a number with 8 characters length",
      invalidLogin: "Invalid Login Credentials",
      confirmPasswordError: "Passwords not match",
    },
    settingsResources: {
      layers: {
        layers: "Map Layers",
        addUsers: "Assign User Layers",
        editLayer: "Edit layer",
        deleteuser: "Delete user",
        addLayer: "Add New Layer",
        layerName: "Layer name",
        layerUrl: "Layer Geoserver Url",
      },
      users: {
        users: "Users ",
        addUser: "Add New User",
        editUser: "Edit user",
        deleteuser: "Delete user",
        changePassword: "Change password",
        usersPermissions: "Users permissions",
        selectusersPermissions: "Select users permissions",
        usergroupsError: "Select at least one group",
        deleteuserConfirmMessage: "Are you sure you want to delete the selected user",
      },
      permissions: {
        permissions: "Permissions",
        groupName: "Group Name",
        addGroupPermissions: "Add group permissions",
        editGroupPermissions: "Edit group permissions",
        groupPermissions: "Groups permissions",
        deletGroupPermissions: "Delet group permissions",
        deletGroupPermissionsMessage: "Are you sure you want to delete the group permissions",
      },
      general: {
        createdBy: "created By",
        createdDate: "created Date",
        delete: "delete",
      },
    },
    analyze: {
      dynamicFilterTitle: "Advanced Search",
      dynamicFilterPlaceholder: "Add Search Field",
    },
    gpxStreetDrivePlayer: {
      StreetDrivePlayer: "فيديو الطرق",
    },
    copyrights: "Copyright © 2020 General Computers and Electronics (GCE)",
  },
});
