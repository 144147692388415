import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GeoServerApi } from "../../../../api/geoserverController";
import LayersNames from "../../../../helpers/LayersNames";

export default function ReportSpecialRules({ dlskey }) {
  const [rules, setrules] = useState(null);

  const getSpecialRules = async () => {
    const response = await GeoServerApi.getLayer({
      layer: LayersNames.specialRules,
      fields: "",
      viewparams: "dlskey:" + dlskey,
    }).catch((e) => {
      console.log(e);
    });
    if (response && response.data && response.data.features) {
      if (response.data.features.length) {
        let data = [];
        response.data.features.forEach((feature) => {
          data.push({
            MINOR_ZONING_NAME: feature.properties["MINOR_ZONING_NAME"],
            RULE: feature.properties["RULE_TXT"],
          });
        });
        setrules(
          data.reduce((r, a) => {
            r[a["MINOR_ZONING_NAME"]] = [...(r[a["MINOR_ZONING_NAME"]] || []), a];
            return r;
          }, {})
        );
      }
    }
  };

  useEffect(() => {
    if (dlskey) getSpecialRules();
  }, []);

  const drawsubRules = (key) => {
    return rules[key].map((row, idx) => {
      return (
        <label key={idx} style={styles.sublabel}>
          {row["RULE"]}
        </label>
      );
    });
  };
  const drawRules = (key, idx) => {
    return (
      <div key={idx}>
        <label style={styles.mainlbl}>-{key}</label>
        {drawsubRules(key)}
      </div>
    );
  };

  return rules && rules.length > 0 ? (
    <div style={styles.container}>
      <label style={styles.label}>احكام خاصة:</label>
      <div style={styles.subcontainer}>{Object.keys(rules).map((key, idx) => drawRules(key, idx))}</div>
    </div>
  ) : null;
}

const styles = {
  container: {
    margin: "20px 5px 0px 5px",
    border: "1px solid black",
    fontWeight: "bold",
    display: "block",
  },
  subcontainer: {
    border: "1px solid black",
    margin: 5,
  },
  mainlbl: {
    fontSize: "11px",
    display: "block",
    marginRight: 5,
  },
  label: {
    fontSize: "13px",
    display: "block",
    marginRight: 5,
  },
  sublabel: {
    fontSize: "9px",
    display: "block",
    marginRight: 5,
  },
};

ReportSpecialRules.propTypes = {
  dlskey: PropTypes.string,
};
