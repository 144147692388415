import React, { useState, useEffect, useRef } from "react";
import Map from "../../../Map/Map";
import { GeoServerApi } from "../../../../api/geoserverController";
import {
  AddgeoserverLayerToMap,
  getCenterAndZoomFeature,
} from "../../../../helpers/MapHelper";
import useDebounce from "../../../../helpers/useDebounce";
import FormInput from "../../../ui-components/FormInput";
import PropTypes from "prop-types";
import LayersNames from "../../../../helpers/LayersNames";
import MapReportLegend from "./MapReportLegend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ReportMaps({ dlskey, withApartment }) {
  const parcelLayer = LayersNames.parcels;
  const landUseParcelStreetLayer = LayersNames.landuseReportBaseLayer;
  const selectedLandUseLayer = LayersNames.landUse;
  const [isLoadingLandUse, setIsLoadingLandUse] = useState(false);
  const [landUseScale, setLandUseScale] = useState("2500");
  const [selectedLegend, setSelectedLegend] = useState([]);
  const landUseMap = useRef(null);
  const [rotation, setRotation] = useState(0);

  const loadMap = async (scale) => {
    const response = await GeoServerApi.getLayer({
      layer: parcelLayer,
      max: "100",
      fields: "geometry_spa,dls_key",
      cqlFilter: `dls_key='${dlskey}'`,
    }).catch((e) => {
      console.log(e);
    });

    if (response && response.data && response.data.features) {
      if (response.data.features.length) {
        setIsLoadingLandUse(true);
        getCenterAndZoomFeature({
          data: response.data,
          map: landUseMap.current.map,
          scale: scale,
        });
        const secondaryLandUseNumbers = await GeoServerApi.getLayer({
          layer: selectedLandUseLayer,
          fields: "secondary_landuse_no",
          cqlFilter: `dls_key='${dlskey}'`,
        }).catch((e) => {
          console.log(e);
        });
        if (
          secondaryLandUseNumbers &&
          secondaryLandUseNumbers.data &&
          secondaryLandUseNumbers.data.features
        ) {
          if (secondaryLandUseNumbers.data.features.length) {
            let selected = [];
            secondaryLandUseNumbers.data.features.forEach((f) => {
              if (selected.indexOf(f.properties.secondary_landuse_no) < 0)
                selected.push(f.properties.secondary_landuse_no);
            });
            setSelectedLegend(selected);
          }
        }
        AddgeoserverLayerToMap(
          landUseMap.current.map,
          selectedLandUseLayer,
          false,
          `dls_key='${dlskey}'`
        );

        setTimeout(() => {
          setIsLoadingLandUse(false);
        }, 1000);
      }
    }
  };
  //const scaleSelect = debounce(1000, loadMap);
  const debouncedSearch = useDebounce(landUseScale, 1000);

  useEffect(() => {
    if (landUseMap && landUseMap.current && landUseMap.current.map) {
      AddgeoserverLayerToMap(landUseMap.current.map, landUseParcelStreetLayer);
    }
  }, []);

  const onScalechange = (event) => {
    if (!event.target.value) {
      return;
    }
    setLandUseScale(event.target.value);
    // scaleSelect(event.target.value);
  };

  useEffect(() => {
    if (Boolean(landUseScale) && dlskey) {
      loadMap(landUseScale);
    }
  }, [debouncedSearch]);

  const rotateMap = () => {
    if (landUseMap.current) {
      landUseMap.current.map.getView().setRotation(rotation);
      setRotation(rotation + 5);
    }
  };

  return (
    <>
      <div className="irbid_landuse_Report_map_contain">
        <div className="irbid_landuse_Report_container">
          <label style={{ marginTop: "0.25rem", color: "#353535" }}>
            مقياس الرسم 1/
          </label>{" "}
          <FormInput
            value={landUseScale}
            placeholder="المقياس"
            containerStyle={{ width: landUseScale.length + 80 + "px" }}
            icon={isLoadingLandUse ? "spinner" : ""}
            onChange={(e) => onScalechange(e, "landuse")}
          />
        </div>
        <div
          className={
            withApartment == "true"
              ? "irbid_landuse_Report_map-withApart"
              : "irbid_landuse_Report_map"
          }
        >
          <Map
            ref={landUseMap}
            isEmpty={true}
            withRotation={true}
            rotation={rotation}
          />
          <div>
            <MapReportLegend selected={selectedLegend} />
          </div>
          <label className="irbid_landuse_Report_label">اتجاه الشمال</label>
          <button
            className="irbid_landuse_Report_rotate"
            onClick={() => rotateMap()}
          >
            <FontAwesomeIcon icon="sync-alt" />
          </button>
        </div>
      </div>
    </>
  );
}

ReportMaps.propTypes = {
  dlskey: PropTypes.string,
};
