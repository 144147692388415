import { usersActions } from "../actions";

const initialState = {
	authenticated: false,
	ID: null,
	Name: "غير مسجل",
	Email: null,
	Phone: null,
	ImagePath: null,
	permissions: [],
	loginAttempt: 0,
	token: null,
	error: null
};

/**
 * @param {{ type: string; payload: any; }} action
 */
const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case usersActions.LOGIN_REQUEST_SUCCESS:
			const payload = { ...action.payload, error: null, loginAttempt: 0, authenticated: true };
			sessionStorage.setItem("user", JSON.stringify(payload));
			return payload;
		case usersActions.LOGOUT_REQUEST_SUCCESS:
			sessionStorage.removeItem("user");
			return initialState;
		case usersActions.LOGIN_REQUEST_FAILED:
			return { ...state, error: null, loginAttempt: state.loginAttempt + 1, authenticated: false };
		case usersActions.CONNECTION_ERROR:
			return { ...state, error: action.payload, authenticated: false };
		default:
			return state;
	}
};

export default userReducer;
