import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Settings.css";
import { resources } from "../../../assets/LocalizationResources";
import { useSelector } from "react-redux";
import Perm, { isAuthorized } from "../../../helpers/Permissions";

export default function Settings() {
  const router = useHistory();
  const [pages, setpages] = useState([]);
  const { permissions } = useSelector(s => s.users);

  useEffect(() => {
    let pages = [];
    if (isAuthorized(Perm.ViewLayers))
      pages.push({
        key: 1,
        title: resources.settingsResources.layers.layers,
        icon: "home",
        path: "/Settings/Layers"
      });
    if (isAuthorized(Perm.ViewGroups))
      pages.push({
        key: 4,
        title: resources.settingsResources.permissions.permissions,
        icon: "directions",
        path: "/Settings/permissions"
      });
    if (isAuthorized(Perm.ViewUsers))
      pages.push({ key: 5, title: resources.settingsResources.users.users, icon: "ruler", path: "/Settings/Users" });
    setpages(pages);
  }, [permissions]);

  const handleClick = path => {
    router.push(path);
  };

  return (
    <div className="setting-container">
      <div className="setting-cards-container">
        {pages.map(page => (
          <div key={page.key} className="setting-card" onClick={() => handleClick(page.path)}>
            <FontAwesomeIcon icon={page.icon} />
            <p className="setting-card-title">{page.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
