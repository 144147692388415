import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer, Group as LayerGroup } from "ol/layer";
import { unByKey } from "ol/Observable";
import Overlay from "ol/Overlay";
import { getArea, getLength } from "ol/sphere";
import Draw from "ol/interaction/Draw";
import { LineString, Polygon } from "ol/geom";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { resources } from "../assets/LocalizationResources";
import Toggle from "ol-ext/control/Toggle";
import Button from "ol-ext/control/Button";

let map;
let sketch;
let draw;
let helpTooltipElement;
let helpTooltip;
let measureTooltipElement;
let measureTooltip;
let pointerMoveListener;
const measureTooltips = [];

let source = new VectorSource();

let pointerMoveHandler = function(evt) {
    if (evt.dragging) {
        return;
    }
    let helpMsg = resources.mapMeasurements.startDrawing;

    if (sketch) {
        let geom = sketch.getGeometry();
        if (geom instanceof Polygon) {
            helpMsg = resources.mapMeasurements.continuePolygon;
        } else if (geom instanceof LineString) {
            helpMsg = resources.mapMeasurements.continueLineString;
        }
    }

    helpTooltipElement.innerHTML = helpMsg;
    helpTooltip.setPosition(evt.coordinate);
    helpTooltipElement.classList.remove("hidden");
};

let formatLength = function(line) {
    let length = getLength(line);
    let output;
    if (length > 100) {
        output = Math.round((length / 1000) * 100) / 100 + " " + resources.mapMeasurements.km;
    } else {
        output = Math.round(length * 100) / 100 + " " + resources.mapMeasurements.m;
    }
    return output;
};

function createHelpTooltip() {
    if (helpTooltipElement) {
        helpTooltipElement.parentNode.removeChild(helpTooltipElement);
    }
    helpTooltipElement = document.createElement("div");
    helpTooltipElement.className = "ol-tooltip hidden";
    helpTooltip = new Overlay({
        element: helpTooltipElement,
        offset: [15, 0],
        positioning: "center-left"
    });
    map.addOverlay(helpTooltip);
}

function createMeasureTooltip() {
    if (measureTooltipElement) {
        measureTooltipElement.parentNode.removeChild(measureTooltipElement);
    }
    measureTooltipElement = document.createElement("div");
    measureTooltipElement.className = "ol-tooltip ol-tooltip-measure";
    measureTooltip = new Overlay({
        element: measureTooltipElement,
        offset: [0, -15],
        positioning: "bottom-center"
    });
    measureTooltips.push(measureTooltip);
    map.addOverlay(measureTooltip);
}

let formatArea = function(polygon) {
    let area = getArea(polygon);
    let output;
    if (area > 10000) {
        output = Math.round((area / 1000000) * 100) / 100 + " " + resources.mapMeasurements.km + "<sup>2</sup>";
    } else {
        output = Math.round(area * 100) / 100 + " " + resources.mapMeasurements.m + "<sup>2</sup>";
    }
    return output;
};

function addInteraction(type) {
    stopIntraction();
    pointerMoveListener = map.on("pointermove", pointerMoveHandler);
    draw = new Draw({
        source: source,
        type: type,
        style: new Style({
            fill: new Fill({
                color: "rgba(255, 255, 255, 0.2)"
            }),
            stroke: new Stroke({
                color: "rgba(0, 0, 0, 0.5)",
                lineDash: [10, 10],
                width: 2
            }),
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: "rgba(0, 0, 0, 0.7)"
                }),
                fill: new Fill({
                    color: "rgba(255, 255, 255, 0.2)"
                })
            })
        })
    });
    map.addInteraction(draw);

    createMeasureTooltip();
    createHelpTooltip();

    let listener;
    draw.on("drawstart", function(evt) {
        // set sketch
        sketch = evt.feature;

        let tooltipCoord = evt.coordinate;

        listener = sketch.getGeometry().on("change", function(evt) {
            let geom = evt.target;
            let output;
            if (geom instanceof Polygon) {
                output = formatArea(geom);
                tooltipCoord = geom.getInteriorPoint().getCoordinates();
            } else if (geom instanceof LineString) {
                output = formatLength(geom);
                tooltipCoord = geom.getLastCoordinate();
            }
            measureTooltipElement.innerHTML = output;
            measureTooltip.setPosition(tooltipCoord);
        });
    });

    draw.on("drawend", function() {
        measureTooltipElement.className = "ol-tooltip ol-tooltip-static";
        measureTooltip.setOffset([0, -7]);
        // unset sketch
        sketch = null;
        // unset tooltip so that a new one can be created
        measureTooltipElement = null;
        createMeasureTooltip();
        unByKey(listener);
    });
}

function stopIntraction() {
    unByKey(pointerMoveListener);
    if (draw) map.removeInteraction(draw);
    if (helpTooltip) map.removeOverlay(helpTooltip);
}

function removeIntraction() {
    source.clear();
    if (helpTooltip) map.removeOverlay(helpTooltip);
    unByKey(pointerMoveListener);
    if (draw) map.removeInteraction(draw);
    measureTooltips.forEach(tooltip => map.removeOverlay(tooltip));
    map.renderSync();
}

const addLayer = () => {
    let vector = new VectorLayer({
        source: source,
        title: resources.mapMeasurements.measurementLayer,
        zIndex: 301,
        style: new Style({
            fill: new Fill({
                color: "#6f42c111"
            }),
            stroke: new Stroke({
                color: "#6f42c1",
                width: 2
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: "#6f42c1"
                })
            })
        })
    });

    map.getLayers()
        .getArray()
        .push(
            new LayerGroup({
                title: resources.mapMeasurements.measurementLayerGroup,
                combine: false,
                layers: [vector]
            })
        );
};

export default function InitMeasurementTools(ext_map) {
    map = ext_map;
    addLayer();

    const toggleArea = new Toggle({
        html: "📐",
        className: "ol-area-ctrl",
        title: resources.mapMeasurements.calcArea,
        onToggle: function(active) {
            if (toggleLength.getActive()) toggleLength.toggle();
            if (active) addInteraction("Polygon");
            else stopIntraction();
        }
    });

    const toggleLength = new Toggle({
        html: "📏",
        className: "ol-length-ctrl",
        title: resources.mapMeasurements.calcLength,
        onToggle: function(active) {
            if (toggleArea.getActive()) toggleArea.toggle();
            if (active) addInteraction("LineString");
            else stopIntraction();
        }
    });

    const buttonClear = new Button({
        html: "✖",
        className: "ol-clear-ctrl",
        title: resources.mapMeasurements.clearMeasures,
        handleClick: function() {
            if (toggleArea.getActive()) toggleArea.toggle();
            if (toggleLength.getActive()) toggleLength.toggle();
            removeIntraction();
        }
    });

    map.addControl(toggleArea);
    map.addControl(toggleLength);
    map.addControl(buttonClear);

    return {
        // Type = "Polygon" || "LineString"
        addInteraction: addInteraction,
        removeInteraction: removeIntraction,
        stopIntraction: stopIntraction
    };
}
