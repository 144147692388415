import React from "react";
import "./Report.css";
import ReportMaps from "../LanduseReport/ReportMaps";
import ReportHeader from "./ReportHeader";
import ReportDeatails from "./ReportDetails/ReportDeatails";
import ReportNote from "./ReportNote";
import ReportFooter from "./ReportFooter";
import ReportSecondaryExtreRules from "./ReportSecondaryExtreRules";
import ReportSpecialRules from "./ReportSpecialRules";
import { useLocation } from "react-router-dom";
import qs from "qs";

export default function LanduseReport() {
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  document.body.style.width = "21cm";
  document.body.style.height = "29.7cm";
  document.body.style.float = "right";

  return (
    <div className="rtl rtl-text">
      <div className="landuse-report ">
        {/* <div className="landuse_Report_grid_style"></div> */}
        <table>
          <thead>
            <tr>
              <th>
                <ReportHeader villageName={query.vn} />
              </th>
            </tr>
          </thead>
          <tbody className="landuse-report-body">
            <tr>
              <td>
                <ReportDeatails
                  data={{
                    dlskey: query.dls,
                    villageName: query.vn,
                    hodeName: query.hn,
                    secondaryLanduseNo: query.sn,
                  }}
                />
                <ReportNote />
                <ReportSpecialRules dlskey={query.dls} />
                <ReportMaps dlskey={query.dls} />
                <ReportFooter />
                {query.sn === "50" && <ReportSecondaryExtreRules />}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
