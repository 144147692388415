import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IrbidLogo from "../../../../assets/images/logo.png";
import { GeoServerApi } from "./../../../../api/geoserverController";
import LayersNames from "./../../../../helpers/LayersNames";

export default function IrbidReportHeader({ districtName, dlsKey }) {
  const [districtAreaName, setDistrictAreaName] = useState("جاري التحميل");
  const getParcelData = async () => {
    const response = await GeoServerApi.getLayer({
      layer: LayersNames.landUse,
      max: 1,
      fields: "district_name",
      cqlFilter: `dls_key='${dlsKey}'`,
    }).catch((e) => {
      console.log(e);
    });
    setDistrictAreaName(response.data.features[0].properties["district_name"]);
  };

  useEffect(() => {
    getParcelData();
  }, []);
  return (
    <div className="irbid-report-header">
      <div className="container-right">
        <label style={{ fontSize: "12px" }}> المملكة الاردنية الهاشمية</label>
        <label
          style={{ fontSize: "30px", fontWeight: "bold", display: "block" }}
        >
          بلدية أربد الكبرى
        </label>
        <label className="container-left-lbl">المنطقة:{districtAreaName}</label>
      </div>
      <div className="container-center">
        <img className="logo-img" src={IrbidLogo} alt="" />
        <label className="container-center-bold-lbl"> مخطط موقع وترسيم </label>
      </div>
      <div className="container-left">
        <div className="container-left-div">
          <label className="container-left-lbl">رقم المخطط:</label>
          <input className="container-left-input" />
        </div>
        <div className="container-left-div">
          <label className="container-left-lbl">رقم الوصول :</label>
          <input className="container-left-input" />
        </div>
        <div className="container-left-div">
          <label className="container-left-lbl">التاريخ :</label>
          <input className="container-left-input" />
        </div>
      </div>
    </div>
  );
}
IrbidReportHeader.propTypes = {
  districtName: PropTypes.string,
};
