import React, { useEffect, useState } from "react";
import Table from "../../../ui-components/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { resources } from "../../../../assets/LocalizationResources";
import settingsApi, { isSuccessfulRequest } from "../../../../api/settingsApi";
import SettingsPageWrapper from "../SettingsPageWrapper";
import { Button } from "reactstrap";
import Modal from "../../../ui-components/Modal";

export default function GroupsView() {
  const router = useHistory();
  const [groups, setgroups] = useState([]);
  const [deleteModalIsOpen, setdeleteModalIsOpen] = useState(false);
  const [selectedID, setselectedID] = useState(null);
  const getAllGroups = async () => {
    const response = await settingsApi.groups();
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      setgroups(response.data);
    }
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  const getEditCell = e => {
    return (
      <span
        title={resources.settingsResources.permissions.editGroupPermissions}
        style={styles.icon}
        onClick={() => {
          router.push("/settings/permissions/ManageGroupPermissions", e.row.original);
        }}
      >
        <FontAwesomeIcon icon="edit" />
      </span>
    );
  };
  const getDeleteCell = e => {
    return (
      <span
        title={resources.settingsResources.permissions.deletGroupPermissions}
        style={styles.deleteicon}
        onClick={() => {
          setdeleteModalIsOpen(true);
          setselectedID(e.row.original.ID);
        }}
      >
        <FontAwesomeIcon icon="trash-alt" />
      </span>
    );
  };

  const columns = [
    { Header: resources.settingsResources.permissions.groupName, accessor: "Name" },
    { Header: resources.settingsResources.general.createdBy, accessor: "CreatedBy" },
    {
      Header: resources.settingsResources.general.createdDate,
      accessor: "CreatedDate",
      Cell: ({ cell }) => new Date(cell.value).toLocaleString()
    },
    {
      id: "edit",
      filterable: false,
      Cell: getEditCell
    },
    {
      id: "delete",
      filterable: false,
      Cell: getDeleteCell
    }
  ];

  const OnDeleteGroupPermisions = async () => {
    const response = await settingsApi.deleteGroupPermissions({ groupID: selectedID });
    if (isSuccessfulRequest(response)) {
      setdeleteModalIsOpen(false);
      getAllGroups();
    }
  };

  return (
    <SettingsPageWrapper title={resources.settingsResources.permissions.groupPermissions}>
      <Button
        style={styles.btn}
        color="default"
        onClick={() => router.push("/settings/permissions/ManageGroupPermissions")}
      >
        <span style={styles.icon}>
          <FontAwesomeIcon icon="plus" /> {resources.settingsResources.permissions.addGroupPermissions}
        </span>
      </Button>
      <Table columns={columns} data={groups} withPagination />
      {deleteModalIsOpen && (
        <Modal
          header={resources.settingsResources.permissions.deletGroupPermissions}
          content={resources.settingsResources.permissions.deletGroupPermissionsMessage}
          footer={
            <Button color="danger" onClick={() => OnDeleteGroupPermisions()}>
              {resources.settingsResources.permissions.deletGroupPermissions}
            </Button>
          }
          dismissLabel={resources.misc.cancel}
          onDismiss={() => setdeleteModalIsOpen(false)}
        />
      )}
    </SettingsPageWrapper>
  );
}

const styles = {
  icon: {
    fontSize: "16px",
    color: "#1f3559",
    cursor: "pointer"
  },
  deleteicon: {
    fontSize: "16px",
    color: "#e74033",
    cursor: "pointer"
  },
  btn: {
    marginRight: 10,
    border: "1px solid #ced4da",
    paddingBottom: 10
  }
};
