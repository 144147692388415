import React, { useEffect, useState } from "react";
import Table from "../../../ui-components/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { resources } from "../../../../assets/LocalizationResources";
import settingsApi, { isSuccessfulRequest } from "../../../../api/settingsApi";
import SettingsPageWrapper from "../SettingsPageWrapper";
import { Button } from "reactstrap";
import Modal from "../../../ui-components/Modal";
import authApi from "../../../../api/authApi";
import Perm, { isAuthorized } from "../../../../helpers/Permissions";
export default function UsersView() {
  const router = useHistory();
  const [users, setusers] = useState([]);
  const [selectedID, setselectedID] = useState(null);
  const [deleteModalIsOpen, setdeleteModalIsOpen] = useState(false);

  const getAllUsers = async () => {
    const response = await settingsApi.users();
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      setusers(response.data);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const getCell = (e, type, icon) => {
    return (
      <span
        title={
          type == "password"
            ? resources.settingsResources.users.changePassword
            : type === "delete"
            ? resources.settingsResources.users.deleteuser
            : type === "edit"
            ? resources.settingsResources.users.deleteuser
            : type === "layerpermission"
            ? resources.settingsResources.users.layerpermission
            : ""
        }
        style={type === "delete" ? { ...styles.icon, color: "red" } : styles.icon}
        onClick={() => {
          setselectedID(e.row.original.ID);
          switch (type) {
            case "password":
              router.push("/Settings/Users/ChangePassword", e.row.original);
              break;
            case "delete":
              setdeleteModalIsOpen(true);
              setselectedID(e.row.original.ID);
              break;
            case "edit":
              router.push("/Settings/Users/ManageUser", e.row.original.ID);
              break;
            case "layerpermission":
              router.push("/Settings/Users/UserLayersPermissions", e.row.original);
              break;
          }
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </span>
    );
  };

  const columns = [
    { Header: resources.user.name, accessor: "Name" },
    { Header: resources.user.phone, accessor: "Phone" },
    { Header: resources.user.email, accessor: "Email" },
  ];
  if (isAuthorized(Perm.EditUserVillagesPermission))
    columns.push({
      id: "layerpermission",
      filterable: false,
      Cell: (e) => getCell(e, "layerpermission", "layer-group"),
    });
  if (isAuthorized(Perm.ChangePassword))
    columns.push({
      id: "password",
      filterable: false,
      Cell: (e) => getCell(e, "password", "key"),
    });
  if (isAuthorized(Perm.EditUser))
    columns.push({
      id: "edit",
      filterable: false,
      Cell: (e) => getCell(e, "edit", "edit"),
    });
  if (isAuthorized(Perm.DeleteUser))
    columns.push({
      id: "delete",
      filterable: false,
      Cell: (e) => getCell(e, "delete", "trash-alt"),
    });
  useEffect(() => {
    console.log(selectedID);
  }, [selectedID]);
  const OnDeleteUser = async () => {
    const response = await authApi.deleteuser({ ID: selectedID });
    if (isSuccessfulRequest(response)) {
      setdeleteModalIsOpen(false);
      getAllUsers();
    }
  };

  return (
    <SettingsPageWrapper title={resources.settingsResources.users.users}>
      {isAuthorized(Perm.AddUser) && (
        <Button style={styles.btn} color="default" onClick={() => router.push("/Settings/Users/ManageUser")}>
          <span style={styles.icon}>
            <FontAwesomeIcon icon="user-plus" /> {resources.settingsResources.users.addUser}
          </span>
        </Button>
      )}
      <Table columns={columns} data={users} withPagination />
      {deleteModalIsOpen && (
        <Modal
          header={resources.settingsResources.users.deleteuser}
          content={resources.settingsResources.users.deleteuserConfirmMessage}
          footer={
            <Button color="danger" onClick={() => OnDeleteUser()}>
              {resources.settingsResources.general.delete}
            </Button>
          }
          dismissLabel={resources.misc.cancel}
          onDismiss={() => setdeleteModalIsOpen(false)}
        />
      )}
    </SettingsPageWrapper>
  );
}

const styles = {
  icon: {
    fontSize: "16px",
    color: "#1f3559",
    cursor: "pointer",
  },
  btn: {
    marginRight: 10,
    border: "1px solid #ced4da",
    paddingBottom: 10,
  },
};
