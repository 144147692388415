import React, { useState, useEffect } from "react";
import { Button, Label, Input, FormGroup, Form } from "reactstrap";
import SettingsPageWrapper from "../SettingsPageWrapper";
import { useHistory } from "react-router-dom";
import { resources } from "../../../../assets/LocalizationResources";

export default function ManageLayer(props) {
  const router = useHistory();

  const [data, setData] = useState({
    ID: null,
    Url: "",
    Name: "",
  });

  useEffect(() => {
    const state = props.location.state;
    if (state && state.ID && state.Name && state.Url) {
      setData(state);
    }
  }, []);

  const SaveLayer = async () => {
    //ToDo
  };

  const isValid = () => {
    //ToDo Validate layer
    return true;
  };

  const handleInputChanges = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid()) SaveLayer();
  };

  return (
    <SettingsPageWrapper
      title={
        data.ID
          ? resources.settingsResources.layers.editLayer
          : resources.settingsResources.layers.addLayer
      }
    >
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label style={styles.Label}>
            {resources.settingsResources.layers.layerName}{" "}
          </Label>
          <Input
            style={styles.input}
            type="text"
            required
            name="Name"
            onChange={handleInputChanges}
            value={data.Name}
            valid={true}
            invalid={false}
          />
        </FormGroup>
        <FormGroup>
          <Label style={styles.Label}>
            {resources.settingsResources.layers.layerUrl}
          </Label>
          <Input
            style={styles.input}
            type="text"
            required
            name="Url"
            onChange={handleInputChanges}
            value={data.Url}
            invalid={true}
            valid={false}
          />
        </FormGroup>
        <Button color="info" type="submit">
          حفظ
        </Button>
      </Form>
    </SettingsPageWrapper>
  );
}

const styles = {
  Label: {
    fontWeight: "bold",
  },
  input: {
    width: 400,
  },
};
