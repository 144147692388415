import React, { useEffect, useState } from "react";
import SettingsPageWrapper from "../SettingsPageWrapper";
import { resources } from "../../../../assets/LocalizationResources";
import { Input, Form, FormGroup, Label, Button } from "reactstrap";
import settingsApi, { isSuccessfulRequest } from "./../../../../api/settingsApi";
import Tree from "../../../ui-components/Tree/Tree";
import { useHistory } from "react-router-dom";

export default function AddEditGroupPermissions({ location }) {
  const [permissions, setpermissions] = useState([]);
  const [data, setdata] = useState({ selectedPermissions: [], Name: "", ID: null });
  const router = useHistory();

  const handleSubmit = async event => {
    event.preventDefault();
    let options = {
      PermissionIDs: data.selectedPermissions.join(","),
      groupName: data.Name,
      CreatedByID: 1 /*To Do add login user */
    };
    const response = data.ID
      ? await settingsApi.editGroupPermissions({ ...options, groupID: data.ID })
      : await settingsApi.addGroupPermissions(options);
    if (isSuccessfulRequest(response)) router.push("./GroupsView");
    //ToDo : Handle Add Group Error
  };

  const getchildNodes = (parentID, data) => {
    let subdata = data.filter(f => f.parentID == parentID);
    return subdata.map(e => {
      let node = {
        value: e.ID,
        label: e.Name
      };
      let childNodes = getchildNodes(e.ID, data);
      if (childNodes.length > 0) node = { ...node, children: childNodes };
      return node;
    });
  };

  const getPermissions = async () => {
    const response = await settingsApi.permissions();
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      const data = response.data;
      let parentNode = data.find(f => f.parentID == null);
      setpermissions([
        {
          value: parentNode.ID,
          label: parentNode.Name,
          children: getchildNodes(parentNode.ID, data)
        }
      ]);
    }
  };

  const getselectedPermissions = async (ID, Name) => {
    const response = await settingsApi.getgroupPermissions({ ID: ID });
    if (isSuccessfulRequest(response) && response.data.length > 0) {
      setdata({ selectedPermissions: response.data.map(e => e.PermissionID), ID: ID, Name: Name });
    }
  };

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    const state = location.state;
    if (permissions.length > 0 && state && state.ID && state.Name) {
      getselectedPermissions(state.ID, state.Name);
    }
  }, [permissions]);

  return (
    <SettingsPageWrapper
      title={
        data.ID
          ? resources.settingsResources.permissions.editGroupPermissions
          : resources.settingsResources.permissions.addGroupPermissions
      }
    >
      <Form onSubmit={handleSubmit} style={styles.form}>
        <FormGroup>
          <Label>{resources.settingsResources.permissions.groupName}</Label>
          <Input
            style={{ fontSize: "14px" }}
            type="text"
            value={data.Name}
            onChange={e => setdata({ ...data, Name: e.target.value })}
          />
        </FormGroup>
        <FormGroup>
          <Tree
            nodes={permissions}
            onTreeCheck={e => setdata({ ...data, selectedPermissions: e })}
            outchecked={data.selectedPermissions}
            outexpanded={permissions.map(e => e.value)}
          />
        </FormGroup>
        <Button type="submit" color="primary" style={styles.formBtn}>
          {resources.misc.save}
        </Button>
        <Button
          type="button"
          color="danger"
          style={styles.formBtn}
          onClick={() => router.push("./settings/permissions")}
        >
          {resources.misc.cancel}
        </Button>
      </Form>
    </SettingsPageWrapper>
  );
}

const styles = {
  form: {
    maxWidth: "400px"
  },
  formBtn: {
    marginTop: 20,
    marginLeft: 10
  }
};
