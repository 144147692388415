import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { GeoServerApi } from "../../../../../api/geoserverController";
import LayersNames from "../../../../../helpers/LayersNames";
export default function RulesData({ dlskey }) {
  const [data, setdata] = useState([]);

  useEffect(() => {
    if (dlskey) getRulesData();
  }, []);
  const getRulesData = async () => {
    const response = await GeoServerApi.getLayer({
      layer: LayersNames.generalRules,
      fields: "",
      viewparams: "dlskey:" + dlskey,
    }).catch((e) => {
      console.log(e);
    });
    if (response && response.data && response.data.features) {
      if (response.data.features.length) {
        let data = [];
        response.data.features.forEach((feature) => {
          data.push([
            "",
            feature.properties["SPLIT_AREA"],
            feature.properties["MIN_FRONTAGE"],
            feature.properties["BUILDING_HIGTH"],
            feature.properties["FLOOR_COUNT"],
            feature.properties["PERCENTAGE_FLOOR"],
            feature.properties["PERCENTAGE"],
            feature.properties["BACK_OFFSIDE"],
            feature.properties["SIDE_OFFSIDE"],
            feature.properties["FRONT_OFFSIDE"],
            feature.properties["landuse_de"],
          ]);
        });
        setdata(data);
      }
    }
  };

  const columns = [
    "لا تقل المساحة الخضراء عن ",
    "الحد الأدنى لمساحة الإفراز",
    "الحد الأدنى للواجهة الأمامية",
    "إرتفاع البناء",
    "عدد الأدوار",
    "النسبة الطابقية %",
    "النسبة المئوية %",
    "خلفي",
    "جانبي",
    "أمامي",
  ];

  const getTableHeader = (label, idx) => {
    return (
      <div key={idx} style={{ ...styles.container, ...styles.padding }}>
        <label style={styles.label}>{label}</label>
      </div>
    );
  };

  const getTableContent = (row, index) => {
    return (
      <div key={index} style={styles.rowContainer}>
        {row.map((data, index) => (
          <div key={index} style={styles.container}>
            <label style={styles.labelfield}>{data ? data : "-"}</label>
          </div>
        ))}
      </div>
    );
  };

  const getTableHeaderDiagonal = (label1, label2, idx) => {
    return (
      <div key={idx} style={styles.containerDiag}>
        <label style={{ ...styles.labelDiag, ...styles.top }}>{label1}</label>
        <label style={{ ...styles.labelDiag, ...styles.bottom }}>{label2}</label>
      </div>
    );
  };

  return (
    <>
      <div style={{ ...styles.rowContainer, ...styles.containerhieht }}>
        {[
          ...columns.map((column, idx) => getTableHeader(column, idx)),
          getTableHeaderDiagonal("الأحكام", "الاستعمال", -1),
        ]}
      </div>
      {data.map((row, idx) => getTableContent(row, idx))}
    </>
  );
}

RulesData.prototypes = {
  dlskey: PropTypes.string,
};

const styles = {
  container: {
    display: "flex",
    alignItems: "flex-start",
    border: "1px solid black",
    fontSize: 9,
    fontWeight: "bold",
  },
  padding: {
    padding: "10px 0",
  },
  rowContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(10, 1fr) 3fr",
    textAlign: "center",
  },
  containerDiag: {
    border: "1px solid black",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    background: "linear-gradient(60deg,#ffffff 49%,black 50%,#ffffff 51%)",
  },
  containerhieht: {
    height: 120,
  },
  label: {
    transform: "rotateZ(90deg) scale(-1)",
    whiteSpace: "nowrap",
    fontSize: 9,
    fontWeight: "bold",
    width: 27,
  },
  labelfield: {
    fontSize: 9,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    width: 27,
  },
  labelDiag: {
    position: "absolute",
    fontSize: 9,
    fontWeight: "bold",
  },
  top: {
    top: 20,
    right: 11,
  },
  bottom: {
    bottom: 20,
    left: 2,
  },
};
