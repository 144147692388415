import React, { useState, useEffect } from "react";
import {
	Button,
	Input,
	Form,
	FormGroup,
	Label,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Spinner
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { resources } from "../../../assets/LocalizationResources";
import { useDispatch, useSelector } from "react-redux";
import { users } from "../../../redux/actions/action-creator";
import "./login-page.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../../assets/images/logo.png";

export default function LoginPage() {
	const dispatch = useDispatch();
	const { loginAttempt, authenticated, error } = useSelector(state => state.users);
	const [credentials, setCredentials] = useState({});
	const [isSubmitting, setIsSubmitting] = useState(false);

	const router = useHistory();

	useEffect(() => {
		if (authenticated) {
			console.log("auth", authenticated);
			router.push("/");
		}
		setIsSubmitting(false);
	}, [loginAttempt, authenticated, error]);

	const handleSubmit = event => {
		event.preventDefault();
		setIsSubmitting(true);
		dispatch(users.loginRequest(credentials));
	};

	const handleInputChanges = e => {
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value
		});
	};

	return (
		<div className="login-page-container">
			<div className="login-page-background"></div>
			<div className="login-page-wrapper">
				<Form className="login-page-form" onSubmit={handleSubmit}>
					<div className="login-page-logo-container">
						<img src={logo} className="login-page-logo" />
					</div>
					<FormGroup>
						<Label>{resources.user.email}</Label>
						<InputGroup dir="ltr">
							<Input
								name="Email"
								dir="rtl"
								type="email"
								required
								maxLength="255"
								onChange={handleInputChanges}
							/>
							<InputGroupAddon addonType="append">
								<InputGroupText>
									<FontAwesomeIcon icon="at" />
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</FormGroup>
					<FormGroup>
						<Label>{resources.user.password}</Label>
						<InputGroup dir="ltr">
							<Input
								name="Password"
								type="password"
								dir="rtl"
								required
								validate={{ pattern: { value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/ } }}
								onChange={handleInputChanges}
							/>
							<InputGroupAddon addonType="append">
								<InputGroupText>
									<FontAwesomeIcon icon="key" />
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</FormGroup>
					<div className="login-page-footer-wrapper">
						<Button disabled={isSubmitting} type="submit" className="login-page-login-btn" color="primary">
							{isSubmitting ? <Spinner size="sm" /> : <p>{resources.misc.login}</p>}
						</Button>
						{!Boolean(error) && loginAttempt > 0 && <label>{resources.user.invalidLogin}</label>}
						{Boolean(error) && <label>{resources.user.connectionError}</label>}
					</div>
				</Form>
			</div>
		</div>
	);
}
