import React from "react";

export default function ReportSecondaryExtreRules() {
  const data = [
    [
      { label: "الرقم" },
      { label: "اﻟﻤﺴﺎﺣﺔ ﺑﺎﻟﻤﺘﺮ اﻟﻤﺮﺑﻊ" },
      { label: "اﻟﺤﺪ اﻻدﻧﻰ ﻟﻼرﺗﺪادات", colspan: 3 },
      { label: "ارﺗﻔﺎع اﻟﺒﻨﺎء" },
      { label: "اﻟﻨﺴﺒﺔ اﻟﻤﺌﻮﯾﺔ اﻟﺴﻄﺤﯿﺔ" }
    ],
    [
      { label: "1" },
      { label: "وأقل 1000 م²" },
      { label: "7" },
      { label: "4" },
      { label: "4" },
      { label: "طابقين بحد اقصى 9 متر" },
      { label: "20%" }
    ],
    [
      { label: "1" },
      { label: "1001-2000" },
      { label: "8" },
      { label: "5" },
      { label: "5" },
      { label: "طابقين بحد اقصى 9 متر" },
      { label: "20%" }
    ],
    [
      { label: "1" },
      { label: "2001-4000" },
      { label: "10" },
      { label: "5" },
      { label: "7" },
      { label: "طابقين بحد اقصى 9 متر" },
      { label: "20%" }
    ],
    [
      { label: "1" },
      { label: "أكثر من 4000م²" },
      { label: "10" },
      { label: "5" },
      { label: "7" },
      { label: "طابقين بحد اقصى 9 متر" },
      { label: "20%" }
    ]
  ];
  const getTableRules = () => {
    return (
      <div>
        <table style={styles.table}>
          <tbody>{getTableRows()}</tbody>
        </table>
      </div>
    );
  };

  const getTableRows = () => {
    return data.map((row, idx) => {
      return <tr key={idx}>{getTableCols(row)}</tr>;
    });
  };

  const getTableCols = row => {
    return row.map((td, index) => {
      return (
        <td key={index} style={styles.td} colSpan={td.colspan ? td.colspan : 1}>
          <label>{td.label}</label>
        </td>
      );
    });
  };

  return (
    <div style={styles.container}>
      <label style={styles.mainlabel}>احكام ترخيص الأبنية السكنية و المنشآت خارج مناطق التنظيم على النحو التالي</label>
      <label>
        أ‌ - يجوز السماح لأي شريك إقامة بناء عللى الأراضي المملوكة على الشيوع التي تقع خارج التنظيم على ان تكون مساحة
        حصص طالب الترخيص في قطعة الأرض مساوية أو اكثر من الحد الأدنى المقرر لفئة استعمال الأراضي المقررة
      </label>
      <label>ب - تطبق الأحكام التنظيمية التالية عى الأبنية السكنية خارج التنظيم</label>
      {getTableRules()}
      <label>
        ج - يجوز إقامة سكن ملحق لكل بناء سكني بمساحة خمسين متر مربع على قطع الأراضي التي لا تقل مساحتها عن ثلاث دونمات و
        تعتبر هذه المساحة ضمن النسبة المئوية المقررة للبناء شريطةالتقيد بالأحكام التنظيمية للقطعة
      </label>
      <label>
        د - يجو إقامة بناء بمساحة عشرين متر مربع لخدمة اغراض المشروع ضمن الأرتداد الأمامي ولا تحتسب هذه المساحة من ضمن
        النسبة المئوية المقررة شريطة ازالته عند طلب الجهات المعنية ذلك دون ان يترتب عليها أي تعويضات
      </label>
      <label>
        هـ - للجنة المتخصصة الموافقة على ترخيص أي بناء في الأراضي المملوكة على الشيوع بعد أن يقدم مالكه تعهدا عدليا
        يلتزم بموجبه بتحمل أي مسؤولية نتيجة اصدار قرار الترخيص
      </label>
    </div>
  );
}
const styles = {
  container: {
    margin: "20px 5px 0px 5px",
    border: "1px solid black",
    fontSize: "9px",
    fontWeight: "bold",
    display: "inline-block",
    width: "738px",
    padding: 5
  },
  mainlabel: {
    fontSize: "12px",
    marginBottom: 10
  },
  table: {
    border: "1px solid black",
    margin: 5,
    width: "715px"
  },
  td: {
    paddingRight: 5
  }
};
